/* eslint-disable @typescript-eslint/no-unused-vars,react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { IonButton, IonCol, IonContent, IonGrid, IonImg, IonPage, IonRow } from '@ionic/react';
import { connect } from '../data/connect';

import { RouteComponentProps } from 'react-router';
import DefaultHeader from '../components/header/DefaultHeader';
import DefaultFooter from '../components/footer/DefaultFooter';
import './SignUpUsefulPage.scss';
import routes from '../routes';
import * as selectors from '../data/selectors';
import { setId, setIsLoggedIn, setUserData, setUserName, setUserToken } from '../data/user/user.actions';

interface OwnProps extends RouteComponentProps {}

interface StateProps {}

interface DispatchProps {}

interface SignUpUsefulProps extends OwnProps, StateProps, DispatchProps {}

const SignUpUsefulPage: React.FC<SignUpUsefulProps> = ({ history }) => {
  const [chkStatus, setChkStatus] = useState('');

  useEffect(() => {
    //소셜 로그인 응답 처리
    const search = new URLSearchParams(window.location.search); // 소셜 로그인 params
    const status = search.get('status') || '';
    const type = search.get('type') || '';
    const id = search.get('id') || '';
    const name = search.get('name') || '';
    const phoneNumber = search.get('phoneNumber') || '';
    const email = search.get('email') || '';
    setChkStatus(status);

    if (!chkStatus) {
      if (chkStatus == 'signup') {
        // 소셜로 회원가입을 하지 않은 경우
        history.push(routes.SIGNUP);
      } else if (chkStatus == 'success') {
        // 소셜 회원가입을 완료 후 로그인 한 경우
        setChkStatus(status);
      } else if (chkStatus == 'error') {
        // TODO 에러가 발생 시 추가적으로 작업할 예정
        setChkStatus(status);
      }
    }
  }, [chkStatus]);

  return (
    <IonPage id="signup_useful">
      <IonContent>
        <DefaultHeader title="회원가입" showBackButton={true} />
        <IonGrid>
          <IonRow className="loadImg">
            <IonCol className="signService">
              <IonImg src="/assets/sign/img_sign.png" alt="회원가입" />
              <p className="strong">반가워요.</p>
              <p>모던한담에 오신 것을 환영합니다.</p>
              <IonButton expand="block">이메일 회원가입</IonButton>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div className="line"></div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol className="whiteThem">
              <IonButton expand="block">카카오 회원가입</IonButton>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol className="whiteThem">
              <IonButton expand="block" className="naver">
                네이버 회원가입
              </IonButton>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol className="whiteThem">
              <IonButton expand="block" className="google">
                구글 회원가입
              </IonButton>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol className="whiteThem">
              <IonButton expand="block" className="apple">
                Apple 회원가입
              </IonButton>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol className="colorThem">
              <IonButton expand="block">카카오 회원가입</IonButton>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol className="colorThem">
              <IonButton expand="block" className="naver">
                네이버 회원가입
              </IonButton>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol className="colorThem">
              <IonButton expand="block" className="google">
                구글 회원가입
              </IonButton>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol className="colorThem">
              <IonButton expand="block" className="apple">
                Apple 회원가입
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
        <DefaultFooter />
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: selectors.getUser(state),
  }),
  mapDispatchToProps: {
    setUserName,
    setIsLoggedIn,
    setUserToken,
    setUserData,
    setId,
  },
  component: SignUpUsefulPage,
});
