import { PayState } from './pay.state';
import { PayActions } from './pay.actions';

export function payReducer(state: PayState, action: PayActions): PayState {
  switch (action.type) {
    case 'set-pay-data':
      return { ...state, ...action.payData };
    case 'set-pay-certify':
      return { ...state, payCertify: action.payCertify };
    case 'set-pay-product':
      return { ...state, payProduct: action.payProduct };
    default:
      return state;
  }
}
