/* eslint-disable @typescript-eslint/no-unused-vars,react-hooks/exhaustive-deps,@typescript-eslint/no-unused-expressions */
import React, { useEffect, useState } from 'react';
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonLabel,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonSlide,
  IonSlides,
  IonText,
  useIonAlert,
  useIonToast,
} from '@ionic/react';
import { connect } from '../data/connect';

import { RouteComponentProps, useLocation } from 'react-router';
import DefaultHeader from '../components/header/DefaultHeader';
import './ProductDetailPage.scss';
import ProductDetailDesc from '../components/product/ProductDetailDesc';
import ProductDetailInfo from '../components/product/ProductDetailInfo';

import 'swiper/swiper.scss';
import 'swiper/modules/autoplay/autoplay.scss';
import '@ionic/react/css/ionic-swiper.css';

//TODO 추후 추가와 함께 사용
//import { Swiper } from 'swiper/react/swiper-react';
//import ProductDetailChkCard from '../components/product/ProductDetailCart';
// import ProductDetailReview from '../components/product/ProductDetailReview';
// import ProductDetailAsk from '../components/product/ProductDetailAsk';
// import ProductCard from '../components/product/ProductCard';
import DefaultFooter from '../components/footer/DefaultFooter';
import SubMenu from '../components/menu/SubMenu';
import Menu from '../components/menu/Menu';
import { cartChangeQty, cartInsert, cartTotal, getCategory, getProductDetail } from '../data/dataApi';
import { ProductDetail } from '../models/ProductDetail';
import { setCartTotal } from '../data/cart/cart.actions';
import routes from '../routes';
import { setPayCertify, setPayProduct } from '../data/pay/pay.actions';
import * as selectors from '../data/selectors';
import { codeName } from '../util/EnumUtils';
import { CodeObject } from '../data/code/code.state';
import { ProductStatusType } from '../models/Product';
import { PayProduct } from '../data/pay/pay.state';

interface OwnProps
  extends RouteComponentProps<{
    productId: string;
  }> {}

interface StateProps {
  user: any;
  codes: CodeObject;
}

interface DispatchProps {
  setCartTotal: typeof setCartTotal;
  setPayCertify: typeof setPayCertify;
  setPayProduct: typeof setPayProduct;
}

interface ProductDetailProps extends OwnProps, StateProps, DispatchProps {}

const ProductDetailPage: React.FC<ProductDetailProps> = ({ match, history, user, setCartTotal, setPayCertify, setPayProduct, codes }) => {
  const [subTab, setSubTab] = useState('explanation');
  const [present] = useIonAlert(); // alert 폼
  const [presentToast] = useIonToast();
  const [qty, setQty] = useState(0); // 장바구니 재고 체크
  const [ChkClick, setChkClick] = useState(false); // +,- 클릭여부
  const [cartCount, setCartCount] = useState(1); // 장바구니 카운트 (상품 갯수 설정)
  const [productCartOn, setProductCartOn] = useState(false); // 상품 장바구니에 담겼는지 여부
  const [productPrice, setProductPrice] = useState(0); // 상품 가격 props
  const [totalPrice, setTotalPrice] = useState(0); // 총 가격
  const [clicked, setClicked] = useState(true); // 슬라이드 바
  const [productDetail, setProductDetail] = useState<ProductDetail | null>(null); // 상품 상세
  const [title, setTitle] = useState(''); // 상세 제목
  const [productStatusType, setProductStatusType] = useState(''); // 판매 상태 - TODO: 최종 재고확인은 paypage에서 하도록
  const [productStockQuantity, setProductStockQuantity] = useState(0);

  const slideOpts = {
    initialSlide: 1,
    speed: 400,
  };

  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);

  const productId = Number(match.params.productId);
  const categoryId = String(params.get('categoryId') || 3);

  const defaultPayProducts: PayProduct = {
    products: [],
    fromCart: false,
  };

  const getData = async () => {
    const { result, ...product } = await getProductDetail(productId);
    if (result) {
      setProductDetail(product);
      setProductPrice(product.productPricePolicy.productNormalPrice);
      setTotalPrice(product.productPricePolicy.productSalePrice * cartCount);
      setProductStatusType(product.productStatusType);
      setProductCartOn(product.cartOn);
      setProductStockQuantity(product.productStockQuantity);
    } else {
    }
  };

  const changeQty = async (value: string) => {
    //TODO 무료배송 상품일때는 배송비 추가가 없음
    if (value == 'm') {
      if (cartCount > 1) {
        setCartCount(cartCount - 1);
      }
    } else if (value == 'p') {
      setQty(cartCount);
      setCartCount(cartCount + 1);
    }
  };

  const handleChangeQuantity = async (flag: 'm' | 'p') => {
    changeQty(flag);
    setChkClick(true);
  };

  const orderSubmit = async () => {
    // 이중 장치
    if (productStatusType === ProductStatusType.SALE) {
      /*
    TODO 비회원 결제 로직 완성 후 아래 내용 제거할 것
    IONIC IonSegment 문제로 페이지 라우팅 잘못되는 문제 존재함
    */
      if (!user.userData.isLoggedIn) {
        present({
          header: '모던한담',
          message: '주문은 로그인 후 사용가능합니다.',
          buttons: [
            {
              text: '확인',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                window.location.href = '/login';
              },
            },
          ],
        });

        return;
      }

      if (cartCount > 0) {
        const items: any = [];
        if (cartCount > 0) {
          let el = { productId: productId, cartQuantity: cartCount };
          items.push(el);
        }

        setPayCertify(true);
        setPayProduct({
          ...defaultPayProducts,
          products: items,
        });
        history.push(routes.PAY);
      } else {
        setPayCertify(false);
        setPayProduct(defaultPayProducts);
        present({
          header: '모던한담',
          message: '결제할 상품을 선택해주세요.',
          buttons: [
            {
              text: '확인',
              role: 'cancel',
              cssClass: 'secondary',
            },
          ],
        });
        return;
      }
    } else {
      await present({
        header: '모던한담',
        message: `현재 구매하실 수 없는 상품입니다. (${codeName(productStatusType, codes.ProductStatusType)})`,
        buttons: [
          {
            text: '확인',
            role: 'cancel',
            cssClass: 'secondary',
          },
        ],
      });
      return;
    }
  };

  const cart_btn = async () => {
    // 이중 장치
    if (productStatusType === ProductStatusType.SALE && productStockQuantity > 0) {
      const addCart = await cartInsert(productId, cartCount);
      if (addCart.result) {
        if (!!addCart.count) {
          setCartTotal(addCart.count);
        } else {
          const getCartTotal = await cartTotal();
          if (getCartTotal.result) setCartTotal(getCartTotal.cartCount);
        }
        presentToast({
          message: productCartOn
            ? `동일한 상품이 장바구니에 있어 담은 수량을 ${cartCount}개 추가하였습니다`
            : `${productDetail?.productName} ${cartCount}개를 장바구니에 담았습니다.`,
          duration: 2000,
        });
        setProductCartOn(true); // 현 페이지에서는 빼기 기능 없음
      } else {
        present({
          header: '모던한담',
          message: '다시 시도해 주세요.',
          buttons: [
            {
              text: '확인',
              role: 'cancel',
              cssClass: 'secondary',
            },
          ],
        });
        return;
      }
    } else if (productStockQuantity <= 0) {
      await present({
        header: '모던한담',
        message: `재고가 부족하여 상품을 장바구니에 담을 수 없습니다.`,
        buttons: [
          {
            text: '확인',
            role: 'cancel',
            cssClass: 'secondary',
          },
        ],
      });
      return;
    } else {
      await present({
        header: '모던한담',
        message: `${codeName(productStatusType, codes.ProductStatusType)} 상태의 상품은 장바구니에 담을 수 없습니다.`,
        buttons: [
          {
            text: '확인',
            role: 'cancel',
            cssClass: 'secondary',
          },
        ],
      });
      return;
    }
  };

  const setTitleCategory = async () => {
    const category = await getCategory();
    if (category.category.length > 0) {
      const title = category.category.filter((items) => items.categoryId == Number(categoryId)).map((item) => item.categoryName);
      setTitle(title[0]);
    }
  };

  function arrowUpDown() {
    if (clicked) {
      setClicked(false);
    } else {
      setClicked(true);
    }
  }

  function numberWithCommas(x: { toString: () => string }) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  useEffect(() => {
    getData();
    setTitleCategory();
  }, [match.params]);

  useEffect(() => {
    if (productDetail?.productStockQuantity) {
      if (cartCount > productDetail?.productStockQuantity || 0) {
        setCartCount(qty);
        present({
          header: '모던한담',
          message:
            "'" +
            productDetail?.productName +
            "'" +
            ' 상품의 재고가 부족합니다. ' +
            productDetail?.productStockQuantity +
            '개 이하로 구매해 주세요.',
          buttons: [
            {
              text: '확인',
              role: 'cancel',
              cssClass: 'secondary',
            },
          ],
        });
        return;
      } else {
        if (ChkClick) {
          setTotalPrice(productPrice * cartCount);
          setChkClick(false);
        }
      }
    }
  }, [cartCount]);

  return (
    <IonPage id="product_detail">
      <IonContent>
        <DefaultHeader title={title} showBackButton={true} />
        <IonGrid>
          <Menu />

          <SubMenu page={categoryId} />

          <IonRow className="product_img">
            <IonCol>
              <IonSlides pager={true} options={slideOpts}>
                {productDetail?.images &&
                  productDetail.images.map((item: { imageUriPath: string | undefined; imageId: number }) => {
                    return (
                      <IonSlide key={item.imageId}>
                        <IonImg src={item.imageUriPath} />
                      </IonSlide>
                    );
                  })}
              </IonSlides>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="detail_con">
              <strong>{productDetail?.productName}</strong>
              <p>{productDetail?.productDescription}</p>
              <div className="row_price">
                {productStatusType === ProductStatusType.SALE ? (
                  productStockQuantity > 0 ? (
                    <>
                      {productDetail?.productPricePolicy.productNormalPrice !== productDetail?.productPricePolicy.productSalePrice && (
                        <p className="normal">
                          <IonText>{numberWithCommas(String(productDetail?.productPricePolicy.productNormalPrice))}</IonText>
                          <span>원</span>
                        </p>
                      )}
                      <p className="sale">
                        <IonText>{numberWithCommas(String(productDetail?.productPricePolicy.productSalePrice))}</IonText>
                        <span>원</span>
                      </p>
                    </>
                  ) : (
                    <p className="out_of">
                      <IonText>{codeName(ProductStatusType.SOLD_OUT, codes.ProductStatusType)}</IonText>
                    </p>
                  )
                ) : (
                  <p className="out_of">
                    <IonText>{codeName(productStatusType, codes.ProductStatusType)}</IonText>
                  </p>
                )}
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="product_info">
              <ul>
                <li>
                  <span>중량</span>
                  <em>{productDetail?.productInformation.weight}</em>
                </li>

                <li>
                  <span>보관</span>
                  <em>{productDetail?.productInformation.storageMethod}</em>
                </li>

                {productDetail?.productDelivery.deliveryMemo && (
                  <li>
                    <span>배송</span>
                    <em className="emphasis">{productDetail?.productDelivery.deliveryMemo || ''}</em>
                  </li>
                )}
                <li>
                  <span>배송비</span>
                  <em>
                    {productDetail?.productDelivery.deliveryFeeType === 'FREE' || productDetail?.productDelivery.deliveryFee === 0
                      ? '무료'
                      : `${productDetail?.productDelivery.deliveryFee}원`}
                  </em>
                </li>
              </ul>
            </IonCol>
          </IonRow>
          <IonRow>
            {/* TODO : 추후 작업 */}
            {/*<IonCol className="con_box3">*/}
            {/*  <b className="tit">함께 구매하면 바로 홈파티 :)</b>*/}
            {/*  <IonList className="" inset={false}>*/}
            {/*    <ProductDetailChkCard Card={''} />*/}
            {/*  </IonList>*/}
            {/*  <IonButton type="button" expand="block" mode="ios" className="btn_cart">*/}
            {/*    한번에 장바구니 담기*/}
            {/*  </IonButton>*/}
            {/*</IonCol>*/}
          </IonRow>

          {/*상품 설명*/}
          <IonRow className="mt">
            <IonCol className="con_box4">
              <IonSegment className="info_tab" scrollable={true} value={subTab} onIonChange={(e) => setSubTab(e.detail.value!)}>
                <IonSegmentButton value="explanation">
                  <IonLabel>상품설명</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="info">
                  <IonLabel>상품정보</IonLabel>
                </IonSegmentButton>
                {/*TODO 추후에 작업 예정*/}
                {/*<IonSegmentButton value="review">*/}
                {/*  <IonLabel>*/}
                {/*    후기<span>(127)</span>*/}
                {/*  </IonLabel>*/}
                {/*</IonSegmentButton>*/}
                {/*<IonSegmentButton value="ask">*/}
                {/*  <IonLabel>*/}
                {/*    문의<span>(38)</span>*/}
                {/*  </IonLabel>*/}
                {/*</IonSegmentButton>*/}
              </IonSegment>
            </IonCol>
            <IonRow className="tab_con">
              {subTab === 'explanation' && <ProductDetailDesc contents={productDetail?.productContents || ''} />}
              {subTab === 'info' && (
                <ProductDetailInfo information={productDetail?.productInformation} customer={productDetail?.productCustomerPolicy} />
              )}
              {/*TODO 추후에 작업 예정*/}
              {/*{subTab === 'review' && <ProductDetailReview />}*/}
              {/*{subTab === 'ask' && <ProductDetailAsk />}*/}
            </IonRow>
          </IonRow>

          {/*TODO : 추후 작업 예정 */}
          {/*<IonRow className="product_list">*/}
          {/*  <IonCol>*/}
          {/*    <strong>함께 즐겨보세요!</strong>*/}
          {/*    <Swiper*/}
          {/*      spaceBetween={10}*/}
          {/*      //TODO:slidesPerView 3개 이상시 2.5 아니면 2*/}
          {/*      slidesPerView={2.5}*/}
          {/*      pagination={{*/}
          {/*        clickable: true,*/}
          {/*        type: 'fraction',*/}
          {/*      }} //페이징 타입, 페이징 적용*/}
          {/*      scrollbar={{ draggable: true }} //스크롤 바*/}
          {/*      onSlideChange={() => console.log('slide change')}*/}
          {/*      onSwiper={(swiper) => console.log(swiper)}*/}
          {/*    >*/}
          {/*      <SwiperSlide>*/}
          {/*        <ProductCard card={''} />*/}
          {/*      </SwiperSlide>*/}

          {/*      <SwiperSlide>*/}
          {/*        <ProductCard card={''} />*/}
          {/*      </SwiperSlide>*/}

          {/*      <SwiperSlide>*/}
          {/*        <ProductCard card={''} />*/}
          {/*      </SwiperSlide>*/}
          {/*    </Swiper>*/}
          {/*  </IonCol>*/}
          {/*</IonRow>*/}
        </IonGrid>

        <div className={`innerWrap ${clicked && 'down'}`}>
          <div className={`orderCloseBtn ${clicked && 'btn_open'}`} onClick={() => arrowUpDown()}>
            <button>닫기</button>
          </div>

          <div className="orderTxt">
            <div>
              <span>{productDetail?.productInformation.weight}</span>
              <span>{productDetail?.productInformation.storageMethod}</span>
              {/*<span>{productDetail?.productInformation.purpose}</span>*/}
            </div>
            <strong>{productDetail?.productName}</strong>
            <div className="orderPrice">
              {productStatusType === ProductStatusType.SALE ? (
                productStockQuantity > 0 ? (
                  <>
                    총 <span id="total_amount">{numberWithCommas(totalPrice)}</span>원
                  </>
                ) : (
                  <IonText className="outOf">{codeName(ProductStatusType.SOLD_OUT, codes.ProductStatusType)}</IonText>
                )
              ) : (
                <IonText className="outOf">{codeName(productStatusType, codes.ProductStatusType)}</IonText>
              )}
            </div>

            <div className="orderCount">
              <button
                type="button"
                disabled={productStatusType !== ProductStatusType.SALE || productStockQuantity <= 0}
                className="btnMinus"
                onClick={() => handleChangeQuantity('m')}
              >
                수량빼기
              </button>
              {/*TODO :input 으로 개수 받을 예정*/}
              <input
                type="number"
                disabled={productStatusType !== ProductStatusType.SALE || productStockQuantity <= 0}
                className="number"
                value={cartCount}
                id="ct_qty"
                readOnly
              />
              <button
                type="button"
                disabled={productStatusType !== ProductStatusType.SALE || productStockQuantity <= 0}
                className="btnPlus"
                onClick={() => handleChangeQuantity('p')}
              >
                수량더하기
              </button>
            </div>
          </div>

          <div className="btn_box">
            <IonButton
              expand="full"
              disabled={productStatusType !== ProductStatusType.SALE || productStockQuantity <= 0}
              onClick={() => orderSubmit()}
            >
              바로구매하기
            </IonButton>
            <IonButton
              expand="full"
              disabled={productStatusType !== ProductStatusType.SALE || productStockQuantity <= 0}
              type="button"
              onClick={() => cart_btn()}
            >
              장바구니담기
            </IonButton>
          </div>
        </div>
        <DefaultFooter />
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: selectors.getUser(state),
    codes: selectors.getCodes(state),
  }),
  mapDispatchToProps: { setCartTotal, setPayCertify, setPayProduct },
  component: React.memo(ProductDetailPage),
});
