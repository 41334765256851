import { CategoryState } from './category.state';
import { CategoryActions } from './category.actions';

export function categoryReducer(state: CategoryState, action: CategoryActions): CategoryState {
  switch (action.type) {
    case 'set-category-data':
      return { ...state, ...action.categoryData };
    case 'set-category-id':
      return { ...state, categoryId: Number(action.categoryId) };
    case 'set-current-category-id':
      return { ...state, currentCategoryId: Number(action.categoryId) };
    default:
      return state;
  }
}
