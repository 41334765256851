const routes = {
  MAIN: '/',
  LOGIN: '/login',
  LOGOUT: '/logout',
  SEARCH: '/search',
  PRODUCT: '/product',
  PRODUCT_DETAIL: '/product/:productId',
  GIFT: '/gift',
  DELIVERY: '/delivery',
  DELIVERY_DETAIL: '/delivery/:deliveryId',
  DELIVERY_INFO: '/delivery/informative',
  DELIVERY_CHANGE: '/delivery/change',
  DELIVERY_ADDITIONAL: '/delivery/additional',
  RECIPE: '/recipe',
  RECIPE_DETAIL: '/recipe/:meatType/:recipeId',
  CART: '/cart',
  MYPAGE: '/mypage',
  SIGNUP: '/signup',
  SIGNUP_USEFUL: '/signup/useful',
  MYINFO: '/myInfo',
  MYINFO_DETAIL: '/myInfo/detail',
  NOTICE: '/notice',
  TERMS_OF_SERVICE: '/terms/service',
  PRIVACY_POLICY: '/terms/privacy',
  PAY: '/pay',
  PAY_COMPLETE: '/pay/complete',
  INQUIRY: '/inquiry',
  INQUIRY_DETAIL: '/inquiry/detail',
  ORDER: '/order',
  ORDER_DETAIL: '/order/:orderId',
  OAUTH: '/login/oauth',
};

export default routes;
