import React, { useEffect, useState } from 'react';
import { connect } from '../data/connect';

import { IonCol, IonContent, IonGrid, IonPage, IonRow, IonRouterLink, IonList, IonImg, useIonAlert, useIonToast } from '@ionic/react';

import 'swiper/swiper.scss';
import 'swiper/modules/autoplay/autoplay.scss';
import '@ionic/react/css/ionic-swiper.css';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';

import { RouteComponentProps } from 'react-router';
import DefaultHeader from '../components/header/DefaultHeader';

import './Main.scss';
import DefaultFooter from '../components/footer/DefaultFooter';
// import ProductCard from '../components/product/ProductCard';
import ModamSale from '../components/modam/ModamSale';
import Menu from '../components/menu/Menu';
import * as selectors from '../data/selectors';
import { productSelect } from '../data/dataApi';
import { Product } from '../models/Product';
import ProductCard from '../components/product/ProductCard';
import { setCartTotal } from '../data/cart/cart.actions';
import { CodeObject } from '../data/code/code.state';

interface OwnProps extends RouteComponentProps {}

interface StateProps {
  user: any;
  codes: CodeObject;
}

interface DispatchProps {
  setCartTotal: typeof setCartTotal;
}

interface MainProps extends OwnProps, StateProps, DispatchProps {}

const MainPage: React.FC<MainProps> = ({ setCartTotal, codes }) => {
  const [cartChk, setCartChk] = useState(false); // 장바구니 딤드처리 목록
  const [pageNumber, setPageNumber] = useState(1);
  const [products, setProducts] = useState<Product[]>([]); //상품 정보
  const [product, setProduct] = useState<Product>(); // 장바구니 딤드 정보 (상품 장바구니 담을때)
  const [present] = useIonToast(); // 폼

  // 임시 전체상품 (10개)
  const getData = async (resetList: boolean = false) => {
    const result = await productSelect({
      category: 1,
      page: resetList ? 1 : pageNumber,
      size: 10,
    });

    if (!result.product || result.product.length === 0) {
      setProducts([]);
    } else {
      if (resetList) {
        setProducts(result.product);
        setPageNumber(pageNumber + 1);
      } else {
        setProducts([...products, ...result.product]);
        setPageNumber(pageNumber + 1);
      }
    }
  };

  useEffect(() => {
    getData(true);
  }, []);

  useEffect(() => {
    if (cartChk) {
      present({
        // header: '모던한담',
        message: `${product?.productName} 1개를 장바구니에 담았습니다.`,
        duration: 2000,
        // buttons: [
        //   {
        //     text: '확인',
        //     role: 'cancel',
        //     cssClass: 'secondary',
        //   },
        // ],
      });
      setCartChk(false);
    }
  }, [cartChk, product]);

  return (
    <IonPage id="modam_main">
      {/*공통 헤더*/}
      <DefaultHeader />

      <IonContent>
        <IonGrid>
          {/*메인 Tab(대분류) menu*/}
          <Menu />

          {/*슬라이더 배너*/}
          <IonRow>
            <IonCol className="swiper_wrap">
              <Swiper
                modules={[Pagination]} //page
                spaceBetween={10} //슬라이드 사이여백
                slidesPerView={1} //한 슬라이드에 보여줄 갯수
                pagination={{
                  clickable: true,
                  type: 'fraction',
                }} //페이징 타입, 페이징 적용
                scrollbar={{ draggable: true }} //스크롤 바
              >
                {/*메뉴별 컴포넌트 생성 후 이동 예정*/}
                <SwiperSlide>
                  <IonRouterLink>
                    <IonImg src="/assets/banner/main_banner1.png" />
                  </IonRouterLink>
                </SwiperSlide>
                <SwiperSlide>
                  <IonRouterLink>
                    <IonImg src="/assets/banner/main_banner1.png" />
                  </IonRouterLink>
                </SwiperSlide>
                <SwiperSlide>
                  <IonRouterLink>
                    <IonImg src="/assets/banner/main_banner1.png" />
                  </IonRouterLink>
                </SwiperSlide>
              </Swiper>
            </IonCol>
          </IonRow>

          {/* TODO: 모담딜 및 카테고리 별 상품추천 관련 기능 부재로 인해 임시로 전체 상품을 보여주고 있음 */}
          <IonGrid>
            {/*메인 Tab(대분류) menu*/}
            {/* 
            {!chkProduct && products.length > 0 && (
              <>
                <IonRow>
                  <IonCol className={'ion-text-center ion-padding-vertical'}>
                    <IonIcon icon={informationCircle} size={'large'} color="warning" />
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol className={'ion-text-center ion-padding-vertical'}>
                    <IonText>등록된 상품이 품절이거나, 준비중 입니다.</IonText>
                  </IonCol>
                </IonRow>
              </>
            )} */}

            <IonRow className="product_wrap">
              <IonCol>
                {products && products.length > 0 && (
                  <IonList inset={false}>
                    {products.map((product) => (
                      <ProductCard
                        key={product.productId}
                        product={product}
                        categoryId={'1'}
                        cart={(cartChk, product) => {
                          setCartChk(cartChk);
                          setProduct(product);
                        }}
                        setCartTotal={setCartTotal}
                        codes={codes}
                      />
                    ))}
                    {/* <IonInfiniteScroll onIonInfinite={loadData} disabled={isInfiniteDisabled} threshold="200px">
                      <IonInfiniteScrollContent loadingSpinner="bubbles" loadingText="불러오는 중..."></IonInfiniteScrollContent>
                    </IonInfiniteScroll> */}
                  </IonList>
                )}
              </IonCol>
            </IonRow>
          </IonGrid>

          {/*모담딜*/}
          {/* <ModamSale Modam={''} /> */}

          {/*구이*/}
          {/* <IonRow className="product_type1">
            <IonCol>
              <IonText>
                <h3>구이</h3>
              </IonText>

              <IonList inset={false}><ProductCard card={''} /></IonList>
            </IonCol>
          </IonRow> */}

          {/* <IonRow className="open">
            <IonCol>
              <IonButton expand="full">
                <div className="arrow">구이 더보기</div>
              </IonButton>
            </IonCol>
          </IonRow> */}

          {/*스테이크*/}
          {/* <IonRow className="product_type1 border"> */}
          {/* <IonCol> */}
          {/* <IonText> */}
          {/* <h3>스테이크</h3> */}
          {/* </IonText> */}

          {/*TODO 데이터 조회 시 2개로 잘라야함*/}
          {/* <IonList inset={false}><ProductCard card={''} /></IonList> */}
          {/* </IonCol> */}
          {/* </IonRow> */}

          {/* <IonRow className="open">
            <IonCol>
              <IonButton expand="full">
                <div className="arrow">구이 더보기</div>
              </IonButton>
            </IonCol>
          </IonRow> */}

          {/* <IonRow className="banner">
            <IonCol>
              <IonRouterLink routerLink="/gift">
                <IonImg src="/assets/banner/business_service.png" />
              </IonRouterLink>
            </IonCol>
          </IonRow> */}
        </IonGrid>
        {/*공통 푸터*/}
        <DefaultFooter />
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: selectors.getUser(state),
    codes: selectors.getCodes(state),
  }),
  mapDispatchToProps: { setCartTotal },
  component: React.memo(MainPage),
});
