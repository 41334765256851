import React, { useState } from 'react';
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonRouterLink,
  IonRow,
  IonSegment,
  IonSegmentButton,
} from '@ionic/react';
import { connect } from '../data/connect';

import { RouteComponentProps } from 'react-router';
import DefaultHeader from '../components/header/DefaultHeader';
import DefaultFooter from '../components/footer/DefaultFooter';
import './SearchPage.scss';
import Routes from '../routes';
// import ProductCard from '../components/product/ProductCard';

interface OwnProps extends RouteComponentProps {}

interface StateProps {}

interface DispatchProps {}

interface SearchPageProps extends OwnProps, StateProps, DispatchProps {}

const SearchPage: React.FC<SearchPageProps> = ({}) => {
  const [searchText, setSearchText] = useState('');

  const goSearch = async () => {
    console.log('action : ');
  };

  return (
    <IonPage id="search_page">
      <IonContent>
        <DefaultHeader title="검색" showBackButton={true} />
        <IonGrid>
          <IonRow>
            <IonCol>
              <div className="custom_search">
                <form
                  id="searchForm"
                  onSubmit={(e) => {
                    e.preventDefault();
                    goSearch();
                    // setSearchText(e.value);
                    // console.log('search submit', e);
                  }}
                >
                  <IonInput
                    value={searchText}
                    enterkeyhint={'search'}
                    onIonChange={(e) => setSearchText(e.detail.value!)}
                    placeholder="무엇을 찾아드릴까요?"
                  />
                  <IonButton
                    type="submit"
                    fill="clear"
                    className={'btn_search'}
                    title="검색 버튼"
                    onClick={(e) => {
                      e.preventDefault();
                      goSearch();
                    }}
                  />
                </form>
              </div>
            </IonCol>
          </IonRow>

          <IonRow className="tag">
            <IonCol>
              <IonSegment value="1" className="segment_wrap" scrollable={true}>
                <IonSegmentButton value="1">
                  <IonLabel>#홈파티</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="2">
                  <IonLabel>#술안주</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="3">
                  <IonLabel>#어린이반찬</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="4">
                  <IonLabel>#특수부위</IonLabel>
                </IonSegmentButton>
              </IonSegment>
            </IonCol>
          </IonRow>

          {/*TODO : 검색결과 없을 시 처리*/}
          <IonRow className="ion-text-center txt">
            <IonCol>
              <div>
                <p>찾으시는 상품(레시피)을 찾지 못했어요. 도움을 드리지 못해 죄송해요 :(</p>
              </div>
            </IonCol>
          </IonRow>

          <IonList className="search_list">
            <IonListHeader>상품 카테고리</IonListHeader>
            {/*TODO : 검색 리스트*/}
            <IonItem>
              <span slot="start" className="rank"></span>
              <IonRouterLink onClick={() => {}} routerLink={`${Routes.SEARCH}?q=`} className="keyword">
                등심
              </IonRouterLink>
            </IonItem>

            <IonItem>
              <span slot="start" className="rank"></span>
              <IonRouterLink onClick={() => {}} routerLink={`${Routes.SEARCH}?q=`} className="keyword">
                안심
              </IonRouterLink>
            </IonItem>

            <IonItem>
              <span slot="start" className="rank"></span>
              <IonRouterLink onClick={() => {}} routerLink={`${Routes.SEARCH}?q=`} className="keyword">
                채끝
              </IonRouterLink>
            </IonItem>

            <IonItem>
              <span slot="start" className="rank"></span>
              <IonRouterLink onClick={() => {}} routerLink={`${Routes.SEARCH}?q=`} className="keyword">
                차돌박이
              </IonRouterLink>
            </IonItem>

            <IonItem>
              <span slot="start" className="rank"></span>
              <IonRouterLink onClick={() => {}} routerLink={`${Routes.SEARCH}?q=`} className="keyword">
                특수부위
              </IonRouterLink>
            </IonItem>

            <IonItem>
              <span slot="start" className="rank"></span>
              <IonRouterLink onClick={() => {}} routerLink={`${Routes.SEARCH}?q=`} className="keyword">
                차돌양지
              </IonRouterLink>
            </IonItem>

            <IonItem>
              <span slot="start" className="rank"></span>
              <IonRouterLink onClick={() => {}} routerLink={`${Routes.SEARCH}?q=`} className="keyword">
                설도
              </IonRouterLink>
            </IonItem>

            <IonItem>
              <span slot="start" className="rank"></span>
              <IonRouterLink onClick={() => {}} routerLink={`${Routes.SEARCH}?q=`} className="keyword">
                설도
              </IonRouterLink>
            </IonItem>

            <IonItem>
              <span slot="start" className="rank"></span>
              <IonRouterLink onClick={() => {}} routerLink={`${Routes.SEARCH}?q=`} className="keyword">
                갈비
              </IonRouterLink>
            </IonItem>

            <IonItem>
              <span slot="start" className="rank"></span>
              <IonRouterLink onClick={() => {}} routerLink={`${Routes.SEARCH}?q=`} className="keyword">
                선물세트
              </IonRouterLink>
            </IonItem>

            <IonItem>
              <span slot="start" className="rank"></span>
              <IonRouterLink onClick={() => {}} routerLink={`${Routes.SEARCH}?q=`} className="keyword">
                시즈닝
              </IonRouterLink>
            </IonItem>
          </IonList>

          {/*검색 결과*/}
          <>
            <IonRow className="search_result">
              <IonCol>
                <p className="result_tit">
                  <span>4</span>개의 상품과 <span>2</span>개의 모담레시피가 검색되었어요.
                </p>
                <IonList inset={false}>{/*<ProductCard card={''} />*/}</IonList>
              </IonCol>
            </IonRow>

            <IonRow className="open">
              <IonCol>
                <IonButton expand="full">
                  <div className="arrow">모담레시피 더보기</div>
                </IonButton>
              </IonCol>
            </IonRow>
          </>
        </IonGrid>
        <DefaultFooter />
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  component: SearchPage,
});
