/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { IonSegment, useIonRouter } from '@ionic/react';
import './SubMenu.scss';
import { Category } from '../../models/Category';
import SubCategory from './SubCategory';
import { connect } from '../../data/connect';
import { getCategory } from '../../data/dataApi';

interface SubMenuProps {
  page?: string;
  change?: (changedValue: string) => void;
}

const SubMenu: React.FC<SubMenuProps> = ({ page, change }) => {
  const [categories, setCategories] = useState<Category[]>([]); // 서브 메뉴 카테고리
  const [chkCategories, setChkCategories] = useState(true); // 카테고리 체크
  const router = useIonRouter();

  const onChange = (e: any) => {
    if (change !== undefined) {
      change(e.detail.value);
    }

    router.push(`/product?categoryId=${e.detail.value}`);
    return false;
  };

  const getData = async () => {
    const category = await getCategory();
    if (category.category.length > 0) {
      setChkCategories(false);
      setCategories(category.category);
    }
  };

  useEffect(() => {
    if (chkCategories) {
      getData();
    }
  }, [chkCategories]);

  return (
    <>
      <IonSegment value={page} scrollable={true} onIonChange={onChange}>
        {categories && categories.length > 0 && (
          <>
            {categories.map((category) => (
              <SubCategory children={category} category={category} key={category.categoryId} />
            ))}
          </>
        )}
      </IonSegment>
    </>
  );
};
export default connect({
  component: React.memo(SubMenu),
});
