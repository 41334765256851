import React, { useState } from 'react';
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonList,
  IonPage,
  IonRow,
  IonText,
  useIonAlert,
} from '@ionic/react';
import { connect } from '../data/connect';

import { RouteComponentProps } from 'react-router';
import DefaultHeader from '../components/header/DefaultHeader';
import DefaultFooter from '../components/footer/DefaultFooter';
import './DeliveryAdditionalPage.scss';
import { alertCircleOutline } from 'ionicons/icons';
import { useForm } from 'react-hook-form';
import DaumPostcode from 'react-daum-postcode';
import { deliveryCreate } from '../data/dataApi';
import * as selectors from '../data/selectors';
import routes from '../routes';

interface OwnProps extends RouteComponentProps {}

interface StateProps {
  user: any;
}

interface DispatchProps {}

interface FormField {
  props: {
    name: string;
    type: string;
    placeholder?: string;
    readonly?: boolean;
  };
  validationProps: {
    required?: boolean;
    pattern?: {
      value: RegExp;
      message: string;
    };
    maxLength?: {
      value: number;
      message: string;
    };
    validate?: (value: string) => boolean | string;
  };
  component?: any;
}

interface DeliveryAdditionalProps extends OwnProps, StateProps, DispatchProps {}

const DeliveryAdditionalPage: React.FC<DeliveryAdditionalProps> = ({ user, history }) => {
  const [present] = useIonAlert(); // alert 폼
  const [isOpenPost, setIsOpenPost] = useState(false); // 우편찾기
  const [destDefault, setDestDefault] = useState(false); // 기본 배송지로 사용
  const [chkGateType, setChkGateType] = useState('ETC'); // 출입 방
  const [destEnterTypeMemo, setDestEnterTypeMemo] = useState(''); // 배송관련 메모

  const handleComplete = (data: { address: any; addressType: string; bname: string; buildingName: string; zonecode: string }) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    setValue('destZipcode', data.zonecode);
    setValue('destAddress', fullAddress);
    setIsOpenPost(false);
  };

  const {
    // watch,
    register,
    setValue,
    getValues,
    // setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onTouched', //입력이 터치 될 때까지 유효성 검사.
    reValidateMode: 'onChange', //입력의 재유효성 검사.
  });

  const formFields: FormField[] = [
    {
      props: {
        name: 'destName',
        type: 'text',
        placeholder: '배송지명',
      },
      validationProps: {
        required: true,
      },
    },
    {
      props: {
        name: 'destRecipientName',
        type: 'text',
        placeholder: '수령인',
      },
      validationProps: {
        required: true,
      },
    },
    {
      props: {
        name: 'destRecipientCellphoneNo',
        type: 'tel',
        placeholder: '전화번호를 입력하세요',
      },
      validationProps: {
        required: true,
        pattern: {
          value: /^(?:(010-\d{4})|(01[1|6|7|8|9]-\d{3,4}))-(\d{4})$/,
          message: '010-0000-0000 휴대전화 형식으로 입력해주세요.',
        },
        maxLength: {
          value: 13,
          message: '13자리를 넘을 수 없습니다.',
        },
      },
    },
    {
      props: {
        name: 'destZipcode',
        type: 'text',
        placeholder: '배송지',
        readonly: true,
      },
      validationProps: {
        required: true,
      },
      component: (
        <>
          <IonButton expand="full" type="button" onClick={() => onChangeOpenPost()}>
            주소 검색
          </IonButton>
        </>
      ),
    },

    {
      props: {
        name: 'destAddress',
        type: 'text',
        placeholder: '주소',
        readonly: true,
      },
      validationProps: {
        // // required: true,
      },
    },

    {
      props: {
        name: 'destAddressDetail',
        type: 'text',
        placeholder: '상세주소',
      },
      validationProps: {
        // required: true,
      },
    },
  ];

  const checkHandlerDestDefault = () => {
    setDestDefault(!destDefault);
  };

  const onChangeOpenPost = () => {
    setIsOpenPost(!isOpenPost);
  };

  const autoHyphen = (phoneNumber: string) => {
    return setValue(
      'destRecipientCellphoneNo',
      phoneNumber == null
        ? null
        : phoneNumber
            .replace(/[^0-9]/g, '')
            .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3')
            .replace(/(\-{1,2})$/g, ''),
    );
  };

  const handelClickRadio = (e: any) => {
    if (e.target.value === 'PASSWORD') {
      setChkGateType('PASSWORD');
    } else if (e.target.value === 'FREEDOM') {
      setChkGateType('FREEDOM');
    } else {
      setChkGateType('ETC');
    }
  };

  const onSubmit = async () => {
    if (!getValues('destZipcode')) {
      present({
        header: '모던한담',
        message: '배송지를 입력해주세요.',
        buttons: [
          {
            text: '확인',
            role: 'cancel',
            cssClass: 'secondary',
          },
        ],
      });
      return;
    }

    setValue('destEnterType', chkGateType);
    setValue('destDefault', !!destDefault);

    const result = await deliveryCreate(user.userData.accessToken, getValues());
    if (result.result) {
      setValue('id', '');
      setValue('userId', '');
      setValue('destName', '');
      setValue('destRecipientName', '');
      setValue('destRecipientCellphoneNo', '');
      setValue('destZipcode', '');
      setValue('destAddress', '');
      setValue('destAddressDetail', '');
      setValue('destEnterType', '');
      setValue('destEnterTypeMemo', '');
      setValue('destDefault', false);
      setChkGateType('ETC');
      setDestEnterTypeMemo('');
      // history.push(routes.DELIVERY);
      // 배송지 변경에서 추가하는 경우가 있어서 뒤로가기
      history.goBack(); // 2회!!
      history.goBack(); // api fetch 때문인지 1회 할 경우 작동하지 않네요..
    } else {
      present({
        header: '모던한담',
        message: '다시 시도해주세요.',
        buttons: [
          {
            text: '확인',
            role: 'cancel',
            cssClass: 'secondary',
          },
        ],
      });
      return;
    }
  };

  return (
    <IonPage id="delivery_address_additional">
      <IonContent>
        <DefaultHeader title="배송지추가" showBackButton={true} />
        <IonGrid>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <IonList>
              {formFields.map((field, index) => {
                const { props, component, validationProps } = field;

                return (
                  <React.Fragment key={`form_field_${index}`}>
                    {props.name == 'destRecipientCellphoneNo' ? (
                      <IonItem lines="none">
                        <>
                          <IonInput
                            {...(props as any)}
                            {...register(props.name, {
                              ...validationProps,
                            })}
                            maxlength={13}
                            onIonChange={(e) => autoHyphen(String(e.detail.value))}
                          />

                          {component}
                        </>
                      </IonItem>
                    ) : (
                      <IonItem lines="none">
                        <>
                          <IonInput
                            {...(props as any)}
                            {...register(props.name, {
                              ...validationProps,
                            })}
                          />
                          {/* 에러 아이콘 */}
                          {errors[props.name] && <IonIcon icon={alertCircleOutline} color="danger" />}
                          {component}
                        </>
                      </IonItem>
                    )}

                    {props.name == 'destZipcode' && (
                      <IonRow>
                        <IonCol>
                          {isOpenPost && (
                            <div>
                              <DaumPostcode autoClose onComplete={handleComplete} />
                            </div>
                          )}
                        </IonCol>
                      </IonRow>
                    )}

                    {/* 에러메세지 */}
                    {errors[props.name] && errors[props.name].message && (
                      <IonText>
                        <p>{errors[props.name].message}</p>
                      </IonText>
                    )}
                  </React.Fragment>
                );
              })}

              <IonRow className="row_c">
                <IonCol>
                  <input
                    type="checkbox"
                    id="basic"
                    onClick={(e) => {
                      setValue('destDefault', e.currentTarget.checked);
                    }}
                    checked={destDefault}
                    onChange={() => checkHandlerDestDefault()}
                  />
                  <label htmlFor="basic">기본 배송지로 사용</label>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <div className="gate_info">
                    <strong>출입방법</strong>
                    <div className="radio_box">
                      <input
                        type="radio"
                        name="pay"
                        id="pay_type1"
                        value="PASSWORD"
                        checked={chkGateType == 'PASSWORD' ? true : false}
                        onChange={handelClickRadio}
                      />
                      <label htmlFor="pay_type1" className="tit">
                        비밀번호
                      </label>
                    </div>

                    <div className="radio_box">
                      <input
                        type="radio"
                        name="pay"
                        id="pay_type2"
                        value="FREEDOM"
                        checked={chkGateType == 'FREEDOM' ? true : false}
                        onChange={handelClickRadio}
                      />
                      <label htmlFor="pay_type2" className="tit">
                        자유출입
                      </label>
                    </div>

                    <div className="radio_box">
                      <input
                        type="radio"
                        name="pay"
                        id="pay_type3"
                        value="ETC"
                        checked={chkGateType == 'ETC' ? true : false}
                        onChange={handelClickRadio}
                      />
                      <label htmlFor="pay_type3" className="tit">
                        기타
                      </label>
                    </div>

                    <div className="text_box">
                      <IonInput
                        type="text"
                        placeholder="공동현관 비밀번호 #494900"
                        value={(setValue('destEnterTypeMemo', destEnterTypeMemo), destEnterTypeMemo)}
                        onIonChange={(e) => setDestEnterTypeMemo(e.detail.value!)}
                      />
                    </div>
                  </div>
                </IonCol>
              </IonRow>

              <IonRow className="btn_box">
                <IonCol>
                  <IonButton type="submit" expand="full">
                    추가
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonList>
          </form>
        </IonGrid>
        <DefaultFooter />
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: selectors.getUser(state),
  }),
  component: DeliveryAdditionalPage,
});
