import { ActionType } from '../../util/types';
import { codeState } from '../dataApi';
import { CodeObject } from './code.state';

export const fetchCodeStateList = () => async (dispatch: React.Dispatch<any>) => {
  const data = await codeState();
  dispatch(setCodeStateList(Object(data)));
};

export const setCodeStateList = (codes: Partial<CodeObject>) =>
  ({
    type: 'set-code-list',
    codes,
  } as const);

export type CodeStateActions = ActionType<typeof setCodeStateList>;
