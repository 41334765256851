/* eslint-disable @typescript-eslint/no-unused-vars,react-hooks/exhaustive-deps,@typescript-eslint/no-unused-expressions */
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRouterLink,
  IonRow,
  IonText,
  useIonAlert,
} from '@ionic/react';
import { connect } from '../data/connect';

import { RouteComponentProps } from 'react-router';
import DefaultHeader from '../components/header/DefaultHeader';
import DefaultFooter from '../components/footer/DefaultFooter';
import * as selectors from '../data/selectors';
import { FC, useEffect, useState } from 'react';
import { OrderCancelRequest, OrderList, Product } from '../models/OrderDetail';
import { modamCancelOrder, modamDecideOrder, modamOrderList } from '../data/dataApi';
import './OrderListPage.scss';
import routes from '../routes';
import { setPayCertify, setPayProduct } from '../data/pay/pay.actions';
import { CodeObject } from '../data/code/code.state';
import { codeName } from '../util/EnumUtils';

interface OwnProps extends RouteComponentProps {}

interface StateProps {
  user: any;
  codes: CodeObject;
}

interface DispatchProps {
  setPayCertify: typeof setPayCertify;
  setPayProduct: typeof setPayProduct;
}

interface OrderDeliveryProps extends OwnProps, StateProps, DispatchProps {}

const OrderListPage: FC<OrderDeliveryProps> = ({ user, codes, history, setPayCertify, setPayProduct }) => {
  const [present] = useIonAlert(); // alert 폼

  const [query, setQuery] = useState({
    page: 1,
    size: 10,
  });

  const [list, setList] = useState<Array<OrderList>>([]);

  const getData = async () => {
    const result = await modamOrderList(user.userData.accessToken, query);
    setList(result);
  };

  useEffect(() => {
    if (!!user.userData.accessToken) {
      getData();
    }
  }, [user.userData.accessToken]);

  const cancel = (orderId: number) => {
    // FIXME: 취소 신청 페이지 및 취소신청 데이터
    // hard-coded
    const cancelRequestData: OrderCancelRequest = {
      orderId,
      reasonType: 'ETC',
      reasonDetail: '개발용 하드코딩입니다',
      refundBankCode: '088',
      refundBankAccount: '110-123-456789',
      refundHolder: '김신한',
    };

    present({
      header: '주문 취소',
      message: '선택한 주문건을 취소하시겠습니까?',
      onDidDismiss: ({ detail: { role } }) => {
        if (role === 'OK') {
          modamCancelOrder(user.userData.accessToken, orderId, cancelRequestData)
            .then((data) => {
              if (data.result) {
                const resultMessage =
                  data.status === 'REQUEST_CANCEL_ORDER'
                    ? '주문 취소 요청이 접수되었습니다. 판매자 확인 후 처리됩니다.'
                    : '해당 주문이 주문 취소되었습니다.';
                present({
                  message: resultMessage,
                  buttons: [
                    {
                      text: '확인',
                      role: 'OK',
                      handler: () => {
                        getData();
                      },
                    },
                  ],
                });
              }
            })
            .catch((err) => {
              present({
                message: '주문 취소 요청에 실패했습니다.',
                buttons: [
                  {
                    text: '확인',
                    role: 'OK',
                    handler: () => {
                      // getData();
                    },
                  },
                ],
              });
            });
        }
      },
      buttons: [
        {
          text: '확인',
          role: 'OK',
        },
        { text: '취소', role: 'CANCEL' },
      ],
    });
  };

  const decideOrder = (orderId: number) => {
    present({
      header: '구매결정',
      subHeader: '선택한 주문을 구매결정하시겠습니까?',
      message: '구매결정은 되돌릴 수 없습니다.',
      cssClass: 'confirm-ion-alert',
      onDidDismiss: ({ detail: { role } }) => {
        if (role === 'OK') {
          modamDecideOrder(user.userData.accessToken, orderId).then(() => {
            present({
              message: '주문건이 구매결정되었습니다.',
              buttons: [
                {
                  text: '확인',
                  role: 'OK',
                  handler: () => {
                    getData();
                  },
                },
              ],
            });
          });
        }
      },
      buttons: [
        {
          text: '확인',
          role: 'OK',
        },
        { text: '취소', role: 'CANCEL' },
      ],
    });
  };

  const repurchase = (products: Array<Product>) => {
    const items: any = products.map((product) => ({ productId: product.productId, cartQuantity: product.productQuantity }));

    setPayCertify(true);
    setPayProduct({
      products: items,
      fromCart: false,
    });
    history.push(routes.PAY);
  };

  return (
    <IonPage>
      <IonContent>
        <DefaultHeader title="주문내역" showBackButton={true} />
        {list.map((catalog) => (
          <IonCard
            key={`${catalog.orderNo}`}
            style={{
              margin: '1rem',
              boxShadow: 'rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px',
            }}
          >
            <IonCardContent style={{ padding: '10px 12px' }}>
              <IonGrid>
                <IonRow>
                  <IonCol size="12">
                    <IonText>
                      <h4 style={{ fontSize: '16px', fontWeight: 600 }}>{codeName(catalog.orderStatusType, codes.OrderStatusType)}</h4>
                    </IonText>
                    <IonText>{catalog.orderNo}</IonText>
                  </IonCol>
                  <IonCol size="100px">
                    <IonRouterLink routerLink={`/order/${catalog.orderId}`}>
                      <IonImg style={{ width: '100px', borderRadius: '10px' }} src={catalog.imageUriPath} />
                    </IonRouterLink>
                  </IonCol>
                  <IonCol>
                    {catalog.paymentCompletedAt ? (
                      <IonText>
                        <span style={{ fontSize: '12px', color: '#888', letterSpacing: '-0.1px' }}>
                          {catalog.paymentCompletedAt.substring(0, 10).replaceAll('-', '. ')} 결제
                        </span>
                      </IonText>
                    ) : (
                      catalog.virtualAccountExpiredDate && (
                        <IonText>
                          <span style={{ fontSize: '12px', color: '#888', letterSpacing: '-0.1px' }}>
                            {catalog.virtualAccountExpiredDate.replaceAll('-', '. ')} 입금 마감
                          </span>
                        </IonText>
                      )
                    )}
                    <IonText>
                      <h5 style={{ fontSize: '16px', fontWeight: 800 }}>
                        <IonRouterLink routerLink={`/order/${catalog.orderId}`} style={{ color: '#737373' }}>
                          {catalog.productName}
                        </IonRouterLink>
                      </h5>
                    </IonText>
                    <IonText style={{ width: '100%', display: 'inline-block', marginTop: '1rem' }}>
                      <span>{catalog.orderAmount.toLocaleString('ko')} 원</span>
                    </IonText>
                    <IonText style={{ width: '100%', display: 'inline-block' }}>
                      <IonRouterLink style={{ fontSize: '14px', fontWeight: 400 }} routerLink={`/order/${catalog.orderId}`}>
                        주문상세 &gt;&gt;
                      </IonRouterLink>
                    </IonText>
                  </IonCol>
                  <IonCol size="12" style={{ marginTop: '10px' }}>
                    {(catalog.orderStatusType === 'REQUEST_PAYMENT' || catalog.orderStatusType === 'COMPLETED_PAYMENT') && (
                      <IonButton type="submit" color="medium" expand="block" fill="outline" onClick={() => cancel(catalog.orderId)}>
                        주문취소
                      </IonButton>
                    )}
                    {/* TODO 현재 프로세스 없음
                    {(catalog.orderStatusType === 'CHECK_ORDER' ||
                      catalog.orderStatusType === 'DELIVERY_PROGRESS' ||
                      catalog.orderStatusType === 'DELIVERY_COMPLETED') && (
                      <IonButton type="submit" color="medium" expand="block" fill="outline">
                        교환/반품
                      </IonButton>
                    )}*/}
                    {(catalog.orderStatusType === 'DELIVERY_PROGRESS' || catalog.orderStatusType === 'DELIVERY_COMPLETED') && (
                      <IonButton type="submit" color="medium" expand="block" fill="outline" onClick={() => decideOrder(catalog.orderId)}>
                        구매확정
                      </IonButton>
                    )}
                    {catalog.orderStatusType === 'PURCHASE_DECIDED' && catalog.orderAmount !== 0 && (
                      <IonButton
                        type="submit"
                        color="medium"
                        expand="block"
                        fill="outline"
                        onClick={() => repurchase(catalog.orderProducts)}
                      >
                        재구매
                      </IonButton>
                    )}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardContent>
          </IonCard>
        ))}
        <DefaultFooter />
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: selectors.getUser(state),
    codes: selectors.getCodes(state),
  }),
  mapDispatchToProps: { setPayCertify, setPayProduct },
  component: OrderListPage,
});
