import React, { useEffect, useContext } from 'react';
import { IonRouterContext } from '@ionic/react';
import routes from '../routes';
import { setAccessToken, setIsLoggedInData, setUserId, setUserNameData } from '../data/dataApi';

interface RedirectToLoginProps {
  setUserData: Function;
  setUserName: Function;
  user: any;
}

const RedirectToLogin: React.FC<RedirectToLoginProps> = ({ setUserData, setUserName, user }) => {
  const ionRouterContext = useContext(IonRouterContext);

  user.userData.userId = '';
  user.userData.userName = '';
  user.userData.accessToken = '';
  user.userData.isLoggedIn = false;

  useEffect(() => {
    (async () => {
      await setAccessToken('');
      await setIsLoggedInData(false);
      await setUserNameData('');
      await setUserId('');
    })();
    setUserData(user);
    setUserName('');
    ionRouterContext.push(routes.LOGIN);
  }, [setUserData, setUserName, ionRouterContext, user]);
  return null;
};

export default RedirectToLogin;
