import { getCategoryData, setCategoryIdData, setCurrentCategoryIdData } from '../dataApi';
import { ActionType } from '../../util/types';
import { CategoryState } from './category.state';

export const loadCategoryData = () => async (dispatch: React.Dispatch<any>) => {
  const data = await getCategoryData();
  dispatch(setCategoryData(Object(data)));
};

export const setCategoryData = (categoryData: Partial<CategoryState>) =>
  ({
    type: 'set-category-data',
    categoryData,
  } as const);

export const setCategoryId = (categoryId: string) => async () => {
  await setCategoryIdData(categoryId);
  return {
    type: 'set-category-id',
    categoryId,
  } as const;
};

export const setCurrentCategoryId = (categoryId: string) => async () => {
  await setCurrentCategoryIdData(categoryId);
  return {
    type: 'set-current-category-id',
    categoryId,
  } as const;
};

export type CategoryActions =
  | ActionType<typeof setCategoryData>
  | ActionType<typeof setCategoryId>
  | ActionType<typeof setCurrentCategoryId>;
