import { IonCol, IonFooter, IonGrid, IonRow } from '@ionic/react';
import React from 'react';
import { Link } from 'react-router-dom';
import './DefaultFooter.scss';

interface OwnProps {}

interface StateProps {}

interface DispatchProps {}

interface DefaultFooterProps extends OwnProps, StateProps, DispatchProps {}

const DefaultFooter: React.FC<DefaultFooterProps> = ({}) => {
  return (
    <IonFooter id="defaultFooter">
      <IonGrid className="footer_box">
        <IonRow>
          <IonCol>
            <b className="company_phone">
              <a href="tel:02-3486-8500">고객센터 02-3486-8500</a>
            </b>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <ul className="info_list">
              <li>
                <Link to="/notice">공지사항</Link>
              </li>
              <li>
                <Link to="/terms/service">이용약관</Link>
              </li>
              <li>
                <Link to="/terms/privacy">개인정보처리방침</Link>
              </li>
            </ul>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="company_con">
            <strong>모던한담 논현지점</strong>
            <address>서울 강남구 선릉로125길 5, 1층</address>
            <p>사업자등록번호 : 282-85-01971 {/* <a href="#none">[사업자정보조회]</a> */}</p>
            <p>통신판매업신고 : 2023-서울강남-01118</p>
            <p>대표이사 : 박주혁</p>
            <p>개인정보보호책임자 : 장근정</p>
            <p>이메일 : modam@hexai.co.kr</p>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonFooter>
  );

  // TODO: 회사정보 나중에 어딘가 관리자에 설정으로 빼야..
};

export default DefaultFooter;
