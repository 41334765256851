import { DeliveryState } from './delivery.state';
import { DeliveryActions } from './delivery.actions';

export function deliveryReducer(state: DeliveryState, action: DeliveryActions): DeliveryState {
  switch (action.type) {
    case 'set-delivery-data':
      return { ...state, ...action.deliveryData };
    default:
      return state;
  }
}
