export interface Product {
  productId: number; // 상품 아이디
  productName: string; // 상품 명
  productSalePrice: number; // 판매가
  productStockQuantity: number; //상품 재고
  purpose: string; // 조리방법
  weight: string; // 중량
  size: string; //크기
  storageMethod: string; // 보관방법
  imageUriPath: string; // 상품 이미지
  productStatusType: ProductStatusType; // 상품 상태
  cartId: string; // 장바구니 아이디
  cartOn: boolean; // 장바구니 담김 여부
}

export enum ProductStatusType {
  READY = 'READY', // 판매대기
  SALE = 'SALE', // 판매중
  SOLD_OUT = 'SOLD_OUT', // 품절
  STOP = 'STOP', // 판매중지
  CLOSE = 'CLOSE', // 판매종료
  FORBID = 'FORBID', // 판매금지
}
