import { CodeObject } from './code.state';
import { CodeStateActions } from './code.actions';

export function codeReducer(state: CodeObject, action: CodeStateActions): CodeObject {
  switch (action.type) {
    case 'set-code-list':
      return { ...state, ...action.codes };
    default:
      return state;
  }
}
