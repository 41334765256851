import React, { useEffect, useState } from 'react';
import { IonCol, IonContent, IonGrid, IonImg, IonList, IonPage, IonRow } from '@ionic/react';
import { connect } from '../data/connect';

import { RouteComponentProps } from 'react-router';
import Menu from '../components/menu/Menu';
import DefaultHeader from '../components/header/DefaultHeader';
import './RecipePage.scss';
import DefaultFooter from '../components/footer/DefaultFooter';
import SubMenu from '../components/menu/SubMenu';
import RecipeCard from '../components/product/RecipeCard';

interface OwnProps extends RouteComponentProps {}

interface StateProps {}

interface DispatchProps {}

interface RecipeProps extends OwnProps, StateProps, DispatchProps {}

const RecipePage: React.FC<RecipeProps> = ({}) => {
  // const [currentSegment, setCurrentSegment] = useState('');

  // useEffect(() => {}, [currentSegment]);

  return (
    <IonPage id="recipe_page">
      <IonContent>
        <DefaultHeader title="모담레시피" showBackButton={true} />
        <IonGrid>
          {/*메인 Tab(대분류) menu*/}
          <Menu />
          {/*<SubMenu*/}
          {/*  page={currentSegment}*/}
          {/*  change={(changedValue) => {*/}
          {/*    setCurrentSegment(changedValue);*/}
          {/*  }}*/}
          {/*/>*/}

          <IonRow>
            <IonCol>
              <IonImg src="/assets/product/recipe/recipe1.jpg" />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <strong className="recipe_tit">차돌박이 샐러드</strong>
            </IonCol>

            <IonList inset={false}>
              <RecipeCard Card={''} />
            </IonList>
          </IonRow>
        </IonGrid>

        <DefaultFooter />
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  component: React.memo(RecipePage),
});
