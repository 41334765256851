/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonPage, IonRow, IonText } from '@ionic/react';
import { connect } from '../data/connect';

import { RouteComponentProps } from 'react-router';
import DefaultHeader from '../components/header/DefaultHeader';
import DefaultFooter from '../components/footer/DefaultFooter';
import './DeliveryPage.scss';
import routes from '../routes';
import { deliverySelect } from '../data/dataApi';
import * as selectors from '../data/selectors';
import { Delivery } from '../models/Delivery';
import DeliveryCard from '../components/delivery/DeliveryCard';
import { informationCircle } from 'ionicons/icons';
import { setDeliveryData } from '../data/delivery/delivery.actions';

interface OwnProps extends RouteComponentProps {}

interface StateProps {
  user: any;
  deliveryData: any;
}

interface DispatchProps {
  setDeliveryData: typeof setDeliveryData;
}

interface DeliveryProps extends OwnProps, StateProps, DispatchProps {}

const DeliveryPage: React.FC<DeliveryProps> = ({ user, match, deliveryData, setDeliveryData }) => {
  const [deliveries, setDeliveries] = useState<Delivery[]>([]); //  배송지 목록
  const [detailChk, setDetailChk] = useState(false); // 배송지 카드 클릭 여부
  const [deliveryChk, setDeliveryChk] = useState(true); // 배송지관리, 배송지변경 체크
  const [title, setTitle] = useState('배송지관리'); // 배송지 타이틀

  const getData = async () => {
    const result = await deliverySelect(user.userData.accessToken);
    if (result.result) {
      setDeliveries(result.delivery);
    } else {
    }
  };

  useEffect(() => {
    if (deliveryChk) {
      setTitle('배송지관리');
    } else {
      setTitle('배송지변경');
    }
    let deliveryPath = match.path;
    deliveryPath = deliveryPath.replace(/\//gi, '');
    if (deliveryPath == 'delivery') {
      setDeliveryChk(true);
    } else if (deliveryPath == 'deliverychange') {
      setDeliveryChk(false);
    } else {
      setDeliveryChk(true);
    }

    if (user.userData.constructor === Object && Object.keys(user.userData).length === 0) {
    } else {
      getData();
      if (detailChk) {
        setDetailChk(false);
      }
    }
  }, [user, detailChk, deliveryChk, match.params]);

  return (
    <IonPage id="delivery">
      <IonContent>
        <DefaultHeader title={title} showBackButton={true} />
        <IonGrid>
          {deliveries && deliveries.length == 0 && (
            <>
              <IonRow className="delivery_not_exist">
                <IonCol className="ion-text-center">
                  <IonIcon icon={informationCircle} size={'large'} color="warning" />
                  <IonText>등록하신 배송지가 없습니다.</IonText>
                </IonCol>
              </IonRow>
            </>
          )}

          {deliveries && deliveries.length > 0 && (
            <>
              {deliveries.map((delivery) => (
                <DeliveryCard
                  delivery={delivery}
                  deliveryListLength={deliveries.length}
                  accessToken={user.userData.accessToken}
                  setDetailChk={setDetailChk}
                  deliveryChk={deliveryChk}
                  deliveryData={deliveryData}
                  setDeliveryData={setDeliveryData}
                  key={delivery.destId}
                />
              ))}
            </>
          )}
          <IonRow>
            <IonCol>
              <IonButton type="button" expand="block" className="btn" routerLink={routes.DELIVERY_ADDITIONAL}>
                배송지 추가
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
        <DefaultFooter />
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: selectors.getUser(state),
    deliveryData: selectors.getDelivery(state),
  }),
  mapDispatchToProps: { setDeliveryData },
  component: React.memo(DeliveryPage),
});
