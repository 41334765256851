import React from 'react';
import { IonCol, IonContent, IonGrid, IonImg, IonPage, IonRow } from '@ionic/react';
import { connect } from '../data/connect';

import { RouteComponentProps } from 'react-router';
import Menu from '../components/menu/Menu';
import DefaultHeader from '../components/header/DefaultHeader';
import DefaultFooter from '../components/footer/DefaultFooter';
import './GiftPage.scss';

interface OwnProps extends RouteComponentProps {}

interface StateProps {}

interface DispatchProps {}

interface GiftProps extends OwnProps, StateProps, DispatchProps {}

const GiftPage: React.FC<GiftProps> = ({}) => {
  return (
    <IonPage id="gift">
      <IonContent>
        <DefaultHeader title="선물세트" showBackButton={true} />
        <IonGrid>
          {/*메인 Tab(대분류) menu*/}
          <Menu />
          <IonRow className="loadImg">
            {/*<IonCol className="comingsoon">*/}
            {/*    <IonImg src="assets/gift/img_comingsoon.png" alt="준비중"  />*/}
            {/*</IonCol>*/}
            <IonCol className="giftService">
              <IonImg src="/assets/gift/gift_service.png" alt="준비중" />
              <p>임직원, 단체를 위한</p>
              <p className="strong">대량주문 상담 서비스</p>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <p className="textBox">
                품격있는 선물세트를 실속있는 가격으로,
                <br />
                상품 제안, 결제 및 주소 취합, 배송일 지정까지!
                <br />
                편리하게 관리해드리는 서비스입니다.
              </p>
            </IonCol>
          </IonRow>
          {/*<IonRow>*/}
          {/*    <IonCol className="business">*/}
          {/*        <IonImg src="assets/gift/business_service.png" alt="준비중" />*/}
          {/*    </IonCol>*/}
          {/*</IonRow>*/}
          <IonRow>
            <IonCol className="giftBox">
              <IonImg src="/assets/gift/gift_box.png" alt="선물상자" />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="subTitle">
              <p>메일 문의</p>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ellipse">
              <div>
                <p>modam@hexai.co.kr</p>
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="subTitle">
              <p>지금 바로 상담</p>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ellipse">
              <div>
                <p>02-3486-8500</p>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
        <DefaultFooter />
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  component: React.memo(GiftPage),
});
