import { ActionType } from '../../util/types';
import { PayProduct, PayState } from './pay.state';
import { getPayData, setPayCertifyData, setPayCertifyProduct } from '../dataApi';

export const loadPayData = () => async (dispatch: React.Dispatch<any>) => {
  const data = await getPayData();
  dispatch(setPayData(Object(data)));
};

export const setPayData = (payData: Partial<PayState>) =>
  ({
    type: 'set-pay-data',
    payData,
  } as const);

export const setPayCertify = (payCertify: boolean) => async () => {
  await setPayCertifyData(payCertify);
  return {
    type: 'set-pay-certify',
    payCertify,
  } as const;
};

export const setPayProduct = (payProduct: PayProduct) => async () => {
  await setPayCertifyProduct(payProduct);
  return {
    type: 'set-pay-product',
    payProduct,
  } as const;
};

export type PayActions = ActionType<typeof setPayData> | ActionType<typeof setPayCertify> | ActionType<typeof setPayProduct>;
