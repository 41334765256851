/* eslint-disable react-hooks/exhaustive-deps,@typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import {
  InputChangeEventDetail,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonList,
  IonLoading,
  IonModal,
  IonPage,
  IonRouterLink,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonViewDidLeave,
} from '@ionic/react';
import { connect } from '../data/connect';

import { RouteComponentProps } from 'react-router';
import DefaultHeader from '../components/header/DefaultHeader';
import DefaultFooter from '../components/footer/DefaultFooter';
import { useForm } from 'react-hook-form';
import './SignUpPage.scss';
import { agreementA, agreementB, emailCheck, phoneCertify, signupUser, verification } from '../data/dataApi';
import routes from '../routes';
import DaumPostcode from 'react-daum-postcode';
import { AgreementContent, Agreements, AgreementType } from '../models/Agreements';
import * as selectors from '../data/selectors';
import {
  setSocialEmail,
  setSocialId,
  setSocialPhoneNumber,
  setSocialStatus,
  setSocialType,
  setSocialUserName,
} from '../data/social/social.actions';

interface OwnProps extends RouteComponentProps {}

interface StateProps {
  social: any;
}

interface DispatchProps {
  setSocialStatus: typeof setSocialStatus;
  setSocialType: typeof setSocialType;
  setSocialId: typeof setSocialId;
  setSocialUserName: typeof setSocialUserName;
  setSocialPhoneNumber: typeof setSocialPhoneNumber;
  setSocialEmail: typeof setSocialEmail;
}

interface SignUpProps extends OwnProps, StateProps, DispatchProps {}

interface FormField {
  props: {
    name: string;
    type: string;
    placeholder?: string;
    readonly?: boolean;
    disabled?: boolean;
  };
  validationProps: {
    required?: boolean;
    pattern?: {
      value: RegExp;
      message: string;
    };
    maxLength?: {
      value: number;
      message: string;
    };
    validate?: (value: string) => boolean | string;
  };
  component?: any;
  onIonChange?: (e: CustomEvent<InputChangeEventDetail>) => void;
}

const SignUpPage: React.FC<SignUpProps> = ({
  match,
  history,
  social,
  setSocialStatus,
  setSocialType,
  setSocialId,
  setSocialUserName,
  setSocialPhoneNumber,
  setSocialEmail,
}) => {
  const [time, setTime] = useState(0); // 휴대전화 인증번호 시간
  // const [currentTime, setCurrentTime] = useState(''); // 휴대전화 인증번호 포멧
  const [chkCertSeq, setChkCertSeq] = useState(false); //휴대전화 인증번호 체크
  const [chkCode, setChkCode] = useState(false); //휴대전화 인증번호 확인 체크
  const [chkEmail, setChkEmail] = useState(false); // email 중복 확인 체크 시도 여부
  const [chkEmailAvailable, setChkEmailAvailable] = useState(false); // email 사용 가능 여부
  const [present] = useIonAlert(); // alert 폼
  const [chkSmsRecv, setChkSmsRecv] = useState(true); // sms 수신여부
  const [chkEmailRecv, setChkEmailRecv] = useState(true); // email 수신여부
  const [essentialA, setEssentialA] = useState(false); // 이용약관 필수 동의 여부
  const [essentialB, setEssentialB] = useState(false); // 개인정보 처리방침 필수 동의 여부
  const [essentialC, setEssentialC] = useState(false); // 만 14세 이상 동의 여부
  const [chkAgreements, setChkAgreements] = useState<Agreements[]>([]); // 이용약관 checked 정보

  const [isOpenPost, setIsOpenPost] = useState(false); // 우편찾기

  const [chkSocial, setChkSocial] = useState(false); // 소셜 체크용

  const modal = useRef<HTMLIonModalElement>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [agreeementDetail, setAgreementDetail] = useState<AgreementContent>({ agreeContents: '', agreementType: '', agreeTitle: '' });

  const loadAgreementDetail = async (type: AgreementType) => {
    setModalLoading(true);
    setModalOpen(true);
    const response = await agreementB(type);
    setModalLoading(false);
    if (response.result) {
      setAgreementDetail(response.agreement!);
    } else {
      setAgreementDetail({
        agreeTitle: '모던한담',
        agreeContents: '약관 로드에 실패했습니다.',
        agreementType: '',
      });
    }
  };

  const onModalClose = () => {
    setModalOpen(false);
    setAgreementDetail({ agreeContents: '', agreementType: '', agreeTitle: '' });
  };

  const socialChecked = async () => {
    setChkSocial(true);
  };

  const clearSocialData = () => {
    setSocialStatus('');
    setSocialType('');
    setSocialId('');
    setSocialUserName('');
    setSocialPhoneNumber('');
    setSocialEmail('');
  };

  useIonViewDidLeave(() => {
    clearSocialData();
  });

  const handleComplete = (data: { address: any; addressType: string; bname: string; buildingName: string; zonecode: string }) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    setValue('userZipcode', data.zonecode);
    setValue('userAddress', fullAddress);
    setIsOpenPost(false);
  };

  const timeFormat = (time: number) => {
    const m = Math.floor(time / 60).toString();
    let s = (time % 60).toString();
    if (s.length === 1) {
      s = `0${s}`;
    }
    return `${m}:${s}`;
  };

  const {
    watch,
    register,
    setValue,
    getValues,
    // setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onTouched', //입력이 터치 될 때까지 유효성 검사.
    reValidateMode: 'onChange', //입력의 재유효성 검사.
  });

  const formFields: FormField[] = [
    {
      props: {
        name: 'userEmail',
        type: 'email',
        placeholder: '이메일',
        disabled: chkEmailAvailable && social?.email,
      },
      validationProps: {
        required: true,
        pattern: {
          value: /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i,
          message: '올바른 이메일 형식이 아닙니다.',
        },
      },
      component: (
        <>
          {/* TODO: 현재 소셜 로그인 기존 회원 매칭시 별도의 가입 페이지가 없으므로 무조건 신규가입이라고 판단, 중복확인을 거친 것으로 간주 합니다 */}
          <IonButton expand="full" type="button" disabled={chkEmailAvailable} onClick={handleEmailCheck}>
            중복확인
          </IonButton>
        </>
      ),
      onIonChange: (e) => {
        if (social?.email && social?.email !== e.detail.value) {
          setChkEmailAvailable(false); // 중복확인 통과 후 변경시 다시 확인하도록
          setChkEmail(false);
        }
      },
    },
    {
      props: {
        name: 'userName',
        type: 'text',
        placeholder: '이름',
      },
      validationProps: {
        required: true,
        pattern: {
          value: /^[가-힣a-zA-Z]+$/,
          message: '한글,영문만 가능합니다.',
        },
      },
    },
    {
      props: {
        name: 'userPassword',
        type: 'password',
        placeholder: '비밀번호 (8~16자리 영문, 숫자, 특수문자 조합)',
      },
      validationProps: {
        // required: true,
        pattern: {
          value: /^(?=.*\W)(?=.*[a-zA-Z])(?=.*[\d]).{8,16}$/i,
          message: '비밀번호 (8~16자리 영문, 숫자, 특수문자 조합)',
        },
      },
    },
    {
      props: {
        name: 'rePassword',
        type: 'password',
        placeholder: '비밀번호 확인',
      },
      validationProps: {
        required: true,
        validate: (value) => {
          if (value === watch('userPassword', '')) {
            return true;
          }
          return '패스워드가 일치하지 않습니다';
        },
      },
    },

    {
      props: {
        name: 'userZipcode',
        type: 'text',
        placeholder: '우편번호',
        readonly: true,
      },
      validationProps: {
        // required: true,
      },
      component: (
        <>
          <IonButton expand="full" type="button" onClick={() => onChangeOpenPost()}>
            우편번호찾기
          </IonButton>
        </>
      ),
    },

    {
      props: {
        name: 'userAddress',
        type: 'text',
        placeholder: '주소',
        readonly: true,
      },
      validationProps: {
        // // required: true,
      },
    },

    {
      props: {
        name: 'userAddressDetail',
        type: 'text',
        placeholder: '상세주소',
      },
      validationProps: {
        // required: true,
      },
    },

    {
      props: {
        name: 'userCellphoneNo',
        type: 'tel',
        placeholder: '휴대전화번호',
        readonly: chkCertSeq,
      },
      validationProps: {
        required: true,
        pattern: {
          value: /^(?:(010-\d{4})|(01[1|6|7|8|9]-\d{3,4}))-(\d{4})$/,
          message: '010-0000-0000 휴대전화 형식으로 입력해주세요.',
        },
        maxLength: {
          value: 13,
          message: '13자리를 넘을 수 없습니다.',
        },
      },
      component: (
        <IonButton type="button" expand="full" disabled={chkCertSeq} onClick={handlePhoneCheck}>
          인증
        </IonButton>
      ),
    },
    {
      props: {
        name: 'authentication',
        type: 'text',
        placeholder: '인증번호',
        readonly: chkCode,
      },
      validationProps: {
        // required: true,
      },
      component: (
        <>
          <IonButton type="button" expand="full" onClick={verificationCode} disabled={chkCode}>
            확인
          </IonButton>
        </>
      ),
    },
  ];

  const autoHyphen = (userCellphoneNo: string) => {
    return setValue(
      'userCellphoneNo',
      userCellphoneNo == null
        ? null
        : userCellphoneNo
            .replace(/[^0-9]/g, '')
            .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3')
            .replace(/(\-{1,2})$/g, ''),
    );
  };

  const onChangeOpenPost = () => {
    console.log(isOpenPost);
    setIsOpenPost(!isOpenPost);
  };

  const handelClickRadioSms = (e: any) => {
    if (e.target.value === 'true') {
      setChkSmsRecv(true);
    } else {
      setChkSmsRecv(false);
    }
  };

  const handelClickRadioEmail = (e: any) => {
    if (e.target.value === 'true') {
      setChkEmailRecv(true);
    } else {
      setChkEmailRecv(false);
    }
  };

  const handelClickAgreement = async (e: any, agreeCode: AgreementType) => {
    let result;
    if (e) {
      switch (agreeCode) {
        case AgreementType.AGE_AGREEMENT:
          result = await agreementA(AgreementType.AGE_AGREEMENT);
          break;
        case AgreementType.TERMS_OF_SERVICE:
          result = await agreementA(AgreementType.TERMS_OF_SERVICE);
          break;
        case AgreementType.PRIVACY_POLICY:
          result = await agreementA(AgreementType.PRIVACY_POLICY);
          break;
        default:
          break;
      }
      setChkAgreements([...chkAgreements, result?.responseData]);
    }
  };

  const checkHandlerEssentialA = () => {
    setEssentialA(!essentialA);
  };
  const checkHandlerEssentialB = () => {
    setEssentialB(!essentialB);
  };
  const checkHandlerEssentialC = () => {
    setEssentialC(!essentialC);
  };

  const onSubmit = async () => {
    if (!chkEmail) {
      present({
        header: '모던한담',
        message: '이메일 중복 확인을 해주세요.',
        buttons: [
          {
            text: '확인',
            role: 'cancel',
            cssClass: 'secondary',
          },
        ],
      });
      return;
    } else if (!chkEmailAvailable) {
      present({
        header: '모던한담',
        message: '이미 사용중인 이메일입니다. 다른 이메일 주소를 사용하세요.',
        buttons: [
          {
            text: '확인',
            role: 'cancel',
            cssClass: 'secondary',
          },
        ],
      });
      return;
    } else if (!getValues('userZipcode')) {
      present({
        header: '모던한담',
        message: '우편번호를 입력해주세요.',
        buttons: [
          {
            text: '확인',
            role: 'cancel',
            cssClass: 'secondary',
          },
        ],
      });
      return;
    } else if (!chkCertSeq) {
      present({
        header: '모던한담',
        message: '휴대전화 인증을 해주세요.',
        buttons: [
          {
            text: '확인',
            role: 'cancel',
            cssClass: 'secondary',
          },
        ],
      });
      return;
    } else if (!chkCode) {
      present({
        header: '모던한담',
        message: '인증번호 확인을 해주세요.',
        buttons: [
          {
            text: '확인',
            role: 'cancel',
            cssClass: 'secondary',
          },
        ],
      });
      return;
    } else if (!getValues('essential_a')) {
      present({
        header: '모던한담',
        message: '홈페이지 이용약관 동의를 체크해주세요.',
        buttons: [
          {
            text: '확인',
            role: 'cancel',
            cssClass: 'secondary',
          },
        ],
      });
      return;
    } else if (!getValues('essential_b')) {
      present({
        header: '모던한담',
        message: '개인정보 처리방침 동의를 체크해주세요.',
        buttons: [
          {
            text: '확인',
            role: 'cancel',
            cssClass: 'secondary',
          },
        ],
      });
      return;
    } else if (!getValues('essential_c')) {
      present({
        header: '모던한담',
        message: '만 14세 이상만 가입이 가능합니다.',
        buttons: [
          {
            text: '확인',
            role: 'cancel',
            cssClass: 'secondary',
          },
        ],
      });
      return;
    }

    // 우편번호 ,주소, sms 수신, email 수신, 이용약관 동의 (별도 set 컬럼), 회원가입 유형
    const items: any = [];
    let certifications;
    if (social && social.status == 'signup') {
      certifications = { type: social.type, accountId: social.id };
      setValue('userPassword', null);
    } else {
      certifications = { type: 'PASSWORD', accountId: getValues('userEmail') };
    }
    items.push(certifications);

    setValue('userCellphoneNo', getValues('userCellphoneNo').replace(/-/gi, ''));
    setValue('userCertifications', items);
    setValue('agreements', chkAgreements.slice(-2));
    setValue('enableReceiveMessage', chkSmsRecv);
    setValue('enableReceiveEmail', chkEmailRecv);

    const result = await signupUser(getValues());
    if (result.result) {
      present({
        header: '모던한담',
        message: '회원가입이 완료 되었습니다.',
        buttons: [
          {
            text: '확인',
            role: 'confirm',
            cssClass: 'secondary',
            handler: () => {
              history.push(routes.LOGIN);
            },
          },
        ],
      });
    } else {
      present({
        header: '모던한담',
        message: '회원가입에 실패 하였습니다.',
        buttons: [
          {
            text: '확인',
            role: 'confirm',
            cssClass: 'secondary',
          },
        ],
      });
    }
  };

  function handleEmailCheck() {
    const regex = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    const userEmail = watch('userEmail', '');

    if (!regex.test(userEmail)) {
      present({
        header: '모던한담',
        message: 'example@example.com 형식으로 입력해주세요.',
        buttons: [
          {
            text: '확인',
            role: 'confirm',
            cssClass: 'secondary',
          },
        ],
      });
      return;
    }
    if (!userEmail) {
      present({
        header: '모던한담',
        message: '이메일 주소를 입력해주세요.',
        buttons: [
          {
            text: '확인',
            role: 'confirm',
            cssClass: 'secondary',
          },
        ],
      });
      return;
    }

    emailCheck(userEmail).then((exist: boolean) => {
      console.log(exist);
      setChkEmail(true); // 체크 시도
      if (exist) {
        setChkEmailAvailable(false);
      } else {
        setChkEmailAvailable(true);
      }
    });
  }

  function handlePhoneCheck() {
    const regex = /^(?:(010-\d{4})|(01[1|6|7|8|9]-\d{3,4}))-(\d{4})$/;
    const userCellphoneNo = watch('userCellphoneNo', '');

    if (!regex.test(userCellphoneNo)) {
      present({
        header: '모던한담',
        message: '010-0000-0000 휴대전화 형식으로 입력해주세요.',
        buttons: [
          {
            text: '확인',
            role: 'confirm',
            cssClass: 'secondary',
          },
        ],
      });
      return;
    }
    if (!userCellphoneNo) {
      present({
        header: '모던한담',
        message: '휴대전화번호를 입력해주세요.',
        buttons: [
          {
            text: '확인',
            role: 'confirm',
            cssClass: 'secondary',
          },
        ],
      });
      return;
    }

    phoneCertify(userCellphoneNo).then((response: { result: any; certSeq: any }) => {
      if (response.result) {
        setValue('certSeq', response.certSeq);
        setChkCertSeq(true);
        setTime(208);
      } else {
        present({
          header: '모던한담',
          message: '다시 시도해주세요.',
          buttons: [
            {
              text: '확인',
              role: 'confirm',
              cssClass: 'secondary',
            },
          ],
        });
        setChkCertSeq(false);
      }
    });
  }

  function verificationCode() {
    const code = watch('authentication', '');
    if (!chkCertSeq) {
      present({
        header: '모던한담',
        message: '휴대전화 인증번호를 발급 받으세요.',
        buttons: [
          {
            text: '확인',
            role: 'confirm',
            cssClass: 'secondary',
          },
        ],
      });
      return;
    } else if (chkCertSeq && !code) {
      present({
        header: '모던한담',
        message: '인증번호를 입력하세요.',
        buttons: [
          {
            text: '확인',
            role: 'confirm',
            cssClass: 'secondary',
          },
        ],
      });
      return;
    }

    const checkedData = verification(getValues('certSeq'), code);
    checkedData.then((response: { result: any; code: string; message: any }) => {
      if (response.result) {
        present({
          header: '모던한담',
          message: '인증이 완료 되었습니다.',
          buttons: [
            {
              text: '확인',
              role: 'confirm',
              cssClass: 'secondary',
              handler: () => {
                setTime(0);
                setChkCode(true);
              },
            },
          ],
        });
      } else {
        // 이미 인증
        if (response.code == 'A0001') {
          present({
            header: '모던한담',
            message: response.message,
            buttons: [
              {
                text: '확인',
                role: 'confirm',
                cssClass: 'secondary',
              },
            ],
          });

          // 인증 시간 만료
        } else if (response.code == 'A0002') {
          present({
            header: '모던한담',
            message: response.message,
            buttons: [
              {
                text: '확인',
                role: 'confirm',
                cssClass: 'secondary',
              },
            ],
          });
          // 인증 값이 다름
        } else if (response.code == 'A0003') {
          present({
            header: '모던한담',
            message: response.message,
            buttons: [
              {
                text: '확인',
                role: 'confirm',
                cssClass: 'secondary',
              },
            ],
          });
        }
      }
    });
  }

  useEffect(() => {
    if (chkSocial && social.status == 'signup') {
      setChkSocial(false);
      setValue('userEmail', social.email);
      setValue('userName', social.userName);
      setValue('userCellphoneNo', social.phone);

      if (social.email) {
        handleEmailCheck();
      }
      // setChkEmail(true);
      // setChkEmailAvailable(true);
    }
  }, [chkSocial]);

  useEffect(() => {
    if (social.status != 'signup') {
      setValue('userEmail', '');
      setValue('userName', '');
      setValue('userPassword', '');
      setValue('rePassword', '');
      setValue('userCellphoneNo', '');
      setValue('userZipcode', '');
      setValue('userAddress', '');
      setValue('userAddressDetail', '');
      setValue('agreements', []);
      setValue('authentication', '');
      setValue('essential_a', false);
      setValue('essential_b', false);
      setValue('essential_c', false);
      setValue('userCertifications', []);

      setTime(0);
      setEssentialA(false);
      setEssentialB(false);
      setChkCertSeq(false);
    }
  }, [match.params]);

  useEffect(() => {
    setTime(0);
    setChkCertSeq(false);
    setChkCode(false);

    const timer = setTimeout(() => {
      socialChecked();
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (time > 0) {
      const counter = setInterval(() => {
        //TODO 핸드폰 인증시 인증 만료시간 필요함. 백엔드 값 추가해야됨.
        // const gap = Math.floor((new Date(currentTime.toString()).getTime() - new Date().getTime()) / 1000);
        setTime(time - 1);
      }, 1000);
      return () => clearInterval(counter);
    }
  }, [time]);

  return (
    <IonPage id="signup_page">
      <IonContent>
        <DefaultHeader title="회원가입" showBackButton={true} />
        <IonGrid>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <IonList>
              {formFields.map((field, index) => {
                const { props, component, validationProps, onIonChange } = field;

                return (
                  <React.Fragment key={`form_field_${index}`}>
                    {social.status == 'signup' && (props.name == 'userPassword' || props.name == 'rePassword') ? null : props.name ==
                      'userCellphoneNo' ? (
                      <IonItem lines="none">
                        <>
                          <IonInput
                            {...(props as any)}
                            {...register(props.name, {
                              ...validationProps,
                            })}
                            autocomplete={'new-password'}
                            maxlength={13}
                            onIonChange={(e) => autoHyphen(String(e.detail.value))}
                          />

                          {component}
                        </>
                      </IonItem>
                    ) : (
                      <IonItem lines="none">
                        <>
                          <IonInput
                            {...(props as any)}
                            {...register(props.name, {
                              ...validationProps,
                            })}
                            autocomplete={'nope'}
                            onIonChange={onIonChange}
                          >
                            {props.name == 'authentication' && (
                              <>
                                {chkCertSeq && (
                                  <div className="code_time">
                                    <IonText>{timeFormat(time)}</IonText>
                                  </div>
                                )}
                              </>
                            )}
                          </IonInput>

                          {component}
                        </>
                      </IonItem>
                    )}

                    {props.name == 'userZipcode' && (
                      <IonRow>
                        <IonCol>
                          {isOpenPost && (
                            <div>
                              <DaumPostcode autoClose onComplete={handleComplete} />
                            </div>
                          )}
                        </IonCol>
                      </IonRow>
                    )}

                    {/* 에러메세지 */}
                    {errors[props.name] && errors[props.name].message && (
                      <IonText>
                        <p>{errors[props.name].message}</p>
                      </IonText>
                    )}

                    {props.name == 'userEmail' && chkEmail && (
                      <IonRow className="btn_rt">
                        <IonCol>
                          <IonText>{chkEmailAvailable ? '사용 가능한 이메일입니다.' : '이미 사용 중인 이메일입니다.'}</IonText>
                        </IonCol>
                      </IonRow>
                    )}
                  </React.Fragment>
                );
              })}

              {chkCertSeq && !chkCode && (
                <IonRow className="btn_rt">
                  <IonCol>
                    <IonText>
                      인증번호가 도착하지 않으셨나요? <br /> 인증번호가 도착하지 않으셨다면 '재전송' 버튼을 눌러주세요.
                    </IonText>
                    <IonButton type="button" expand="full" onClick={handlePhoneCheck}>
                      재전송
                    </IonButton>
                  </IonCol>
                </IonRow>
              )}

              <IonRow className="radio_wrap">
                <IonCol>
                  <div>
                    <label>SMS 수신</label>
                    <div className="radio_box">
                      <input type="radio" name="sms" id="sms_type1" value="true" checked={chkSmsRecv} onChange={handelClickRadioSms} />
                      <label htmlFor="sms_type1">동의</label>
                    </div>
                    <div className="radio_box">
                      <input type="radio" name="sms" id="sms_type2" value="false" checked={!chkSmsRecv} onChange={handelClickRadioSms} />
                      <label htmlFor="sms_type2">동의안함</label>
                    </div>
                  </div>

                  <div>
                    <label>이메일 수신</label>
                    <div className="radio_box">
                      <input
                        type="radio"
                        name="email"
                        id="sms_type3"
                        value="true"
                        checked={chkEmailRecv}
                        onChange={handelClickRadioEmail}
                      />
                      <label htmlFor="sms_type3">동의</label>
                    </div>

                    <div className="radio_box">
                      <input
                        type="radio"
                        name="email"
                        id="sms_type4"
                        value="false"
                        checked={!chkEmailRecv}
                        onChange={handelClickRadioEmail}
                      />
                      <label htmlFor="sms_type4">동의안함</label>
                    </div>
                  </div>
                </IonCol>
              </IonRow>

              <IonRow className="checked_wrap">
                <IonCol>
                  <div className="checked_box">
                    <input
                      type="checkbox"
                      id="agreement1"
                      onClick={(e) => {
                        setValue('essential_a', e.currentTarget.checked);
                        handelClickAgreement(e.currentTarget.checked, AgreementType.TERMS_OF_SERVICE);
                      }}
                      checked={essentialA}
                      onChange={() => checkHandlerEssentialA()}
                    />
                    <label htmlFor="agreement1">이용약관 필수 동의</label>
                    <IonRouterLink onClick={() => loadAgreementDetail(AgreementType.TERMS_OF_SERVICE)}>자세히보기</IonRouterLink>
                  </div>

                  <div className="checked_box">
                    <input
                      type="checkbox"
                      id="agreement2"
                      onClick={(e) => {
                        setValue('essential_b', e.currentTarget.checked);
                        handelClickAgreement(e.currentTarget.checked, AgreementType.PRIVACY_POLICY);
                      }}
                      checked={essentialB}
                      onChange={() => checkHandlerEssentialB()}
                    />
                    <label htmlFor="agreement2">개인정보 처리방침 필수 동의</label>
                    <IonRouterLink onClick={() => loadAgreementDetail(AgreementType.PRIVACY_POLICY)}>자세히보기</IonRouterLink>
                  </div>

                  <div className="checked_box">
                    <input
                      type="checkbox"
                      id="agreement3"
                      onClick={(e) => {
                        setValue('essential_c', e.currentTarget.checked);
                        handelClickAgreement(e.currentTarget.checked, AgreementType.AGE_AGREEMENT);
                      }}
                      checked={essentialC}
                      onChange={() => checkHandlerEssentialC()}
                    />
                    <label htmlFor="agreement3">만 14세 이상입니다.</label>
                  </div>
                </IonCol>
              </IonRow>

              <IonRow className="btn_box">
                <IonCol>
                  <IonButton type="submit" expand="full">
                    회원가입
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonList>
          </form>
        </IonGrid>
        {/* <IonButton id="open-modal" expand="block">
          Open
        </IonButton> */}
        <IonModal className="agreement-modal" ref={modal} isOpen={modalOpen} onDidDismiss={onModalClose}>
          <IonLoading isOpen={modalLoading} message={'불러오는 중...'} />
          <IonHeader>
            <IonToolbar>
              <IonTitle>{agreeementDetail.agreeTitle}</IonTitle>
              <IonButtons slot="end">
                <IonButton strong={true} onClick={() => modal.current?.dismiss()}>
                  닫기
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <div
            className="content-wrapper toastui-editor-contents"
            dangerouslySetInnerHTML={{ __html: agreeementDetail?.agreeContents }}
          ></div>
        </IonModal>
        <DefaultFooter />
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    social: selectors.getSocial(state),
  }),
  mapDispatchToProps: {
    setSocialStatus,
    setSocialType,
    setSocialId,
    setSocialUserName,
    setSocialPhoneNumber,
    setSocialEmail,
  },
  component: SignUpPage,
});
