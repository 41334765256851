import { ActionType } from '../../util/types';
import { DeliveryState } from './delivery.state';

export const loadDeliveryData = () => async (dispatch: React.Dispatch<any>) => {
  // TODO 새로고침 시 사용 예정
};

export const setDeliveryData = (deliveryData: Partial<DeliveryState>) =>
  ({
    type: 'set-delivery-data',
    deliveryData,
  } as const);

export type DeliveryActions = ActionType<typeof setDeliveryData>;
