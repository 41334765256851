import React from 'react';
import { IonButton, IonCol, IonContent, IonGrid, IonPage, IonRow, IonTextarea } from '@ionic/react';
import { connect } from '../data/connect';

import { RouteComponentProps } from 'react-router';
import DefaultHeader from '../components/header/DefaultHeader';
import DefaultFooter from '../components/footer/DefaultFooter';
import './InquiryPage.scss';
import ProductDetailInquiryPage from '../components/product/ProductDetailInquiry';

interface OwnProps extends RouteComponentProps {}

interface StateProps {}

interface DispatchProps {}

interface InquiryProps extends OwnProps, StateProps, DispatchProps {}

const InquiryPage: React.FC<InquiryProps> = ({}) => {
  return (
    <IonPage id="inquiry">
      <IonContent>
        <DefaultHeader title="문의하기" showBackButton={true} />
        <IonGrid>
          <ProductDetailInquiryPage Card={''}></ProductDetailInquiryPage>

          <IonRow className="text_box">
            <IonCol>
              <IonTextarea placeholder="내용을 입력해주세요."></IonTextarea>
            </IonCol>
          </IonRow>

          <IonRow className="btn_box">
            <IonCol>
              <IonButton expand="full">확인</IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
        <DefaultFooter />
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  component: React.memo(InquiryPage),
});
