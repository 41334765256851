import { getUserData, setAccessToken, setIsLoggedInData, setUserId, setUserNameData } from '../dataApi';
import { ActionType } from '../../util/types';
import { Login } from '../../models/User';

export const loadUserData = () => async (dispatch: React.Dispatch<any>) => {
  dispatch(setLoading(true));
  const data = await getUserData();
  if (data.isLoggedIn) {
    dispatch(setUserData(Object(data)));
  }
  dispatch(setLoading(false));
};

export const setLoading = (isLoading: boolean) =>
  ({
    type: 'set-user-loading',
    isLoading,
  } as const);

export const setUserData = (userData: Partial<Login>) =>
  ({
    type: 'set-user-userdata',
    userData,
  } as const);

export const setUserToken = (userToken: string) => async () => {
  await setAccessToken(userToken);
  return {
    type: 'set-user-usertoken',
    userToken,
  } as const;
};

export const setIsLoggedIn = (loggedIn: boolean) => async () => {
  await setIsLoggedInData(loggedIn);
  return {
    type: 'set-is-loggedin',
    loggedIn,
  } as const;
};

export const setUserName = (username: string) => async () => {
  await setUserNameData(username);
  return {
    type: 'set-username',
    username,
  } as const;
};

export const setId = (userId: string) => async () => {
  await setUserId(userId);
  return {
    type: 'set-userid',
    userId,
  } as const;
};

export const setDarkMode = (darkMode: boolean) =>
  ({
    type: 'set-dark-mode',
    darkMode,
  } as const);

export type UserActions =
  | ActionType<typeof setLoading>
  | ActionType<typeof setUserData>
  | ActionType<typeof setUserToken>
  | ActionType<typeof setIsLoggedIn>
  | ActionType<typeof setUserName>
  | ActionType<typeof setDarkMode>;
