import React from 'react';
import { IonRow, IonCol, IonImg, IonText } from '@ionic/react';
import './InquiryDetailCard.scss';

interface InquiryDetailCardProps {
  Card: {};
}

const InquiryDetailCard: React.FC<InquiryDetailCardProps> = ({}) => {
  return (
    <>
      <IonRow className="inquiry">
        <IonCol>
          <div className="complete_box">
            <span>
              금일 배송되었다는 문자 수신하였는데요.
              <br /> 현재 성남지역 파업으로 배송이 진행되지 않는 것으로
              <br /> 알고 있습니다.
              <br /> 모든 상품들이 반송처리되어 있는 상황인데 정상적으로
              <br /> 발신처리가 된 것인지,
              <br /> 수신은 가능한 것인지 확인 부탁드리고자 합니다.
            </span>
            <div className="state_box">
              <span>답변 완료</span>
              <span>2021-08-25</span>
            </div>
          </div>
          <div className="answer_box">
            <span>
              오전 11시까지 주문하시면 당일 발송되어요.
              <br /> 금, 토, 일요일 주문은 차주 월요일에 발송해 드립니다.
              <br /> 주문금액이 10만원 이상인 경우 무료배송,
              <br /> 그 외 배송료는 3,500원입니다.
              <br /> 도서, 산간 일부 지역은 배송비가 추가되어요.
              <br />
              <br /> 평균 1~2일 이내로 수령 가능하며,
              <br /> 기상악화 및 상황에 따라 지연 배송이 발생될 수 있습니다.
            </span>
          </div>
        </IonCol>
      </IonRow>

      <IonRow className="inquiry">
        <IonCol>
          <div className="progress_box">
            <div className="add_box">
              <IonImg className="col_span" src="/assets/product/roasted/roasted_meat1.jpg" alt="부채살 구이" />
              <IonText>
                <div className="price_box col_span">
                  <div>
                    <span>200g</span>
                    <span>냉장</span>
                    <span>스테이크</span>
                  </div>
                  <div>
                    <strong>안심 스테이크</strong>
                  </div>
                </div>
              </IonText>
            </div>
            <span>
              토요일 캠핑갈 때 가져 가려고 하는데
              <br /> 지금 주문하면 받을 수 있을까요?
            </span>
            <div className="state_box">
              <span>답변 대기</span>
              <span>2021-08-25</span>
            </div>
          </div>
        </IonCol>
      </IonRow>
    </>
  );
};

export default InquiryDetailCard;
