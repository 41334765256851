export interface Agreements {
  agreements: AgreementsDetail;
}

export interface AgreementsDetail {
  agreeId: number; // 아이디
  agreeDate: string; // 약관 동의 시간
  agreeChecked: boolean; // 약관 체크
  agreeType: string; // 약관 타입
}

export interface AgreementContent {
  agreementType: string; // AgreementType?
  agreeTitle: string;
  agreeContents: string;
  // 생략
}

export enum AgreementType {
  TERMS_OF_SERVICE = 'TERMS_OF_SERVICE', // 이용약관
  PRIVACY_POLICY = 'PRIVACY_POLICY', // 개인정보 처리방침
  AGE_AGREEMENT = 'AGE_AGREEMENT', // 만 14세 이상
  // ORDER_TERMS_OF_SERVICE = 'ORDER_TERMS_OF_SERVICE', // 이용약관
  ORDER_PROVIDE_PRIVACY = 'ORDER_PROVICE_PRIVACY', // 제3자 개인정보 제공 동의
}
