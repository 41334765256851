import React, { useState } from 'react';
import { IonButton, IonCol, IonContent, IonGrid, IonInput, IonPage, IonRow, useIonAlert } from '@ionic/react';
import { connect } from '../data/connect';

import { RouteComponentProps } from 'react-router';
import DefaultHeader from '../components/header/DefaultHeader';
import DefaultFooter from '../components/footer/DefaultFooter';
import './MyInfoPage.scss';
import * as selectors from '../data/selectors';
import { myPagePwChk } from '../data/dataApi';
import routes from '../routes';

interface OwnProps extends RouteComponentProps {}

interface StateProps {
  user: any;
}

interface DispatchProps {}

interface MyInfoProps extends OwnProps, StateProps, DispatchProps {}

const MyInfoPage: React.FC<MyInfoProps> = ({ user, history }) => {
  const [passwordInput, setPasswordInput] = useState('');
  const [present] = useIonAlert();

  const login = async (e: React.FormEvent) => {
    e.preventDefault();
    if (passwordInput) {
      const login = await myPagePwChk(user.userData.accessToken, passwordInput);
      if (login.isLoggedIn) {
        setPasswordInput('');
        history.push(routes.MYINFO_DETAIL);
      } else {
        present({
          header: '모던한담',
          message: '비밀번호가 틀립니다.',
          buttons: [
            {
              text: '확인',
              role: 'confirm',
              cssClass: 'secondary',
            },
          ],
        });
      }
    }
  };
  return (
    <IonPage id="myInfo">
      <IonContent>
        <DefaultHeader title="내정보관리" showBackButton={true} />
        <IonGrid>
          <form noValidate onSubmit={login}>
            <IonRow className="id">
              <IonCol>
                <p>{user.userData.userId}</p>
              </IonCol>
            </IonRow>

            <IonRow className="pw">
              <IonCol>
                <IonInput
                  name="password"
                  type="password"
                  value={passwordInput}
                  title="비밀번호를 입력해 주세요."
                  placeholder="비밀번호를 입력해 주세요."
                  onIonChange={(e) => setPasswordInput(e.detail.value!)}
                />
              </IonCol>
            </IonRow>

            <IonRow className="btn_box">
              <IonCol>
                <IonButton expand="full" type="submit">
                  확인
                </IonButton>
              </IonCol>
            </IonRow>
          </form>
        </IonGrid>
        <DefaultFooter />
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: selectors.getUser(state),
  }),
  component: React.memo(MyInfoPage),
});
