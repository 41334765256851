import React, { useState } from 'react';
import { IonItem, IonLabel, IonAccordion, useIonAlert } from '@ionic/react';
import './NoticeCard.scss';
import { Notice } from '../../models/Notice';
import { noticeCount } from '../../data/dataApi';

interface NoticeCardProps {
  notice: Notice;
}

const NoticeCard: React.FC<NoticeCardProps> = ({ notice }) => {
  const [present] = useIonAlert(); // alert 폼
  const [chkNumber, setChkNumber] = useState<number>(0);

  //TODO chkNumber 리스트 클릭 시 다른 리스트 아이디랑 비교할때 값이 변환 안되는 부분 확인 필요함.

  const cnt = async (key: number) => {
    if (key == chkNumber) {
      setChkNumber(0);
      return;
    }

    const data = await noticeCount(key);
    if (!data.result) {
      present({
        header: '모던한담',
        message: '다시 시도해 주세요.',
        buttons: [
          {
            text: '확인',
            role: 'confirm',
            cssClass: 'secondary',
            handler: async () => {},
          },
        ],
      });
    }
    setChkNumber(key);
  };

  return (
    <IonAccordion>
      <IonItem slot="header" onClick={() => cnt(notice.noticeId)}>
        <IonLabel>{notice.noticeTitle}</IonLabel>
        <IonLabel position="stacked" className="subHeader">
          {notice.createdAt.substring(0, 10)}
        </IonLabel>
      </IonItem>
      <IonItem slot="content" className="content" lines="none">
        <p dangerouslySetInnerHTML={{ __html: notice.noticeContents }} />
      </IonItem>
    </IonAccordion>
  );
};

export default NoticeCard;
