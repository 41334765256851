import React from 'react';
import { IonCol, IonImg, IonRow, IonText } from '@ionic/react';

import './ProductDetailInquiry.scss';

interface ProductDetailInquiryProps {
  Card: {};
}

const ProductDetailInquiryPage: React.FC<ProductDetailInquiryProps> = () => {
  return (
    <>
      <IonRow>
        <IonCol className="add_box">
          <IonImg className="col_span" src="/assets/product/roasted/roasted_meat1.jpg" alt="부채살 구이" />
          <IonText>
            <div className="price_box col_span">
              <div>
                <span>200g</span>
                <span>냉장</span>
                <span>스테이크</span>
              </div>
              <div>
                <strong>안심 스테이크</strong>
              </div>
            </div>
          </IonText>
        </IonCol>
      </IonRow>
    </>
  );
};

export default ProductDetailInquiryPage;
