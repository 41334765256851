import {
  getSocialSignUpData,
  setSocialEmailData,
  setSocialIdData,
  setSocialPhoneNumberData,
  setSocialStatusData,
  setSocialTypeData,
  setSocialUserNameData,
} from '../dataApi';
import { ActionType } from '../../util/types';
import { SocialState } from './social.state';

export const loadSocialData = () => async (dispatch: React.Dispatch<any>) => {
  const data = await getSocialSignUpData();
  if (data.status == 'signup') {
    dispatch(setSocialData(Object(data)));
  }
};

export const setSocialData = (socialData: Partial<SocialState>) =>
  ({
    type: 'set-social-data',
    socialData,
  } as const);

export const setSocialStatus = (socialStatus: string) => async () => {
  await setSocialStatusData(socialStatus);
  return {
    type: 'set-social-status',
    socialStatus,
  } as const;
};

export const setSocialType = (socialType: string) => async () => {
  await setSocialTypeData(socialType);
  return {
    type: 'set-social-type',
    socialType,
  } as const;
};

export const setSocialId = (socialId: string) => async () => {
  await setSocialIdData(socialId);
  return {
    type: 'set-social-userid',
    socialId,
  } as const;
};

export const setSocialUserName = (socialUserName: string) => async () => {
  await setSocialUserNameData(socialUserName);
  return {
    type: 'set-social-username',
    socialUserName,
  } as const;
};

export const setSocialPhoneNumber = (socialPhone: string) => async () => {
  await setSocialPhoneNumberData(socialPhone);
  return {
    type: 'set-social-phone',
    socialPhone,
  } as const;
};

export const setSocialEmail = (socialEmail: string) => async () => {
  await setSocialEmailData(socialEmail);
  return {
    type: 'set-social-email',
    socialEmail,
  } as const;
};

export type SocialActions =
  | ActionType<typeof setSocialData>
  | ActionType<typeof setSocialStatus>
  | ActionType<typeof setSocialType>
  | ActionType<typeof setSocialId>
  | ActionType<typeof setSocialUserName>
  | ActionType<typeof setSocialPhoneNumber>
  | ActionType<typeof setSocialEmail>;
