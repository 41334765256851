/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { IonButton, IonCol, IonContent, IonGrid, IonInput, IonPage, IonRow, IonText, useIonAlert } from '@ionic/react';
import { connect } from '../data/connect';

import { RouteComponentProps } from 'react-router';
import DefaultHeader from '../components/header/DefaultHeader';
import DefaultFooter from '../components/footer/DefaultFooter';
import './MyInfoDetailPage.scss';
import * as selectors from '../data/selectors';
import {
  deleteUser,
  getDetailUser,
  phoneCertify,
  putUpdateUser,
  setAccessToken,
  setIsLoggedInData,
  setSaveId,
  setSaveIdBox,
  setUserId,
  setUserNameData,
  verification,
} from '../data/dataApi';
import routes from '../routes';
import { UserDetail } from '../models/User';
import { useForm } from 'react-hook-form';
import DaumPostcode from 'react-daum-postcode';
import { setIsLoggedIn, setUserName } from '../data/user/user.actions';

interface OwnProps extends RouteComponentProps {}

interface StateProps {
  user: any;
}

interface DispatchProps {
  setIsLoggedIn: typeof setIsLoggedIn;
  setUserName: typeof setUserName;
}

interface MyInfoDetailProps extends OwnProps, StateProps, DispatchProps {}

const MyInfoDetailPage: React.FC<MyInfoDetailProps> = ({ user, history, setIsLoggedIn, setUserName, match }) => {
  const [present] = useIonAlert(); // alert 폼
  const [presentDetail] = useIonAlert(); // alert 폼
  const [userD, setUserD] = useState<UserDetail>(); // 회원 상세
  const [oldPasswordInput, setOldPasswordInput] = useState(''); //현재 비밀번호
  const [passwordInput, setPasswordInput] = useState(''); // 새 비밀번호
  const [rePasswordInput, setRePasswordInput] = useState(''); // 새 비밀번호 확인

  const [chkOldPasswordInput, setChkOldPasswordInput] = useState(false); // 현재 비밀번호 체크
  const [chkPasswordInput, setChkPasswordInput] = useState(false); // 새 비밀번호 체크

  const [chkPasswordInputMessage, setChkPasswordInputMessage] = useState(''); // 새 비밀번호 메시지

  const [address, setAddress] = useState(''); // 주소
  const [addressDetail, setAddressDetail] = useState(''); // 상세주소
  const [phone, setPhone] = useState(''); // 휴대전화
  const [authentication, setAuthentication] = useState(0); // 휴대전화 인증번호
  const [zipcode, setZipcode] = useState(''); // 우편번호
  const [chkSms, setChkSms] = useState(true); // SMS 수신여부
  const [chkEmail, setChkEmail] = useState(true); // 이메일 수신여부

  const [isOpenPost, setIsOpenPost] = useState(false); //우편찾기

  const [chkCertSeq, setChkCertSeq] = useState(false); //휴대전화 인증번호 체크
  const [chkCode, setChkCode] = useState(false); //휴대전화 인증번호 확인 체크
  const [time, setTime] = useState(0); // 휴대전화 인증번호 시간

  const [chkPhone, setChkPhone] = useState(false); //휴대전화 변경시 체크
  const [chkSns, setChkSns] = useState(false); //SNS 체크

  const {
    // watch,
    // register,
    setValue,
    getValues,
    // setError,
    handleSubmit,
    formState: {},
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });

  const onChangeOpenPost = () => {
    setIsOpenPost(!isOpenPost);
  };

  //TODO : 우편찾기 클릭 시 document 못찾는 스크립트 에러 해결해야됨  > StrictMode (삭제 시 예외 가능)
  const handleComplete = (data: { address: any; addressType: string; bname: string; buildingName: string; zonecode: string }) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    setZipcode(data.zonecode);
    setAddress(fullAddress);
    setIsOpenPost(false);
  };

  const detailAutoHyphen = (destRecipientCellphoneNo: string) => {
    return destRecipientCellphoneNo.replace(/[^0-9]/g, '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
  };

  const autoHyphen = (phoneNumber: string) => {
    return phoneNumber
      .replace(/[^0-9]/g, '')
      .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3')
      .replace(/(\-{1,2})$/g, '');
  };

  const timeFormat = (time: number) => {
    const m = Math.floor(time / 60).toString();
    let s = (time % 60).toString();
    if (s.length === 1) {
      s = `0${s}`;
    }
    return `${m}:${s}`;
  };

  const handelClickRadioSms = (e: any) => {
    if (e.target.value === 'true') {
      setChkSms(true);
    } else {
      setChkSms(false);
    }
  };

  const handelClickRadioEmail = (e: any) => {
    if (e.target.value === 'true') {
      setChkEmail(true);
    } else {
      setChkEmail(false);
    }
  };

  const logOutWrap = async () => {
    await setAccessToken('');
    await setIsLoggedInData(false);
    await setUserNameData('');
    await setUserId('');
    await setSaveId('');
    await setSaveIdBox(false);
    user.userData.userId = '';
    user.userData.userName = '';
    user.userData.accessToken = '';
    user.userData.isLoggedIn = false;
    setIsLoggedIn(false);
    setUserName('');
  };

  const userDeleteAction = async () => {
    await present({
      header: '모던한담',
      message: '정말 탈퇴 하시겠습니까?',
      buttons: [
        {
          text: '취소',
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: '확인',
          handler: async () => {
            const deleteResult = await deleteUser(user.userData.accessToken);
            if (deleteResult.result) {
              await presentDetail({
                header: '모던한담',
                message: '회원탈퇴가 완료 되었습니다.',
                buttons: [
                  {
                    text: '확인',
                    cssClass: 'secondary',
                    handler: () => {
                      logOutWrap();
                      history.push(routes.MAIN);
                    },
                  },
                ],
              });
              return;
            }
          },
        },
      ],
    });
  };

  const userDetail = async () => {
    const detail = await getDetailUser(user.userData.accessToken);
    console.log(detail);
    if (detail.result) {
      setUserD(detail);

      if (detail.userZipcode != null) {
        setZipcode(detail.userZipcode);
      }

      if (detail.userAddress != null) {
        setAddress(detail.userAddress);
      }

      if (detail.userAddressDetail != null) {
        setAddressDetail(detail.userAddressDetail);
      }

      if (detail.userCellphoneNo != null) {
        setPhone(detailAutoHyphen(detail.userCellphoneNo));
      }

      if (detail.enableReceiveEmail != null) {
        setChkEmail(detail.enableReceiveEmail);
      }
      if (detail.enableReceiveMessage != null) {
        setChkSms(detail.enableReceiveMessage);
      }

      if (detail.usePassword) {
        setChkSns(true);
      } else {
        setChkSns(false);
      }
    } else {
      present({
        header: '모던한담',
        message: '다시 시도해 주세요.',
        buttons: [
          {
            text: '확인',
            role: 'confirm',
            cssClass: 'secondary',
            handler: () => {
              history.push(routes.MYINFO);
            },
          },
        ],
      });
    }
  };

  function handlePhoneCheck() {
    const regex = /^(?:(010-\d{4})|(01[1|6|7|8|9]-\d{3,4}))-(\d{4})$/;
    const userPhone = phone;

    if (!chkPhone) {
      present({
        header: '모던한담',
        message: '인증된 휴대전화를 변경 하시겠습니까?',
        buttons: [
          {
            text: '취소',
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: '확인',
            handler: async () => {
              setChkPhone(true);
            },
          },
        ],
      });
      return;
    } else if (!regex.test(userPhone)) {
      present({
        header: '모던한담',
        message: '010-0000-0000 휴대전화 형식으로 입력해주세요.',
        buttons: [
          {
            text: '확인',
            role: 'confirm',
            cssClass: 'secondary',
          },
        ],
      });
      return;
    } else if (!userPhone) {
      present({
        header: '모던한담',
        message: '휴대전화번호를 입력해주세요.',
        buttons: [
          {
            text: '확인',
            role: 'confirm',
            cssClass: 'secondary',
          },
        ],
      });
      return;
    }

    phoneCertify(userPhone).then((response) => {
      if (response.result) {
        setValue('certSeq', response.certSeq);
        setChkCertSeq(true);
        setTime(208);
      } else {
        present({
          header: '모던한담',
          message: '다시 시도해주세요.',
          buttons: [
            {
              text: '확인',
              role: 'confirm',
              cssClass: 'secondary',
            },
          ],
        });
        setChkCertSeq(false);
      }
    });
  }

  function verificationCode() {
    const code = authentication;
    if (!chkCertSeq) {
      present({
        header: '모던한담',
        message: '휴대전화 인증번호를 발급 받으세요.',
        buttons: [
          {
            text: '확인',
            role: 'confirm',
            cssClass: 'secondary',
          },
        ],
      });
      return;
    } else if (chkCertSeq && !code) {
      present({
        header: '모던한담',
        message: '인증번호를 입력하세요.',
        buttons: [
          {
            text: '확인',
            role: 'confirm',
            cssClass: 'secondary',
          },
        ],
      });
      return;
    }

    const checkedData = verification(getValues('certSeq'), code);
    checkedData.then((response) => {
      if (response.result) {
        present({
          header: '모던한담',
          message: '인증이 완료 되었습니다.',
          buttons: [
            {
              text: '확인',
              role: 'confirm',
              cssClass: 'secondary',
              handler: () => {
                setTime(0);
                setChkCode(true);
              },
            },
          ],
        });
      } else {
        // 이미 인증
        if (response.code == 'A0001') {
          present({
            header: '모던한담',
            message: response.message,
            buttons: [
              {
                text: '확인',
                role: 'confirm',
                cssClass: 'secondary',
              },
            ],
          });

          // 인증 시간 만료
        } else if (response.code == 'A0002') {
          present({
            header: '모던한담',
            message: response.message,
            buttons: [
              {
                text: '확인',
                role: 'confirm',
                cssClass: 'secondary',
              },
            ],
          });
          // 인증 값이 다름
        } else if (response.code == 'A0003') {
          present({
            header: '모던한담',
            message: response.message,
            buttons: [
              {
                text: '확인',
                role: 'confirm',
                cssClass: 'secondary',
              },
            ],
          });
        }
      }
    });
  }

  const onSubmit = async () => {
    const regex = /^(?=.*\W)(?=.*[a-zA-Z])(?=.*[\d]).{8,16}$/i;

    // 상세에서 비밀번호 확인 할떄 사용
    // if (oldPasswordInput != null && !oldPasswordInput) {
    //   setChkOldPasswordInput(true);
    //   return;
    // }

    if (passwordInput != null && passwordInput) {
      if (!regex.test(passwordInput)) {
        setChkPasswordInput(true);
        setChkPasswordInputMessage('8~16자리 영문, 숫자, 특수문자 조합하여 입력해주세요.');
        return;
      }

      if (rePasswordInput != null && !rePasswordInput) {
        setChkPasswordInput(true);
        setChkPasswordInputMessage('새 비밀번호를 입력해 주세요.');
        return;
      }

      if (passwordInput !== rePasswordInput) {
        setChkPasswordInput(true);
        setChkPasswordInputMessage('새 비밀번호와 확인 비밀번호가 일치하지 않습니다.');
        return;
      }

      setChkPasswordInput(false);
    } else if (rePasswordInput != null && rePasswordInput) {
      setChkPasswordInput(true);
      setChkPasswordInputMessage('새 비밀번호를 입력해 주세요.');
    }

    setChkOldPasswordInput(false);

    if (chkPhone) {
      if (!chkCertSeq) {
        present({
          header: '모던한담',
          message: '휴대전화 인증을 해주세요.',
          buttons: [
            {
              text: '확인',
              role: 'cancel',
              cssClass: 'secondary',
            },
          ],
        });
        return;
      } else if (!chkCode) {
        present({
          header: '모던한담',
          message: '인증번호 확인을 해주세요.',
          buttons: [
            {
              text: '확인',
              role: 'cancel',
              cssClass: 'secondary',
            },
          ],
        });
        return;
      }

      setValue('userCellphoneNo', phone.replace(/-/gi, ''));
    }

    setValue('enableReceiveMessage', chkSms);
    setValue('enableReceiveEmail', chkEmail);

    const updateUser = await putUpdateUser(user.userData.accessToken, getValues());
    if (updateUser.result) {
      present({
        header: '모던한담',
        message: '정보 수정이 완료 되었습니다.',
        buttons: [
          {
            text: '확인',
            role: 'confirm',
            cssClass: 'secondary',
            handler: () => {
              setOldPasswordInput('');
              setRePasswordInput('');
              setPasswordInput('');
              history.push(routes.MYPAGE);
            },
          },
        ],
      });
    } else {
      present({
        header: '모던한담',
        message: '현재 비밀번호를 확인 해주세요.',
        buttons: [
          {
            text: '확인',
            role: 'confirm',
            cssClass: 'secondary',
            handler: () => {},
          },
        ],
      });
    }
  };

  useEffect(() => {
    userDetail();
  }, [user]);

  useEffect(() => {
    setTime(0);
    setAuthentication(0);
    setChkCertSeq(false);
    setChkCode(false);
    setChkPhone(false);
    // setCurrentTime('');
  }, [match.params]);

  useEffect(() => {
    if (time > 0) {
      const counter = setInterval(() => {
        //TODO 핸드폰 인증시 인증 만료시간 필요함. 백엔드 값 추가해야됨.
        // const gap = Math.floor((new Date(currentTime.toString()).getTime() - new Date().getTime()) / 1000);
        setTime(time - 1);
      }, 1000);
      return () => clearInterval(counter);
    }
  }, [time]);

  return (
    <IonPage id="myInfoDetail">
      <IonContent>
        <DefaultHeader title="내정보관리" showBackButton={true} />
        <IonGrid>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <IonRow className="id">
              <IonCol>
                <p>{userD?.userEmail}</p>
              </IonCol>
            </IonRow>

            <IonRow className="input">
              <IonCol>
                <IonInput placeholder="이름" value={(setValue('userName', userD?.userName), userD?.userName)}></IonInput>
              </IonCol>
            </IonRow>

            {chkSns && (
              <>
                <IonRow className="input">
                  <IonCol>
                    <IonInput
                      type="password"
                      value={(setValue('oldPassword', oldPasswordInput), oldPasswordInput)}
                      onIonChange={(e) => setOldPasswordInput(e.detail.value!)}
                      placeholder="현재 비밀번호"
                    />
                  </IonCol>
                </IonRow>

                {chkOldPasswordInput && (
                  <IonRow>
                    <IonCol>
                      <IonText>
                        <p>현재 비밀번호를 입력해주세요.</p>
                      </IonText>
                    </IonCol>
                  </IonRow>
                )}

                <IonRow className="input">
                  <IonCol>
                    <IonInput
                      type="password"
                      value={(setValue('userPassword', passwordInput), passwordInput)}
                      onIonChange={(e) => setPasswordInput(e.detail.value!)}
                      placeholder="새 비밀번호 (8~16자리 영문, 숫자 조합)"
                    />
                  </IonCol>
                </IonRow>

                {chkPasswordInput && (
                  <IonRow>
                    <IonCol>
                      <IonText>
                        <p>{chkPasswordInputMessage}</p>
                      </IonText>
                    </IonCol>
                  </IonRow>
                )}

                <IonRow className="input">
                  <IonCol>
                    <IonInput
                      type="password"
                      value={(setValue('rePassword', rePasswordInput), rePasswordInput)}
                      onIonChange={(e) => setRePasswordInput(e.detail.value!)}
                      placeholder="새 비밀번호 확인"
                    />
                  </IonCol>
                </IonRow>
              </>
            )}

            <IonRow className="zipcode">
              <IonCol>
                <IonInput value={(setValue('userZipcode', zipcode), zipcode)} readonly></IonInput>
                <IonButton expand="full" type="button" onClick={() => onChangeOpenPost()}>
                  우편번호찾기
                </IonButton>
                {isOpenPost && (
                  <>
                    <div>
                      <DaumPostcode autoClose onComplete={handleComplete} />
                    </div>
                  </>
                )}
              </IonCol>
            </IonRow>

            <IonRow className="input">
              <IonCol>
                <IonInput value={(setValue('userAddress', address), address)} readonly></IonInput>
              </IonCol>
            </IonRow>

            <IonRow className="input">
              <IonCol>
                <IonInput
                  value={(setValue('userAddressDetail', addressDetail), addressDetail)}
                  onIonChange={(e) => setAddressDetail(e.detail.value!)}
                ></IonInput>
              </IonCol>
            </IonRow>

            <IonRow className="mobile">
              <IonCol>
                <IonInput
                  value={phone}
                  onIonChange={(e) => setPhone(autoHyphen(String(e.detail.value)))}
                  readonly={!chkPhone ? true : false}
                  maxlength={13}
                ></IonInput>
                <IonButton type="button" expand="full" disabled={chkCertSeq} onClick={handlePhoneCheck}>
                  {chkPhone && '인증'}
                  {!chkPhone && '변경'}
                </IonButton>
              </IonCol>
            </IonRow>

            <IonRow className="mobile">
              <IonCol>
                <IonInput
                  value={authentication > 0 ? authentication : ''}
                  onIonChange={(e) => setAuthentication(Number(e.detail.value))}
                  readonly={!chkCertSeq ? true : false}
                >
                  <>
                    {chkCertSeq && (
                      <div className="code_time">
                        <IonText>{timeFormat(time)}</IonText>
                      </div>
                    )}
                  </>
                </IonInput>
                <IonButton type="button" expand="full" onClick={verificationCode} disabled={chkCode}>
                  확인
                </IonButton>
              </IonCol>
            </IonRow>

            {chkCertSeq && !chkCode && (
              <IonRow className="btn_rt">
                <IonCol>
                  <IonText>
                    인증번호가 도착하지 않으셨나요? <br /> 인증번호가 도착하지 않으셨다면 '재전송' 버튼을 눌러주세요.
                  </IonText>
                  <IonButton type="button" expand="full" onClick={handlePhoneCheck}>
                    재전송
                  </IonButton>
                </IonCol>
              </IonRow>
            )}

            <IonRow className="text_box">
              <IonCol>
                {userD?.createdAt && (
                  <p>
                    {userD?.createdAt.substring(0, 4)}년 {userD?.createdAt.substring(5, 7)}월 {userD?.createdAt.substring(8, 10)}일{' '}
                    {userD?.createdAt.substring(11, 13)}시 {userD?.createdAt.substring(14, 16)}분 가입
                  </p>
                )}
              </IonCol>
            </IonRow>

            <IonRow className="radio_wrap">
              <IonCol>
                <div>
                  <label>SMS 수신</label>
                  <div className="radio_box">
                    <input type="radio" name="sms" id="sms_type1" value="true" checked={chkSms} onChange={handelClickRadioSms} />
                    <label htmlFor="sms_type1">동의</label>
                  </div>
                  <div className="radio_box">
                    <input type="radio" name="sms" id="sms_type2" value="false" checked={!chkSms} onChange={handelClickRadioSms} />
                    <label htmlFor="sms_type2">동의안함</label>
                  </div>
                </div>

                <div>
                  <label>이메일 수신</label>
                  <div className="radio_box">
                    <input type="radio" name="email" id="sms_type3" value="true" checked={chkEmail} onChange={handelClickRadioEmail} />
                    <label htmlFor="sms_type3">동의</label>
                  </div>

                  <div className="radio_box">
                    <input type="radio" name="email" id="sms_type4" value="false" checked={!chkEmail} onChange={handelClickRadioEmail} />
                    <label htmlFor="sms_type4">동의안함</label>
                  </div>
                </div>
              </IonCol>
            </IonRow>

            <IonRow className="modify_btn">
              <IonCol>
                <IonButton expand="full" type="submit">
                  정보수정
                </IonButton>
              </IonCol>
            </IonRow>

            <IonRow className="withdrawal_btn">
              <IonCol>
                <IonButton expand="full" className="withdrawal" onClick={() => userDeleteAction()}>
                  회원탈퇴
                </IonButton>
              </IonCol>
            </IonRow>
          </form>
        </IonGrid>
        <DefaultFooter />
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: selectors.getUser(state),
  }),
  mapDispatchToProps: { setIsLoggedIn, setUserName },
  component: React.memo(MyInfoDetailPage),
});
