import React from 'react';
import { IonButton, IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react';
import { connect } from '../data/connect';

import { RouteComponentProps } from 'react-router';
import DefaultHeader from '../components/header/DefaultHeader';
import DefaultFooter from '../components/footer/DefaultFooter';
import './NoticePage.scss';
import InquiryDetailCard from '../components/inquiry/InquiryDetailCard';
import routes from '../routes';
import './InquiryDetailPage.scss';

interface OwnProps extends RouteComponentProps {}

interface StateProps {}

interface DispatchProps {}

interface NoticeProps extends OwnProps, StateProps, DispatchProps {}

const NoticePage: React.FC<NoticeProps> = ({}) => {
  return (
    <IonPage id="inquiry_detail">
      <IonContent>
        <DefaultHeader title="문의하기" showBackButton={true} />
        <IonGrid>
          <InquiryDetailCard Card={''} />
          <IonRow>
            <IonCol>
              <IonButton type="button" expand="block" className="btn" routerLink={routes.INQUIRY}>
                문의하기
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
        <DefaultFooter />
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  component: NoticePage,
});
