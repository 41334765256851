/* eslint-disable @typescript-eslint/no-unused-vars,react-hooks/exhaustive-deps,@typescript-eslint/no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { IonCard, IonCardContent, IonImg, IonRow, IonCol, IonText, useIonAlert } from '@ionic/react';
import './MyCartCard.scss';
import { Cart } from '../../models/Cart';
import { cartChangeQty, cartRemove, cartTotal } from '../../data/dataApi';
import { CodeObject } from '../../data/code/code.state';
import { codeName } from '../../util/EnumUtils';
import { ProductStatusType } from '../../models/Product';

interface MyCartCardProps {
  cart: Cart;
  cartRemoveChk?: (cartRemoveChk: boolean) => void;
  handleSingleCheck: (checked: boolean, id: number) => void;
  checkItems: any;
  cartQty: (count: number, id: number) => void;
  productPrice: (price: number) => void;
  setCartTotal: Function;
  codes: CodeObject;
}

const MyCartCard: React.FC<MyCartCardProps> = ({
  cart,
  cartRemoveChk,
  handleSingleCheck,
  checkItems,
  cartQty,
  productPrice,
  setCartTotal,
  codes,
}) => {
  const [qty, setQty] = useState(0); // 장바구니 재고 체크
  const [cartCount, setCartCount] = useState(cart.cartQuantity); // 장바구니 카운트 (상품 갯수 설정)
  const [present] = useIonAlert(); // alert 폼
  const [ChkClick, setChkClick] = useState(false); // +,- 클릭여부

  const handleChangeQuantity = async (flag: 'm' | 'p') => {
    changeQty(flag);
    setChkClick(true);
  };

  const changeQty = async (value: string) => {
    //TODO 무료배송 상품일때는 배송비 추가가 없음
    if (value == 'm') {
      if (cartCount > 1) {
        setCartCount(cartCount - 1);
      }
    } else if (value == 'p') {
      setQty(cartCount);
      setCartCount(cartCount + 1);
    }
  };

  const changeCartQty = async () => {
    const resultData = await cartChangeQty(cart.productId, cartCount, String(cart.cartId));
    if (!resultData.result) {
      present({
        header: '모던한담',
        message: '다시 시도해 주세요.',
        buttons: [
          {
            text: '확인',
            role: 'cancel',
            cssClass: 'secondary',
          },
        ],
      });
      return;
    } else {
      cartQty(cartCount, cart.productId);
      productPrice(cart.productSalePrice);
    }
  };

  const removeCart = async () => {
    const items: any = [];
    let el = { productId: cart.productId, cartId: cart.cartId };
    items.push(el);

    const removeCart = await cartRemove(items);
    if (removeCart.result) {
      if (cartRemoveChk !== undefined) {
        cartRemoveChk(true);
        const getCartTotal = await cartTotal();
        if (getCartTotal.result) setCartTotal(getCartTotal.cartCount);
      }
    } else {
      present({
        header: '모던한담',
        message: '다시 시도해 주세요.',
        buttons: [
          {
            text: '확인',
            role: 'cancel',
            cssClass: 'secondary',
          },
        ],
      });
      return;
    }
  };

  function numberWithCommas(x: { toString: () => string }) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  useEffect(() => {
    if (cartCount > cart.productStockQuantity) {
      setCartCount(qty);
      present({
        header: '모던한담',
        message: "'" + cart.productName + "'" + ' 상품의 재고가 부족합니다. ' + cart.productStockQuantity + '개 이하로 구매해 주세요.',
        buttons: [
          {
            text: '확인',
            role: 'cancel',
            cssClass: 'secondary',
          },
        ],
      });
      return;
    } else {
      if (ChkClick) {
        changeCartQty();
        setChkClick(false);
      }
    }
  }, [cartCount]);

  useEffect(() => {
    setCartCount(cart.cartQuantity);
  }, [cart]);

  return (
    <>
      <IonCard>
        <IonCardContent className="cart_con">
          <IonRow>
            <IonCol>
              <div className="chk_box">
                <input
                  type="checkbox"
                  id={'product_' + cart.productId}
                  onChange={(e) => handleSingleCheck(e.target.checked, cart.productId)}
                  checked={checkItems.includes(cart.productId) ? true : false}
                  disabled={cart.productStatusType !== ProductStatusType.SALE}
                />
                <label htmlFor={'product_' + cart.productId} className="product_tit">
                  {' '}
                  {cart.productName}
                </label>
                <button type="button" className="remove" onClick={() => removeCart()}>
                  삭제
                </button>
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonImg src={cart.imageUriPath} alt={cart.productName} />
              <div className="price_box">
                {cart.productStatusType === ProductStatusType.SALE ? (
                  <>
                    <IonText>
                      <p className="price">
                        <em>{numberWithCommas(cart.productSalePrice)}</em>원
                      </p>
                    </IonText>
                    {cart.deliveryFeeType === 'FREE' && (
                      <IonText>
                        <p className="free">무료배송</p>
                      </IonText>
                    )}
                    {!cart.deliveryEnabledBundling && (
                      <IonText>
                        <p className="single">개별포장상품</p>
                      </IonText>
                    )}
                  </>
                ) : (
                  <>
                    <IonText>
                      <p className="out_of">{codeName(cart.productStatusType, codes.ProductStatusType)}</p>
                    </IonText>
                  </>
                )}
              </div>

              {cart.productStatusType === ProductStatusType.SALE && (
                <div className="orderCount">
                  <button
                    type="button"
                    className="btnMinus"
                    disabled={cart.productStatusType !== ProductStatusType.SALE}
                    onClick={() => handleChangeQuantity('m')}
                  >
                    수량빼기
                  </button>
                  {/*TODO :input 으로 개수 받을 예정*/}
                  <input
                    type="number"
                    className="number"
                    value={cartCount}
                    id="ct_qty"
                    disabled={cart.productStatusType !== ProductStatusType.SALE}
                    readOnly
                  />
                  <button
                    type="button"
                    className="btnPlus"
                    disabled={cart.productStatusType !== ProductStatusType.SALE}
                    onClick={() => handleChangeQuantity('p')}
                  >
                    수량더하기
                  </button>
                </div>
              )}
            </IonCol>
          </IonRow>
        </IonCardContent>
      </IonCard>
    </>
  );
};

export default MyCartCard;
