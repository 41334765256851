import React from 'react';
import { IonCard, IonCardContent, IonCol, IonImg, IonRow } from '@ionic/react';
import './RecipeCard.scss';

interface RecipeCardProps {
  Card: {};
}

const RecipeCard: React.FC<RecipeCardProps> = ({}) => {
  return (
    <>
      {/*TODO: 데이터 나오면 IonCard (리스트 사용)*/}
      <IonCard routerLink={''}>
        <IonImg src="/assets/product/roasted/roasted_meat1.jpg" alt="부채살 구이" />
        <IonCardContent>
          <IonRow>
            <IonCol>
              <strong className="card_tit">밀푀유나베</strong>
            </IonCol>
          </IonRow>
        </IonCardContent>
      </IonCard>

      <IonCard routerLink={''}>
        <IonImg src="/assets/product/roasted/roasted_meat1.jpg" alt="부채살 구이" />
        <IonCardContent>
          <IonRow>
            <IonCol>
              <strong className="card_tit">밀푀유나베</strong>
            </IonCol>
          </IonRow>
        </IonCardContent>
      </IonCard>

      <IonCard routerLink={''}>
        <IonImg src="/assets/product/roasted/roasted_meat1.jpg" alt="부채살 구이" />
        <IonCardContent>
          <IonRow>
            <IonCol>
              <strong className="card_tit">밀푀유나베</strong>
            </IonCol>
          </IonRow>
        </IonCardContent>
      </IonCard>

      <IonCard routerLink={''}>
        <IonImg src="/assets/product/roasted/roasted_meat1.jpg" alt="부채살 구이" />
        <IonCardContent>
          <IonRow>
            <IonCol>
              <strong className="card_tit">밀푀유나베</strong>
            </IonCol>
          </IonRow>
        </IonCardContent>
      </IonCard>

      <div className="open">
        <button type="button">더보기</button>
      </div>
    </>
  );
};

export default RecipeCard;
