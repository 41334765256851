import { SocialState } from './social.state';
import { SocialActions } from './social.actions';

export function socialReducer(state: SocialState, action: SocialActions): SocialState {
  switch (action.type) {
    case 'set-social-data':
      return { ...state, ...action.socialData };
    case 'set-social-status':
      return { ...state, status: action.socialStatus };
    case 'set-social-type':
      return { ...state, type: action.socialType };
    case 'set-social-userid':
      return { ...state, id: action.socialId };
    case 'set-social-username':
      return { ...state, userName: action.socialUserName };
    case 'set-social-phone':
      return { ...state, phone: action.socialPhone };
    case 'set-social-email':
      return { ...state, email: action.socialEmail };
    default:
      return state;
  }
}
