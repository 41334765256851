/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { IonButton, IonCol, IonRow, IonText, useIonAlert } from '@ionic/react';
import './DeliveryCard.scss';
import { Delivery } from '../../models/Delivery';
import { deliveryDefault, deliveryRemove } from '../../data/dataApi';

interface DeliveryCardProps {
  delivery: Delivery;
  deliveryListLength: number;
  accessToken: string;
  setDetailChk: (detailChk: boolean) => void;
  deliveryChk: boolean;
  deliveryData: any;
  setDeliveryData: Function;
}

const DeliveryCard: React.FC<DeliveryCardProps> = ({
  delivery,
  deliveryListLength,
  accessToken,
  setDetailChk,
  deliveryChk,
  deliveryData,
  setDeliveryData,
}) => {
  const [present] = useIonAlert(); // alert 폼
  const [presentDetail] = useIonAlert(); // alert 폼
  const autoHyphen = (destRecipientCellphoneNo: string) => {
    return destRecipientCellphoneNo.replace(/[^0-9]/g, '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
  };

  const remove = async () => {
    if (deliveryListLength === 1) {
      await present({
        header: '모던한담',
        message: '배송지는 1개 이상 필요합니다.',
        buttons: [
          {
            text: '확인',
            handler: () => {
              return;
            },
          },
        ],
      });
      return;
    }

    await present({
      header: '모던한담',
      message: '배송지를 삭제 하시겠습니까?',
      buttons: [
        {
          text: '취소',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            return;
          },
        },
        {
          text: '확인',
          handler: async () => {
            const result = await deliveryRemove(accessToken, delivery.destId);
            if (result.result) {
              await presentDetail({
                header: '모던한담',
                message: '배송지가 삭제 되었습니다.',
                buttons: [
                  {
                    text: '확인',
                    cssClass: 'secondary',
                    handler: () => {
                      setDetailChk(true);
                    },
                  },
                ],
              });
              return;
            }
          },
        },
      ],
    });
    return;
  };

  const deliveryDefaultChk = async () => {
    const result = await deliveryDefault(accessToken, delivery.destId);
    if (result.result) {
      present({
        header: '모던한담',
        message: '기본 배송지가 변경 되었습니다.',
        buttons: [
          {
            text: '확인',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              setDetailChk(true);
            },
          },
        ],
      });
      return;
    }
  };

  function radioChangeChk() {
    setDeliveryData(delivery);
    history.back();
  }

  return (
    <IonRow>
      <IonCol>
        <div className="delivery_box ">
          {!deliveryChk && (
            <input
              type="radio"
              name="adr"
              id="adr_type1"
              value={delivery.destId}
              checked={delivery.destId == deliveryData.destId ? true : false}
              onChange={radioChangeChk}
            />
          )}
          <label htmlFor="adr_type1" className="pay_tit">
            {delivery.destName}
          </label>

          {delivery.destDefault && <span className="txt">기본배송지</span>}
          {!delivery.destDefault && (
            <span className="txt type2" onClick={() => deliveryDefaultChk()}>
              기본배송지로 선택
            </span>
          )}

          <IonText>
            <span className="d_name">{delivery.destRecipientName}</span>
            <span className="d_phone">{autoHyphen(delivery.destRecipientCellphoneNo)}</span>
            <address>{delivery.destAddress}</address>
            <address>{delivery.destAddressDetail}</address>

            <div className="btn_box">
              <IonButton routerLink={`/delivery/${delivery.destId}`}>수정</IonButton>
              <IonButton onClick={() => remove()}>삭제</IonButton>
            </div>
          </IonText>
        </div>
      </IonCol>
    </IonRow>
  );
};

export default DeliveryCard;
