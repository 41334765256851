import React from 'react';
import { IonCol, IonContent, IonGrid, IonImg, IonPage, IonRow } from '@ionic/react';
import { connect } from '../data/connect';

import { RouteComponentProps } from 'react-router';
import Menu from '../components/menu/Menu';
import DefaultHeader from '../components/header/DefaultHeader';
import DefaultFooter from '../components/footer/DefaultFooter';
import './DeliveryInfoPage.scss';

interface OwnProps extends RouteComponentProps {}

interface StateProps {}

interface DispatchProps {}

interface DeliveryInfoProps extends OwnProps, StateProps, DispatchProps {}

const DeliveryInfoPage: React.FC<DeliveryInfoProps> = ({}) => {
  return (
    <IonPage id="delivery_info">
      <IonContent>
        <DefaultHeader title="배송안내" showBackButton={true} />
        <IonGrid>
          {/*메인 Tab(대분류) menu*/}
          <Menu />
          <IonRow className="loadImg">
            <IonCol className="deliveryService">
              <IonImg src="/assets/delivery/img_delivery.png" alt="배송안내" />
              <p className="strong">주 5일 택배배송</p>
              <p>오전 11시까지 주문하면 당일 발송!</p>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <p className="textBox">
                오전 11시까지 주문하시면 당일 발송되어요.
                <br />
                금, 토, 일요일 주문은 차주 월요일에 발송해 드립니다.
                <br />
                주문금액이 10만원 이상인 경우 무료배송,
                <br />그 외 배송료는 3,500원입니다.
                <br />
                도서, 산간 일부 지역은 배송비가 추가되어요.
                <br />
                <br />
                평균 1~2일 이내로 수령 가능하며,
                <br />
                기상악화 및 상황에 따라 지연 배송이 발생될 수 있습니다.
              </p>
            </IonCol>
          </IonRow>
        </IonGrid>
        <DefaultFooter />
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  component: DeliveryInfoPage,
});
