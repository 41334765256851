import React, { useEffect, useState } from 'react';
import { IonImg, IonRow, IonCol, IonRouterLink } from '@ionic/react';
import './Menu.scss';
import routes from '../../routes';
import { connect } from '../../data/connect';
import * as selectors from '../../data/selectors';

interface MenuProps {
  category: any;
}
const Menu: React.FC<MenuProps> = ({ category }) => {
  const [url, setUrl] = useState('');

  useEffect(() => {
    setUrl(`${routes.PRODUCT}` + '?categoryId=' + category.categoryId);
  }, [category]);
  return (
    <>
      <IonRow className="tabRow">
        <IonCol>
          <IonRouterLink routerLink={url} color="dark">
            상품전체
          </IonRouterLink>
        </IonCol>

        <IonCol>
          <IonRouterLink routerLink={`${routes.GIFT}`} color="dark">
            선물세트
          </IonRouterLink>
        </IonCol>

        <IonCol>
          <IonRouterLink routerLink={`${routes.DELIVERY_INFO}`} color="dark">
            배송안내
          </IonRouterLink>
        </IonCol>

        <IonCol>
          <IonRouterLink routerLink={`${routes.RECIPE}`} color="dark">
            모담레시피
          </IonRouterLink>
        </IonCol>
        <IonCol>
          <IonRouterLink className="searchIcon" routerLink={`${routes.SEARCH}`}>
            <IonImg src={'/assets/icon/ico_search.png'} />
          </IonRouterLink>
        </IonCol>
      </IonRow>
    </>
  );
};

export default connect({
  mapStateToProps: (state) => ({
    category: selectors.getCategory(state),
  }),
  component: Menu,
});
