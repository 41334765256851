import { getCartData, setCartCountData } from '../dataApi';
import { ActionType } from '../../util/types';
import { CartState } from './cart.state';

export const loadCartData = () => async (dispatch: React.Dispatch<any>) => {
  const data = await getCartData();
  dispatch(setCartData(Object(data)));
};

export const setCartData = (cartData: Partial<CartState>) =>
  ({
    type: 'set-cart-data',
    cartData,
  } as const);

export const setCartTotal = (cartCount: string) => async () => {
  await setCartCountData(cartCount);
  return {
    type: 'set-cart-count',
    cartCount,
  } as const;
};

export type CartActions = ActionType<typeof setCartData> | ActionType<typeof setCartTotal>;
