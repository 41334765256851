import { RouteComponentProps } from 'react-router';
import { IamportPayMethod, IamportResultRedirectParams, PaymentVerificationResult } from '../models/Pay';

import queryString from 'query-string';
import { connect } from '../data/connect';
import { cartTotal, sendPaymentErrorLog, verifyPayment } from '../data/dataApi';
import { useEffect, useState } from 'react';
import { useIonAlert } from '@ionic/react';
import routes from '../routes';
import { setCartTotal } from '../data/cart/cart.actions';

interface OwnProps extends RouteComponentProps {}

interface StateProps {}

interface DispatchProps {
  setCartTotal: typeof setCartTotal;
}

interface PayProps extends OwnProps, StateProps, DispatchProps {}

const PayComplete: React.FC<PayProps> = ({ history, setCartTotal }) => {
  const [present] = useIonAlert(); // alert 폼

  const [params, setParams] = useState<IamportResultRedirectParams>();
  const [payResult, setPayResult] = useState<PaymentVerificationResult>();

  // FIXME: 아임포트 결과값을 받고 백엔드에 검증 요청
  // 검증 결과에 따라 보여줘야 함

  const fetchResult = async (params: IamportResultRedirectParams) => {
    const { result, ...data } = await verifyPayment(params);
    if (result) {
      setPayResult(data as PaymentVerificationResult);
      return data as PaymentVerificationResult;
    } else {
      return Promise.reject();
    }
  };

  const cartTotalCount = async () => {
    const getCartTotal = await cartTotal();
    if (getCartTotal.result) setCartTotal(getCartTotal.cartCount);
  };

  useEffect(() => {
    setParams(queryString.parse(window.location.search) as unknown as IamportResultRedirectParams);
  }, []);

  useEffect(() => {
    if (!params) {
      return;
    }
    if (!params.error_msg) {
      fetchResult(params)
        .then((data) => {
          cartTotalCount(); // 장바구니 삭제 된 개수 반영
          let message = '주문이 완료되었습니다.';
          if (data?.payMethod === IamportPayMethod.VIRTUAL_ACCOUNT) {
            message = '가상계좌가 발급되었습니다. 주문 내역에서 가상계좌번호를 확인 후 기한 내 입금하셔야 주문이 최종 완료됩니다.';
          }
          present({
            header: '모던한담',
            message,
            buttons: [
              {
                text: '확인',
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => {
                  history.push(routes.MAIN);
                },
              },
            ],
          });
        })
        .catch(() => {
          present({
            header: '모던한담',
            message: '에러가 발생했습니다. 아래 결제번호를 첨부하여 문의 부탁드려요. \n' + params.imp_uid,
            buttons: [
              {
                text: '확인',
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => {
                  history.push(routes.MAIN);
                },
              },
            ],
          });
        });
    } else {
      const message = !!params.error_msg ? params.error_msg : '에러가 발생되었습니다. 다시 시도해주시기 바랍니다.';
      sendPaymentErrorLog({ ...params, error_msg: message }).then(() => {
        present({
          header: '모던한담',
          message,
          buttons: [
            {
              text: '확인',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                history.push(routes.MAIN);
              },
            },
          ],
        });
      });
    }
  }, [params]);

  return <></>;
};

export default connect<OwnProps, StateProps, DispatchProps>({
  component: PayComplete,
  mapDispatchToProps: { setCartTotal },
});
