import React from 'react';
import { IonButton, IonCol, IonRow } from '@ionic/react';
import './ProductDetailInfo.scss';
import { Customer, Information } from '../../models/ProductDetail';

interface ProductDetailInfoProps {
  information: Information | undefined;
  customer: Customer | undefined;
}

const ProductDetailInfoPage: React.FC<ProductDetailInfoProps> = ({ information, customer }) => {
  return (
    <>
      <IonCol className="con_type2">
        <ul>
          <li>
            <span>품목 또는 명칭</span>
            <em>{information?.modelName}</em>
          </li>

          <li>
            <span>중량</span>
            <em>{information?.weight}</em>
          </li>

          <li>
            <span>판매 수량</span>
            <em>{information?.quantityPerPackage}</em>
          </li>

          <li>
            <span>크기</span>
            <em>{information?.size}</em>
          </li>

          {/*TODO 카테고리 논의*/}

          <li>
            <span>생산자</span>
            <em>{information?.manufacturerName}</em>
          </li>

          <li>
            <span>원산지</span>
            <em>
              {information?.originType == 'DOMESTIC' ? '국내산' : information?.originType == 'FOREIGN' ? '수입산' : information?.originMemo}
            </em>
          </li>

          <li>
            <span>제조연월일</span>
            <em>{information?.productionDate}</em>
          </li>

          <li>
            <span>유통기한</span>
            <em>{information?.expiryDate}</em>
          </li>

          <li>
            <span>축산법에 따른 등급 표시</span>
            <em>{information?.legalGrade}</em>
          </li>

          <li>
            <span>축산물 이력정보</span>
            <em>{information?.legalHistoryInfo}</em>
          </li>

          <li>
            <span>상품 구성</span>
            <em>{information?.legalProductComposition}</em>
          </li>

          <li>
            <span>보관/취급방법</span>
            <em>{information?.storageMethod}</em>
          </li>

          <li>
            <span>식품등의 표시·광고에 관한 법률에 따른 소비자 안전을 위한 주의사항</span>
            <em>{information?.cautionInfo}</em>
          </li>

          <li>
            <span>고객센터 번호</span>
            <em>{customer?.customerContact}</em>
          </li>
        </ul>

        {customer?.changeInformation && (
          <>
            <IonCol className="toastui-editor-contents" dangerouslySetInnerHTML={{ __html: customer.changeInformation }}></IonCol>
          </>
        )}

        <dl>
          <dt>교환 및 환불 안내</dt>

          <dd>
            <p>재판매가 불가한 신선제품의 특성상 단순 변심, 주문 착오로 인한 교환과 환불은 어려운 점 양해 부탁드립니다.</p>

            <p>
              상품을 받은 날로부터 2일 이내에 상품 상태를 확인할 수 있는 사진과 함께 1:1 문의를 남겨주세요. 제품에 문제가 있는 것으로
              확인되면 배송비는 모던한담에서 부담하며 신속하게 교환, 환불을 도와드립니다.
            </p>

            <p>
              사전 접수 단계를 거치지 않고 임의 반품한 제품은 환송 또는 폐기될 수 있으며, 이로 인한 환불 처리는 불가한 점 양해 부탁드립니다.
            </p>
          </dd>
        </dl>

        <dl>
          <dt>배송 정보</dt>

          <dd>
            <p>
              직접 배송 : 직접 배송 상품의 경우 모던한담에서 고객님께서 주문하신 배송지까지 직접 배송을 드립니다. 오전 11시까지 주문하시면
              당일 수령이 가능합니다.
            </p>

            <p>오늘 출발 : 당일 오전 11시 이전까지 주문하실 경우 당일 오후 택배가 발송 됩니다.</p>

            <p>
              일반 택배 : 일반 택배 상품의 경우 주문 후 업무일 기준 당일 혹은 익일 발송 됩니다. (단, 재고 수량에 따라 달라질 수 있습니다.)
            </p>
          </dd>
        </dl>

        <dl>
          <dt>고객센터</dt>

          <dd>
            <p>
              서비스 이용에 불편한 점이나 궁금한 점이 있으신가요?
              <br /> 1:1 문의 또는 유선(02-3486-8500)을 통해 도와 드리겠습니다.
            </p>

            <IonButton type="button" expand="block" mode="ios" className="btn_info">
              <div>1:1문의 남기기</div>
            </IonButton>
          </dd>
        </dl>
      </IonCol>
    </>
  );
};

export default ProductDetailInfoPage;
