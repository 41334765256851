import React from 'react';
import { IonLabel, IonSegmentButton } from '@ionic/react';
import './SubMenu.scss';
import { Category } from '../../models/Category';

interface SubCategoryProps {
  category: Category;
}

const SubCategory: React.FC<SubCategoryProps> = ({ category }) => {
  return (
    <IonSegmentButton value={String(category.categoryId)}>
      <IonLabel>{category.categoryName}</IonLabel>
    </IonSegmentButton>
  );
};

export default SubCategory;
