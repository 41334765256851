/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { IonContent, IonGrid, IonLoading, IonPage } from '@ionic/react';
import { connect } from '../data/connect';

import { RouteComponentProps } from 'react-router';
import DefaultHeader from '../components/header/DefaultHeader';
import DefaultFooter from '../components/footer/DefaultFooter';
import './TermsOfServicePage.scss';
import { agreementB } from '../data/dataApi';
import { AgreementContent, AgreementType } from '../models/Agreements';
import routes from '../routes';

interface OwnProps extends RouteComponentProps {}

interface StateProps {}

interface DispatchProps {}

interface NoticeProps extends OwnProps, StateProps, DispatchProps {}

const TermsOfServicePage: React.FC<NoticeProps> = ({ match }) => {
  const [termsData, setTermsData] = useState<AgreementContent>({
    agreeContents: '',
    agreementType: '',
    agreeTitle: '',
  });
  const [loading, setLoading] = useState(false);

  const loadData = async (type: AgreementType) => {
    setLoading(true);
    const response = await agreementB(type);
    setLoading(false);
    if (response.result) {
      setTermsData(response.agreement!);
    } else {
      setTermsData({
        agreeTitle: '모던한담',
        agreeContents: '약관 로드에 실패했습니다.',
        agreementType: '',
      });
    }
  };

  useEffect(() => {
    let type: AgreementType | undefined;
    switch (match.path) {
      case routes.TERMS_OF_SERVICE:
        type = AgreementType.TERMS_OF_SERVICE;
        break;
      case routes.PRIVACY_POLICY:
        type = AgreementType.PRIVACY_POLICY;
        break;
    }
    if (!!type) {
      loadData(type);
    }
  }, []);

  return (
    <IonPage id="terms_of_service">
      <IonContent>
        <DefaultHeader title={termsData.agreeTitle} showBackButton={true} />
        <IonLoading isOpen={loading} message={'불러오는 중...'} />
        <IonGrid>
          <div className="content-wrapper toastui-editor-contents" dangerouslySetInnerHTML={{ __html: termsData?.agreeContents }}></div>
        </IonGrid>
        <DefaultFooter />
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  component: React.memo(TermsOfServicePage),
});
