import { IonBackButton, IonButton, IonButtons, IonHeader, IonImg, IonTitle, IonToolbar, useIonRouter } from '@ionic/react';
import React from 'react';
import { connect } from '../../data/connect';
import './DefaultHeader.scss';
import routes from '../../routes';
import * as selectors from '../../data/selectors';
import { setCartCountData } from '../../data/dataApi';

interface OwnProps {
  title?: string;
  showBackButton?: boolean;
  guBun?: string;
}

interface StateProps {
  user: any;
  cart: any;
}

interface DispatchProps {
  setCartCountData: typeof setCartCountData;
}

interface DefaultHeaderProps extends OwnProps, StateProps, DispatchProps {}

const DefaultHeader: React.FC<DefaultHeaderProps> = ({ title, showBackButton, user, cart }) => {
  const router = useIonRouter();
  let canShowBackButton = showBackButton && router.canGoBack();

  //새로고침 시 back 버튼
  if (!canShowBackButton && showBackButton) {
    canShowBackButton = true;
  }

  return (
    <IonHeader id="defaultHeader">
      <IonToolbar class="ion-no-padding">
        <IonButtons slot="start">{canShowBackButton && <IonBackButton defaultHref={routes.MAIN} />}</IonButtons>
        <IonTitle>
          {!title && (
            <div className="login-logo">
              <img src="/assets/logo/logo.png" alt="모던한담" />
            </div>
          )}
          {title && <span>{title}</span>}
        </IonTitle>

        <IonButtons className="btn_wrap" slot="end">
          {/* FIXME 카드 및 로그인 페이지로 이동되지 않는 문제 발견되어 일시적으로 href로 처리 */}
          {router.routeInfo.pathname !== '/' && (
            <IonButton href={routes.MAIN}>
              {/* FIXME 아이콘 */}
              <IonImg src="/assets/icon/favicon/modam_favicon.png" alt="메인페이지" />
            </IonButton>
          )}
          {/* FIXME 카드 및 로그인 페이지로 이동되지 않는 문제 발견되어 일시적으로 href로 처리 */}
          <IonButton href={user.userData.isLoggedIn === true ? routes.MYPAGE : routes.LOGIN}>
            <IonImg src="/assets/icon/mypage.png" alt="로그인 마이페이지" />
          </IonButton>

          {/* FIXME 카드 및 로그인 페이지로 이동되지 않는 문제 발견되어 일시적으로 href로 처리 */}
          <IonButton className="cart" href={routes.CART}>
            <IonImg src="/assets/icon/mycart.png" alt="장바구니" />
            <IonButton>{cart.cartCount || 0}</IonButton>
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: selectors.getUser(state),
    cart: selectors.getCart(state),
  }),
  mapDispatchToProps: { setCartCountData },
  component: DefaultHeader,
});
