import { CartState } from './cart.state';
import { CartActions } from './cart.actions';

export function cartReducer(state: CartState, action: CartActions): CartState {
  switch (action.type) {
    case 'set-cart-data':
      return { ...state, ...action.cartData };
    case 'set-cart-count':
      return { ...state, cartCount: Number(action.cartCount) };
    default:
      return state;
  }
}
