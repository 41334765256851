/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';

import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact, isPlatform } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { connect } from './data/connect';
import { AppContextProvider } from './data/AppContext';
import { loadUserData, setUserData, setUserName, setUserToken } from './data/user/user.actions';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

/*reset*/
import './AppReset.css';

import './App.scss';
import './style/toast-ui/ToastUIEditor.scss';

import Main from './pages/Main';
import ProductPage from './pages/ProductPage';
import routes from './routes';
import RecipePage from './pages/RecipePage';
import CartPage from './pages/MyCartPage';
import GiftPage from './pages/GiftPage';
import ProductDetailPage from './pages/ProductDetailPage';
import MyPage from './pages/MyPage';
import DeliveryPage from './pages/DeliveryPage';
import SignUpPage from './pages/SignUpPage';
import LoginPage from './pages/LoginPage';
import MyInfoPage from './pages/MyInfoPage';
import NoticePage from './pages/NoticePage';
import MyInfoDetailPage from './pages/MyInfoDetailPage';
import PayPage from './pages/PayPage';
import InquiryPage from './pages/InquiryPage';
import InquiryDetailPage from './pages/InquiryDetailPage';
import DeliveryAdditionalPage from './pages/DeliveryAdditionalPage';
import DeliveryInfoPage from './pages/DeliveryInfoPage';
import SignUpUsefulPage from './pages/SignUpUsefulPage';
import searchPage from './pages/SearchPage';
import RecipeDetailPage from './pages/RecipeDetailPage';
import RedirectToLogin from './pages/RedirectToLogin';
import { RouteComponentProps } from 'react-router';
import { loadSocialData } from './data/social/social.actions';
import { loadCategoryData, setCategoryId } from './data/category/category.actions';
import { cartTotal, doLogout, getCategory } from './data/dataApi';
import { loadCartData, setCartTotal } from './data/cart/cart.actions';
import { loadPayData } from './data/pay/pay.actions';
import PayComplete from './pages/PayComplete';
import DeliveryDetailPage from './pages/DeliveryDetailPage';
import OrderListPage from './pages/OrderListPage';
import OrderDetailPage from './pages/OrderDetailPage';
import TermsOfServicePage from './pages/TermsOfServicePage';
import { fetchCodeStateList } from './data/code/code.actions';
import { CodeObject } from './data/code/code.state';

Bugsnag.start({
  apiKey: 'c3719a28ccecdfc6e8b458cfd6ecb14a',
  plugins: [new BugsnagPluginReact()],
});

// @ts-ignore
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

/* Theme variables */

const App: React.FC = () => {
  return (
    <ErrorBoundary>
      <AppContextProvider>
        <IonicAppConnected />
      </AppContextProvider>
    </ErrorBoundary>
  );
};

interface OwnProps extends RouteComponentProps {}

interface StateProps {
  darkMode: boolean;
  user: any;
  codes: CodeObject;
}

interface DispatchProps {
  setUserData: typeof setUserData;
  setUserName: typeof setUserName;
  setUserToken: typeof setUserToken;
  loadUserData: typeof loadUserData;
  loadSocialData: typeof loadSocialData;
  loadCategoryData: typeof loadCategoryData;
  setCategoryId: typeof setCategoryId;
  setCartTotal: typeof setCartTotal;
  loadCartData: typeof loadCartData;
  loadPayData: typeof loadPayData;
  fetchCodeStateList: typeof fetchCodeStateList;
}

interface IonicAppProps extends OwnProps, StateProps, DispatchProps {}

setupIonicReact({
  rippleEffect: false,
  mode: 'md',
});

const IonicApp: React.FC<IonicAppProps> = ({
  setCategoryId,
  darkMode,
  user,
  loadUserData,
  loadSocialData,
  loadCategoryData,
  loadCartData,
  loadPayData,
  setCartTotal,
  fetchCodeStateList,
}) => {
  const categoryData = async () => {
    const category = await getCategory();
    if (category.category.length > 0) {
      setCategoryId(String(category.category[0].categoryId));
    }
  };

  const cartTotalCount = async () => {
    const getCartTotal = await cartTotal();
    if (getCartTotal.result) setCartTotal(getCartTotal.cartCount);
  };

  useEffect(() => {
    fetchCodeStateList();
    categoryData();
    cartTotalCount();
    loadUserData();
    loadSocialData();
    loadCategoryData();
    loadCartData();
    loadPayData();
  }, []);

  return (
    <IonApp className={`${darkMode ? 'dark-theme' : ''} ${isPlatform('desktop') ? 'desktop' : ''}`}>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <IonRouterOutlet id="main">
            <Route path="/" component={Main} exact />
            <Route path={routes.LOGIN} component={LoginPage} exact />
            <Route
              path={routes.LOGOUT}
              render={() => {
                // FIXME 로그아웃 처리
                doLogout().then(cartTotalCount);
                return <RedirectToLogin setUserData={setUserData} setUserName={setUserName} user={user} />;
              }}
            />
            <Route path={routes.SEARCH} component={searchPage} exact />
            <Route path={routes.PRODUCT} component={ProductPage} exact />
            <Route path={routes.PRODUCT_DETAIL} component={ProductDetailPage} exact />
            <Route path={routes.GIFT} component={GiftPage} exact />
            <Route path={routes.DELIVERY} component={DeliveryPage} exact />
            <Route path={routes.DELIVERY_DETAIL} component={DeliveryDetailPage} exact />
            <Route path={routes.DELIVERY_INFO} component={DeliveryInfoPage} exact />
            <Route path={routes.DELIVERY_CHANGE} component={DeliveryPage} exact />
            <Route path={routes.DELIVERY_ADDITIONAL} component={DeliveryAdditionalPage} exact />
            <Route path={routes.RECIPE} component={RecipePage} exact />
            <Route path={routes.RECIPE_DETAIL} component={RecipeDetailPage} exact />
            <Route path={routes.CART} component={CartPage} exact />
            <Route path={routes.MYPAGE} component={MyPage} exact />
            <Route path={routes.SIGNUP} component={SignUpPage} exact />
            <Route path={routes.SIGNUP_USEFUL} component={SignUpUsefulPage} exact />
            <Route path={routes.MYINFO} component={MyInfoPage} exact />
            <Route path={routes.MYINFO_DETAIL} component={MyInfoDetailPage} exact />
            <Route path={routes.NOTICE} component={NoticePage} exact />
            <Route path={routes.TERMS_OF_SERVICE} component={TermsOfServicePage} exact />
            <Route path={routes.PRIVACY_POLICY} component={TermsOfServicePage} exact />
            <Route path={routes.PAY} component={PayPage} exact />
            <Route path={routes.INQUIRY} component={InquiryPage} exact />
            <Route path={routes.INQUIRY_DETAIL} component={InquiryDetailPage} exact />
            <Route path={routes.OAUTH} component={LoginPage} exact />
            <Route path={routes.PAY_COMPLETE} component={PayComplete} exact />
            <Route path={routes.ORDER} component={OrderListPage} exact />
            <Route path={routes.ORDER_DETAIL} component={OrderDetailPage} exact />
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;

const IonicAppConnected = connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
    user: state.user,
    codes: state.codes,
  }),
  mapDispatchToProps: {
    setUserData,
    setUserName,
    setUserToken,
    loadUserData,
    loadSocialData,
    loadCategoryData,
    setCategoryId,
    loadCartData,
    loadPayData,
    setCartTotal,
    fetchCodeStateList,
  },
  component: IonicApp,
});
