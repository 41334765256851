/* eslint-disable @typescript-eslint/no-unused-vars,react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  IonPage,
  IonRow,
  IonText,
} from '@ionic/react';
import { connect } from '../data/connect';
import { RouteComponentProps, useLocation } from 'react-router';
import Menu from '../components/menu/Menu';
import ProductCard from '../components/product/ProductCard';
import DefaultHeader from '../components/header/DefaultHeader';
import DefaultFooter from '../components/footer/DefaultFooter';
import SubMenu from '../components/menu/SubMenu';
import './ProductPage.scss';
import CartModal from '../components/cart/CartModal';
import { Product } from '../models/Product';
import { productSelect } from '../data/dataApi';
import { informationCircle } from 'ionicons/icons';
import { setCartTotal } from '../data/cart/cart.actions';
import * as selectors from '../data/selectors';
import { CodeObject } from '../data/code/code.state';

interface OwnProps extends RouteComponentProps {}

interface StateProps {
  codes: CodeObject;
}

interface DispatchProps {
  setCartTotal: typeof setCartTotal;
}

interface ProductProps extends OwnProps, StateProps, DispatchProps {}

const ProductPage: React.FC<ProductProps> = ({ match, setCartTotal, codes }) => {
  const [currentSegment, setCurrentSegment] = useState(''); // 카테고리 (탭) 값
  const [cartChk, setCartChk] = useState(false); // 장바구니 딤드처리 목록

  const [products, setProducts] = useState<Product[]>([]); //상품 정보
  const [product, setProduct] = useState<Product>(); // 장바구니 딤드 정보 (상품 장바구니 담을때)

  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false); //스크롤 여부
  const [chkProduct, setChkProduct] = useState(false); //상품 갯수 여부
  const [pageNumber, setPageNumber] = useState(1);

  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const categoryId = String(params.get('categoryId') || 1);

  const getData = async (resetList: boolean = false) => {
    const result = await productSelect({
      category: Number(currentSegment) ? Number(currentSegment) : 1,
      page: resetList ? 1 : pageNumber,
      size: 10,
    });

    if (!result.product || result.product.length === 0) {
      setInfiniteDisabled(true);
      setChkProduct(false);
      setProducts([]);
    } else {
      if (resetList) {
        setChkProduct(true);
        setProducts(result.product);
        setPageNumber(pageNumber + 1);
      } else {
        setProducts([...products, ...result.product]);
        setPageNumber(pageNumber + 1);
      }

      if (result.product.length < 10) {
        setInfiniteDisabled(true);
      } else {
        setInfiniteDisabled(false);
      }
    }
  };

  const loadData = async (ev: any) => {
    await getData();
    ev.target.complete();
    if (products.length >= 1000) {
      setInfiniteDisabled(true);
    }
  };

  useEffect(() => {
    if (cartChk) {
      setCartChk(false);
    }
  }, [cartChk]);

  useEffect(() => {
    setCurrentSegment(categoryId);
    setPageNumber(1);
    getData(true);
  }, [currentSegment, match.params]);

  return (
    <IonPage id="product">
      <IonContent>
        <CartModal item={cartChk} children={product} />
        <DefaultHeader title="상품전체" showBackButton={true} />
        <IonGrid>
          {/*메인 Tab(대분류) menu*/}
          <Menu />

          <SubMenu
            page={currentSegment}
            change={(changedValue: React.SetStateAction<string>) => {
              setCurrentSegment(changedValue);
            }}
          />

          {!chkProduct && products.length > 0 && (
            <>
              <IonRow>
                <IonCol className={'ion-text-center ion-padding-vertical'}>
                  <IonIcon icon={informationCircle} size={'large'} color="warning" />
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol className={'ion-text-center ion-padding-vertical'}>
                  <IonText>등록된 상품이 품절이거나, 준비중 입니다.</IonText>
                </IonCol>
              </IonRow>
            </>
          )}

          <IonRow className="product_wrap">
            <IonCol>
              {products && products.length > 0 && (
                <IonList inset={false}>
                  {products.map((product) => (
                    <ProductCard
                      key={product.productId}
                      product={product}
                      categoryId={currentSegment}
                      cart={(cartChk, product) => {
                        setCartChk(cartChk);
                        setProduct(product);
                      }}
                      setCartTotal={setCartTotal}
                      codes={codes}
                    />
                  ))}
                  <IonInfiniteScroll onIonInfinite={loadData} disabled={isInfiniteDisabled} threshold="200px">
                    <IonInfiniteScrollContent loadingSpinner="bubbles" loadingText="불러오는 중..."></IonInfiniteScrollContent>
                  </IonInfiniteScroll>
                </IonList>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
        <DefaultFooter />
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    codes: selectors.getCodes(state),
  }),
  mapDispatchToProps: { setCartTotal },
  component: React.memo(ProductPage),
});
