/* eslint-disable react-hooks/exhaustive-deps,@typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonList,
  IonPage,
  IonRouterLink,
  IonRow,
  IonText,
  useIonAlert,
} from '@ionic/react';
import { connect } from '../data/connect';

import { RouteComponentProps } from 'react-router';
import DefaultHeader from '../components/header/DefaultHeader';
import DefaultFooter from '../components/footer/DefaultFooter';

import './LoginPage.scss';
import routes from '../routes';
import { cartTotal, doLogin, getSaveBox, getSaveId, setSaveId, setSaveIdBox, socialModamLogin, socialModamSignUp } from '../data/dataApi';
import { setId, setIsLoggedIn, setUserData, setUserName, setUserToken } from '../data/user/user.actions';
import * as selectors from '../data/selectors';
import {
  setSocialEmail,
  setSocialId,
  setSocialPhoneNumber,
  setSocialStatus,
  setSocialType,
  setSocialUserName,
} from '../data/social/social.actions';
import { URL_PREFIX } from '../constants';
import { setCartTotal } from '../data/cart/cart.actions';

interface OwnProps extends RouteComponentProps {}

interface StateProps {
  user: any;
  social: any;
}

interface DispatchProps {
  setIsLoggedIn: typeof setIsLoggedIn;
  setUserName: typeof setUserName;
  setUserToken: typeof setUserToken;
  setUserData: typeof setUserData;
  setId: typeof setId;
  setSocialStatus: typeof setSocialStatus;
  setSocialType: typeof setSocialType;
  setSocialId: typeof setSocialId;
  setSocialUserName: typeof setSocialUserName;
  setSocialPhoneNumber: typeof setSocialPhoneNumber;
  setSocialEmail: typeof setSocialEmail;
  setCartTotal: typeof setCartTotal;
}

interface LoginProps extends OwnProps, StateProps, DispatchProps {}

const LoginPage: React.FC<LoginProps> = ({
  setIsLoggedIn,
  setUserName,
  setUserToken,
  setUserData,
  setId,
  history,
  match,
  setSocialStatus,
  setSocialType,
  setSocialId,
  setSocialUserName,
  setSocialPhoneNumber,
  setSocialEmail,
  social,
  setCartTotal,
}) => {
  const [present] = useIonAlert(); // alert 폼
  const [userNameInput, setUserNameInput] = useState(''); // 아이디
  const [passwordInput, setPasswordInput] = useState(''); // 비밀번호
  const [formSubmitted, setFormSubmitted] = useState(false); // 아이디 비밀번호 체크
  const [userNameError, setUserNameError] = useState(false); // 아이디 체크
  const [passwordError, setPasswordError] = useState(false); // 비밀번호 체크
  const [loginFailed, setLoginFailed] = useState(false); // 로그인 체크
  const [checked, setChecked] = useState(false); // 아이디 저장

  const [chkStatus, setChkStatus] = useState(''); // 소셜 로그인 상태값
  const [chkSocialToken, setChkSocialToken] = useState(''); // 소셜 로그인 성공 시 인증 토큰
  const [chkSignUpCode, setChkSignUpCode] = useState(''); // 소셜 회원가입 데이터 취득용 코드(토큰)

  const cartTotalCount = async () => {
    const getCartTotal = await cartTotal();
    if (getCartTotal.result) setCartTotal(getCartTotal.cartCount);
  };

  //소셜 로그인 응답 처리
  const socialChk = async () => {
    if (window.location.search) {
      const search = new URLSearchParams(window.location.search);
      const status = search.get('status') || '';
      const token = search.get('token') || '';
      const code = search.get('code') || '';

      setSocialStatus(status);

      setChkSocialToken(token);
      setChkSignUpCode(code);
      setChkStatus(status);
    } else {
      setChkStatus('');
      setSocialStatus('');
      setSocialType('');
      setSocialId('');
      setSocialUserName('');
      setSocialPhoneNumber('');
      setSocialEmail('');
    }
  };

  const clearSocialData = () => {
    setSocialStatus('');
    setSocialType('');
    setSocialId('');
    setSocialUserName('');
    setSocialPhoneNumber('');
    setSocialEmail('');
  };

  const login = async (e: React.FormEvent) => {
    e.preventDefault();
    setFormSubmitted(true);
    if (!userNameInput) {
      setUserNameError(true);
    } else {
      setUserNameError(false);
    }
    if (!passwordInput) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }

    if (userNameInput && passwordInput) {
      const login = await doLogin(userNameInput, passwordInput, checked);
      if (login.isLoggedIn) {
        setIsLoggedIn(login.isLoggedIn);
        setUserName(login.userName);
        setUserToken(login.accessToken);
        setId(login.userId || '');

        setUserData(login);
        cartTotalCount();
        history.push(routes.MAIN);
      } else {
        setLoginFailed(true);
      }
    }
  };

  const saveChk = async () => {
    const getChk = await getSaveBox();
    const getId = await getSaveId();
    if (getChk !== null && getChk === 'true') {
      setChecked(true);
      setUserNameInput(getId || '');
    } else {
      setChecked(false);
      setUserNameInput('');
      await setSaveId(undefined);
      await setSaveIdBox(false);
    }
  };

  const socialLoginWrap = async (value: string) => {
    if (value == 'apple') {
      present({
        header: '모던한담',
        message: '준비 중입니다. 다른 SNS를 이용해주세요.',
        buttons: [
          {
            text: '확인',
            role: 'confirm',
            cssClass: 'secondary',
          },
        ],
      });
    } else {
      location.href = `${URL_PREFIX}/api/oauth2/authorize/${value}`;
    }
  };

  const modamSocialSignUpCode = async (chkSignUpCode: string) => {
    const signUpData = await socialModamSignUp(chkSignUpCode);

    if (signUpData) {
      setSocialType(signUpData.type);
      setSocialId(signUpData.id);
      setSocialUserName(signUpData.name || '');
      setSocialPhoneNumber(signUpData.phoneNumber || '');
      setSocialEmail(signUpData.email || '');
    } else {
      clearSocialData();
    }
  };

  const modamSocialToken = async (chkSocialToken: string) => {
    const socialModam = await socialModamLogin(chkSocialToken);
    if (socialModam.isLoggedIn) {
      setIsLoggedIn(socialModam.isLoggedIn);
      setUserName(socialModam.userName);
      setUserToken(socialModam.accessToken);

      setUserData(socialModam);
      cartTotalCount();
      history.push(routes.MAIN);
    } else {
      present({
        header: '모던한담',
        message: socialModam.message,
        buttons: [
          {
            text: '확인',
            role: 'confirm',
            cssClass: 'secondary',
            handler: () => {
              history.push(routes.LOGIN);
            },
          },
        ],
      });
    }
  };

  useEffect(() => {
    social = {};
    saveChk();
    if (!checked) {
      setUserNameInput('');
      setPasswordInput('');
    } else {
      setPasswordInput('');
    }
  }, [match.params]);

  useEffect(() => {
    socialChk();
    if (chkStatus) {
      if (chkStatus == 'signup') {
        // 소셜로 회원가입을 하지 않은 경우
        modamSocialSignUpCode(chkSignUpCode).then(() => history.push(routes.SIGNUP));
      } else if (chkStatus == 'success') {
        // 소셜 회원가입을 완료 후 로그인 한 경우
        // TODO : 현재는 정책이 없어서 기존 모던한담 회원가입을 해도 소셜 로그인 가능,
        modamSocialToken(chkSocialToken);
      } else if (chkStatus == 'error') {
        // TODO 에러가 발생 시 추가적으로 작업할 예정
      }
    }
  }, [chkStatus]);

  return (
    <IonPage id="login_page">
      <IonContent>
        <DefaultHeader title="로그인" showBackButton={true} />

        <IonGrid>
          <form noValidate onSubmit={login}>
            <IonList>
              <IonItem lines="none">
                <IonInput
                  name="email"
                  type="text"
                  value={userNameInput}
                  spellCheck={false}
                  autocapitalize="off"
                  title="아이디를 입력하세요"
                  placeholder="이메일"
                  onIonChange={(e) => setUserNameInput(e.detail.value!)}
                  required
                />
              </IonItem>

              {formSubmitted && userNameError && (
                <IonText color="danger">
                  <p className="ion-padding-start">이메일을 입력해주세요.</p>
                </IonText>
              )}

              <IonItem lines="none">
                <IonInput
                  name="password"
                  type="password"
                  value={passwordInput}
                  title="비밀번호를 입력하세요"
                  placeholder="비밀번호를 입력하세요."
                  onIonChange={(e) => setPasswordInput(e.detail.value!)}
                />
              </IonItem>

              {formSubmitted && passwordError && (
                <IonText>
                  <p className="ion-padding-start">비밀번호를 입력해주세요.</p>
                </IonText>
              )}
              {formSubmitted && loginFailed && (
                <IonText>
                  <p className="ion-padding-start">이메일 또는 비밀번호를 다시 확인해주세요.</p>
                </IonText>
              )}

              <IonRow>
                <IonCol>
                  <input type="checkbox" id="saveId" checked={checked} onChange={(e) => setChecked(e.currentTarget.checked)} />
                  <label htmlFor="saveId" className="save">
                    아이디 저장
                  </label>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <IonButton expand="full" type="submit">
                    로그인
                  </IonButton>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <ul className="option">
                    <li>
                      <IonRouterLink routerLink={`${routes.SIGNUP}`}>
                        <span>회원가입</span>
                      </IonRouterLink>
                    </li>

                    <li>
                      <IonRouterLink routerLink={`${routes.SIGNUP}`}>
                        <span>아이디 찾기</span>
                      </IonRouterLink>
                    </li>

                    <li>
                      <IonRouterLink routerLink={`${routes.SIGNUP}`}>
                        <span>비밀번호 찾기</span>
                      </IonRouterLink>
                    </li>
                  </ul>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol className="whiteThem">
                  <IonButton expand="block" onClick={() => socialLoginWrap('kakao')}>
                    카카오 로그인
                  </IonButton>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol className="whiteThem">
                  <IonButton expand="block" className="naver" onClick={() => socialLoginWrap('naver')}>
                    네이버 로그인
                  </IonButton>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol className="whiteThem">
                  <IonButton expand="block" className="google" onClick={() => socialLoginWrap('google')}>
                    구글 로그인
                  </IonButton>
                </IonCol>
              </IonRow>

              {/*
              <IonRow>
                <IonCol className="whiteThem">
                  <IonButton expand="block" className="apple" onClick={() => socialLoginWrap('apple')}>
                    Apple 로그인
                  </IonButton>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol className="whiteThem colorThem">
                  <IonButton expand="block">카카오 로그인</IonButton>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol className="whiteThem colorThem">
                  <IonButton expand="block" className="naver">
                    네이버 로그인
                  </IonButton>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol className="whiteThem colorThem">
                  <IonButton expand="block" className="google">
                    구글 로그인
                  </IonButton>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol className="whiteThem colorThem">
                  <IonButton expand="block" className="apple">
                    Apple 로그인
                  </IonButton>
                </IonCol>
              </IonRow>
              */}
            </IonList>
          </form>
        </IonGrid>
        <DefaultFooter />
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: selectors.getUser(state),
    social: selectors.getSocial(state),
  }),
  mapDispatchToProps: {
    setUserName,
    setIsLoggedIn,
    setUserToken,
    setUserData,
    setId,
    setSocialStatus,
    setSocialType,
    setSocialId,
    setSocialUserName,
    setSocialPhoneNumber,
    setSocialEmail,
    setCartTotal,
  },
  component: LoginPage,
});
