import React from 'react';
import { IonCol } from '@ionic/react';

import './ProductDetailDesc.scss';

interface ProductDetailDescProps {
  contents: string;
}

const ProductDetailDescPage: React.FC<ProductDetailDescProps> = ({ contents }) => {
  return (
    <>
      <IonCol className="con_type1 toastui-editor-contents" dangerouslySetInnerHTML={{ __html: contents }} style={{ width: '100%' }}>
        {/*<dl>*/}
        {/*  <dt>*/}
        {/*    <strong>알등심 스테이크</strong>*/}
        {/*  </dt>*/}
        {/*  <dd>*/}
        {/*    <p>꽃등심 중 예쁘고 반듯한 알등심만을 남겨요 :)</p>*/}
        {/*    <IonImg src="/assets/product/steak/detail_info1.jpg" />*/}
        {/*  </dd>*/}

        {/*  <dt>*/}
        {/*    <strong>알등심 설명</strong>*/}
        {/*  </dt>*/}
        {/*  <dd>*/}
        {/*    <p>부위, 두께</p>*/}
        {/*    <IonImg src="/assets/product/steak/detail_info2.jpg" />*/}
        {/*  </dd>*/}

        {/*  <dt>*/}
        {/*    <strong>투뿔한우 설명</strong>*/}
        {/*  </dt>*/}
        {/*  <dd>*/}
        {/*    <p>투뿔 한우 중 넘버 8,9 등급만을 취급 일정한 퀄리티 유지</p>*/}
        {/*  </dd>*/}

        {/*  <dt>*/}
        {/*    <strong>HACCP</strong>*/}
        {/*  </dt>*/}
        {/*  <dd>*/}
        {/*    <p>꽃등심 중 예쁘고 반듯한 알등심만을 남겨 스테이크로 즐겨요 :)</p>*/}
        {/*    <IonImg className="logo" src="/assets/logo/haccp_logo.jpg" alt="안전관리인증" />*/}
        {/*  </dd>*/}

        {/*  <dt>*/}
        {/*    <strong>포장</strong>*/}
        {/*  </dt>*/}
        {/*  <dd>*/}
        {/*    <p>스킨포장 설명, 보관 일수 안내 당일, 새벽배송과 택배배송 포장 안내</p>*/}
        {/*  </dd>*/}
        {/*</dl>*/}
      </IonCol>
    </>
  );
};

export default ProductDetailDescPage;
