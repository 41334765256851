import React from 'react';
import { IonCol, IonContent, IonGrid, IonImg, IonItem, IonList, IonPage, IonRouterLink, IonRow } from '@ionic/react';
import { connect } from '../data/connect';

import { RouteComponentProps } from 'react-router';
import DefaultHeader from '../components/header/DefaultHeader';
import DefaultFooter from '../components/footer/DefaultFooter';
import './MyPage.scss';
import routes from '../routes';
import * as selectors from '../data/selectors';
import { checkCertification, myPageSnsChk } from '../data/dataApi';

interface OwnProps extends RouteComponentProps {}

interface StateProps {
  user: any;
}

interface DispatchProps {}

interface MyPageProps extends OwnProps, StateProps, DispatchProps {}

const MyPage: React.FC<MyPageProps> = ({ user, history }) => {
  const userInfoChk = async () => {
    if (user.userData.accessToken) {
      const snsChk = await checkCertification(user.userData.accessToken);
      if (snsChk.result) {
        //회원가입을 SNS로 한사람은 비밀번호 체크를 안함
        history.push(routes.MYINFO_DETAIL);
      } else {
        //모던한담으로 가입 한사람은 비밀번호 체크
        user.userData.userId = snsChk.email;
        history.push(routes.MYINFO);
      }
    }
  };

  return (
    <IonPage id="myPage">
      <IonContent>
        <DefaultHeader title="마이페이지" showBackButton={true} />
        <IonGrid>
          <IonRow>
            <IonCol className="message">
              <div>
                <span>{user.userData.userName}</span>님 안녕하세요!
              </div>
            </IonCol>
          </IonRow>

          <IonRow className="my_data">
            <IonCol>
              <div>
                쿠폰
                <p>0</p>
              </div>
            </IonCol>
            <IonCol>
              <div className="line">
                포인트
                <p>0</p>
              </div>
            </IonCol>
            <IonCol>
              <div className="line">
                문의
                <p>0</p>
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div className="grey_box"></div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonList lines="full">
                <IonItem>
                  <IonRouterLink routerLink={routes.ORDER}>주문내역</IonRouterLink>
                </IonItem>
                <IonItem>
                  <IonRouterLink routerLink="#">구매후기</IonRouterLink>
                  <span className="alert">작성하실 수 있는 후기가 있어요!</span>
                </IonItem>
                <IonItem>
                  <IonRouterLink routerLink={routes.DELIVERY}>배송지관리</IonRouterLink>
                </IonItem>
                <IonItem>
                  <IonRouterLink routerLink={routes.DELIVERY_INFO}>배송안내</IonRouterLink>
                </IonItem>
                <IonItem>
                  <IonRouterLink routerLink="#">문의하기</IonRouterLink>
                  <IonImg src="/assets/icon/ico_new.png" alt="신규" />
                </IonItem>
                <IonItem>
                  <IonRouterLink onClick={() => userInfoChk()}>내정보관리</IonRouterLink>
                </IonItem>
                <IonItem>
                  <IonRouterLink routerLink={routes.NOTICE}>공지사항</IonRouterLink>
                  <IonImg src="/assets/icon/ico_new.png" alt="신규" />
                </IonItem>
              </IonList>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div className="grey_box"></div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol className="logout">
              <IonRouterLink routerLink={routes.LOGOUT} routerDirection="none">
                로그아웃
              </IonRouterLink>
            </IonCol>
          </IonRow>
        </IonGrid>

        <DefaultFooter />
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: selectors.getUser(state),
  }),
  component: React.memo(MyPage),
});
