import React from 'react';
import { IonCol, IonContent, IonGrid, IonImg, IonPage, IonRow } from '@ionic/react';
import { connect } from '../data/connect';

import { RouteComponentProps } from 'react-router';
import DefaultHeader from '../components/header/DefaultHeader';
import './RecipeDetailPage.scss';

import DefaultFooter from '../components/footer/DefaultFooter';
import Menu from '../components/menu/Menu';

interface OwnProps extends RouteComponentProps {}

interface StateProps {}

interface DispatchProps {}

interface RecipeDetailProps extends OwnProps, StateProps, DispatchProps {}

const RecipeDetailPage: React.FC<RecipeDetailProps> = ({ match }) => {
  //TODO : 파라미터 정해지면 메뉴 속성 작업 해야됨.
  console.log('match : ', match);

  return (
    <IonPage id="recipe_detail">
      <IonContent>
        <DefaultHeader title="모담레시피" showBackButton={true} />
        <IonGrid>
          <Menu />

          <IonRow>
            <IonCol>
              <IonImg src="/assets/product/recipe/recipe1.jpg" />
            </IonCol>
          </IonRow>

          <IonRow className="pd">
            <IonCol>
              <strong className="recipe_tit">차돌박이 샐러드</strong>
            </IonCol>
          </IonRow>

          <IonRow className="pd">
            <IonCol className="product_info">
              <ul>
                <li>
                  <span>중량</span>
                  <em>냉장차돌박이 200g, 로메인, 양파, 팽이버섯</em>
                </li>

                <li>
                  <span>드레싱</span>
                  <em>
                    화이트발사믹(식초) 4큰술, 유자청 1큰술, 설탕 1큰술, 꿀 1.5작은술, 소금 1/3작은술, 레몬즙 1큰술, 홀그레인머스타드
                    2작은술, 마늘 다진 것 1쪽, 겨자 1작은술, 참기름 1.5큰술, 후추 약간
                  </em>
                </li>
              </ul>
            </IonCol>
          </IonRow>

          <IonRow className="pd">
            <IonCol className="recipe_con">
              <dl>
                <dt>레시피</dt>
                <dd>
                  로메인과 양파를 먹기 좋게 썰어준다.
                  <br /> 팽이버섯은 소금과 후추를 넣고 수분이 날아갈 때까지 바삭하게 볶아준다.
                  <br /> (팽이버섯이 정말 킥이에요!) <br /> 차돌박이는 노릇하게 굽는다.
                  <br /> 채소를 그릇에 담고 차돌박이, 팽이버섯을 올린 후 소스를 붓는다.
                </dd>
              </dl>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonImg src="/assets/product/recipe/recipe1.jpg" />
            </IonCol>
          </IonRow>
        </IonGrid>
        <DefaultFooter />
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  component: RecipeDetailPage,
});
