/* eslint-disable @typescript-eslint/no-unused-vars,react-hooks/exhaustive-deps,@typescript-eslint/no-unused-expressions */
import React, { useEffect, useState } from 'react';
import {
  IonAccordion,
  IonAccordionGroup,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonText,
} from '@ionic/react';
import { connect } from '../data/connect';

import './PayPage.scss';
import './OrderDetailPage.scss';
import { RouteComponentProps } from 'react-router';
import DefaultHeader from '../components/header/DefaultHeader';
import DefaultFooter from '../components/footer/DefaultFooter';
import * as selectors from '../data/selectors';
import { setDeliveryData } from '../data/delivery/delivery.actions';
import { modamOrderDetail } from '../data/dataApi';
import { Order } from '../models/OrderDetail';
import { CodeObject } from '../data/code/code.state';
import { codeName } from '../util/EnumUtils';

interface OwnProps
  extends RouteComponentProps<{
    orderId: string;
  }> {}

interface StateProps {
  user: any;
  codes: CodeObject;
}

interface DispatchProps {
  setDeliveryData: typeof setDeliveryData;
}

interface OrderDetailProps extends OwnProps, StateProps, DispatchProps {}

const OrderDetailPage: React.FC<OrderDetailProps> = ({ match, user, codes }) => {
  const orderId = match.params.orderId;
  const [order, setOrder] = useState<Order>({
    orderAmount: 0,
    orderActivatedAt: '',
    orderStatusType: '',
    orderNo: '',
    orderDeliveryAmount: 0,
    orderGrandTotal: 0,
    purchaserName: '',
    purchaserCellphoneNo: '',
    orderDestination: {
      orderId: 0,
      orderDestSeq: 0,
      destRecipientName: '',
      destRecipientCellphoneNo: '',
      destZipcode: '',
      destAddress: '',
      destAddressDetail: '',
      deliveryRequestMemo: '',
    },
    orderPayments: [],
    orderProducts: [],
    orderDeliveryFeeInfos: [],
  });

  const getData = async () => {
    const data = await modamOrderDetail(user.userData.accessToken, orderId);
    console.log(data);
    setOrder(data);
  };

  useEffect(() => {
    if (!!user.userData.accessToken) {
      getData();
    }
  }, [user.userData.accessToken]);

  function numberWithCommas(x: { toString: () => string }) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  const autoHyphen = (destRecipientCellphoneNo: string) => {
    return destRecipientCellphoneNo.replace(/[^0-9]/g, '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
  };

  return (
    <IonPage id="pay_page">
      <IonContent>
        <DefaultHeader title="주문내역" showBackButton={true} />
        <IonGrid>
          <IonRow>
            <IonCol>
              <div className="order_info">
                <strong>주문 정보</strong>
                <IonText>
                  <span className="total_type1">
                    <div className="option_box1">
                      <strong>주문번호</strong>
                      <p className="option_value">
                        <em>{order.orderNo}</em>
                      </p>
                    </div>
                    <div className="option_box1">
                      <strong>주문일자</strong>
                      <p className="option_value">
                        <em>{order.orderActivatedAt.replaceAll('-', '. ')}</em>
                      </p>
                    </div>
                    <div className="option_box1">
                      <strong>주문상태</strong>
                      <p className="option_value">
                        <em>{order.orderStatusType}</em>
                      </p>
                    </div>
                    {!!order.orderCancelled && (
                      <div className="option_box1">
                        <strong>취소완료일자</strong>
                        <p className="option_value">
                          <em>{order.orderCancelSettledAt?.replaceAll('-', '. ')}</em>
                        </p>
                      </div>
                    )}
                  </span>
                </IonText>
              </div>
            </IonCol>
          </IonRow>

          <IonAccordionGroup value="product">
            <IonAccordion value="product">
              <IonItem className="tit" slot="header">
                <IonLabel>주문상품</IonLabel>
              </IonItem>

              <IonList slot="content" inset={false}>
                {order.orderProducts.length > 0 &&
                  order.orderProducts.map((orderProduct) => (
                    <IonItem lines="none" key={`${orderProduct.imageUriPath}`}>
                      <IonImg className="col_span" src={orderProduct.imageUriPath} alt={orderProduct.productName} />
                      <IonText>
                        <div className="pay_box col_span">
                          <div>
                            <strong>{orderProduct.productName}</strong>
                            <p className="price">
                              <em>{numberWithCommas(orderProduct.productSalePrice)}</em>원
                            </p>
                            <p className="count">
                              <em>{orderProduct.productQuantity}</em>개
                            </p>
                            {!!orderProduct.productDeliveryStatus && (
                              <p className="delivery_status">{orderProduct.productDeliveryStatus}</p>
                            )}
                          </div>
                          {!!orderProduct.deliveryTrackingNo && (
                            <div>
                              <p className="invoice">
                                {orderProduct.deliveryType === 'PARCEL'
                                  ? `[${orderProduct.deliveryCompanyName}] ${orderProduct.deliveryTrackingNo}`
                                  : codeName(orderProduct.deliveryType, codes.DeliveryType)}
                              </p>
                            </div>
                          )}
                        </div>
                      </IonText>
                    </IonItem>
                  ))}
              </IonList>
            </IonAccordion>
          </IonAccordionGroup>

          <IonRow>
            <IonCol>
              <div className="order_info">
                <strong>주문고객 정보</strong>
                <IonText>
                  <span className="d_name">{order.purchaserName}</span>
                  <span className="d_phone">{autoHyphen(String(order.purchaserCellphoneNo))}</span>
                </IonText>
              </div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div className="order_info delivery">
                <strong>배송지 정보</strong>
                {order.orderDestination && (
                  <>
                    <IonText>
                      <span className="d_name">{order.orderDestination.destRecipientName}</span>
                      <span className="d_phone">{autoHyphen(String(order.orderDestination.destRecipientCellphoneNo))}</span>
                      <span className="d_name">{order.orderDestination.destAddress}</span>
                      <address>{order.orderDestination.destAddressDetail}</address>
                    </IonText>
                    {order.orderDestination.deliveryRequestMemo && (
                      <IonText>
                        <span>요청사항: {order.orderDestination.deliveryRequestMemo}</span>
                      </IonText>
                    )}
                  </>
                )}
              </div>
            </IonCol>
          </IonRow>

          <IonAccordionGroup value="total_price" className="arrow_box">
            <IonAccordion value="total_price">
              <IonItem className="tit" slot="header">
                <IonLabel>결제정보</IonLabel>
              </IonItem>

              <IonList slot="content">
                <IonText>
                  <IonRow className="p_n">
                    <IonCol>
                      <div className="total_type1">
                        <div className="option_box1">
                          <strong>결제수단</strong>
                          {/* TODO: 구체적인 정보, 은행이나 카드사 표시할지 말지 */}
                          <p className="modam_price">
                            {order?.orderPayments?.map((payment, index) => {
                              return (
                                <span key={index}>
                                  {index > 0 ? ', ' : null}
                                  <em key={index}>{payment.orderPaymentType}</em>
                                  {payment.receiptUrl ? (
                                    <span
                                      onClick={() =>
                                        window.open(
                                          payment.receiptUrl,
                                          'tracking',
                                          'toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=350, height=600, top=30',
                                        )
                                      }
                                    >
                                      [영수증]
                                    </span>
                                  ) : null}
                                </span>
                              );
                            })}
                          </p>
                        </div>
                        {order?.orderPayments
                          ?.filter((payment) =>
                            [
                              payment.virtualAccountExpiredDate,
                              payment.virtualBankHolder,
                              payment.virtualBankName,
                              payment.paymentInformation,
                            ].every((v) => !!v),
                          )
                          .map((payment, index) => (
                            <>
                              <div className="option_box1" key={index * 2}>
                                <strong>가상계좌</strong>
                                <p className="modam_price">
                                  <span>{`[${payment.virtualBankName}] ${payment.paymentInformation} (${payment.virtualBankHolder})`}</span>
                                </p>
                              </div>
                              <div className="option_box1" key={index * 2 + 1}>
                                <strong>입금기한</strong>
                                <p className="modam_price">
                                  <span>{payment.virtualAccountExpiredDate.replaceAll('-', '. ')} 까지</span>
                                </p>
                              </div>
                            </>
                          ))}
                      </div>
                    </IonCol>
                  </IonRow>
                  {order.orderPayments.length > 0 &&
                    order.orderPayments.map((orderPayment, index) => (
                      <IonRow className="p_n" key={index}>
                        <IonCol>
                          <div className="total_type1">
                            <div className="option_box1">
                              <strong>즉시배송</strong>
                              <p className="modam_price">
                                <em>{numberWithCommas(String(order?.orderAmount))}</em>원
                              </p>
                            </div>
                            <div className="option_box1">
                              <strong>배송비</strong>
                              <p className="modam_price">
                                <em>{numberWithCommas(String(order?.orderDeliveryAmount ?? 0))}</em>원
                              </p>
                            </div>

                            {/* TODO 주문내역에 배송비 상세 추가할지 말지 */}
                            {/* {order?.orderDeliveryFeeInfos
                              .sort((a, b) => a.orderDeliveryFeeInfoSeq - b.orderDeliveryFeeInfoSeq)
                              .map((info) => (
                                <div className="option_box2" key={info.orderDeliveryFeeInfoSeq}>
                                  <span>{info.reason}</span>
                                  <p className="modam_price">{numberWithCommas(String(info.deliveryFee))}원</p>
                                </div>
                              ))} */}
                          </div>
                        </IonCol>
                      </IonRow>
                    ))}

                  {/*<IonRow className="p_n">*/}
                  {/*  <IonCol>*/}
                  {/*    <div className="total_type1">*/}
                  {/*      <div className="option_box1">*/}
                  {/*        <strong>쿠폰</strong>*/}
                  {/*        <p className="modam_price">*/}
                  {/*          <em>0</em>원*/}
                  {/*        </p>*/}
                  {/*      </div>*/}
                  {/*    </div>*/}
                  {/*  </IonCol>*/}
                  {/*</IonRow>*/}

                  {/*<IonRow className="p_n">
                    <IonCol>
                      <div className="total_type1">
                        <div className="option_box1">
                          <strong>포인트</strong>
                          <p className="modam_price">
                            <em>0</em>원
                          </p>
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>*/}

                  <IonRow className="p_n b_l">
                    <IonCol>
                      <div className="total_type1 type2">
                        <div className="option_box1">
                          <strong>최종 결제금액</strong>
                          <p className="modam_price">
                            <em>{numberWithCommas(String(order?.orderGrandTotal))}</em>원
                          </p>
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonText>
              </IonList>
            </IonAccordion>
          </IonAccordionGroup>
        </IonGrid>
        <DefaultFooter />
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: selectors.getUser(state),
    pay: selectors.getPay(state),
    delivery: selectors.getDelivery(state),
    codes: selectors.getCodes(state),
  }),
  mapDispatchToProps: { setDeliveryData },
  component: OrderDetailPage,
});
