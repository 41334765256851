import { combineReducers } from './combineReducers';
import { userReducer } from './user/user.reducer';
import { socialReducer } from './social/social.reducer';
import { categoryReducer } from './category/category.reducer';
import { cartReducer } from './cart/cart.reducer';
import { payReducer } from './pay/pay.reducer';
import { deliveryReducer } from './delivery/delivery.reducer';
import { codeReducer } from './code/code.reducer';
import { codeState } from './dataApi';

export const initialState: AppState = {
  user: {
    userData: {},
    darkMode: false,
    loading: false,
  },
  social: {},
  category: {},
  cart: {},
  pay: {},
  codes: {
    AgreementType: [],
    AmountUnitType: [],
    BankCode: [],
    CardCode: [],
    DeliveryAttributeType: [],
    DeliveryFeeType: [],
    DeliveryPaymentType: [],
    DeliveryRangeType: [],
    DeliveryRegionZoneType: [],
    DeliveryStatusType: [],
    DeliveryType: [],
    EnterType: [],
    InquiryType: [],
    MadeType: [],
    NoticeQueryType: [],
    OrderCancelStatusType: [],
    OrderDecideType: [],
    OrderPaymentType: [],
    OrderStatusType: [],
    OriginType: [],
    PaymentStatusType: [],
    ProductCertExceptionType: [],
    ProductCertType: [],
    ProductExtendType: [],
    ProductImageType: [],
    ProductOptionType: [],
    ProductStatusType: [],
    ProductType: [],
    RecipeMaterialType: [],
    SalesChannel: [],
    SalesChannelType: [],
    SelectDateAttribute: [],
    NoticeType: [],

    // plto - wip
    WorkActiontype: [],
    WorkSelectActionType: [],
  },
};

export const reducers = combineReducers({
  user: userReducer,
  social: socialReducer,
  category: categoryReducer,
  cart: cartReducer,
  pay: payReducer,
  delivery: deliveryReducer,
  codes: codeReducer,
});

export type AppState = ReturnType<typeof reducers>;
