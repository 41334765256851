/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  IonAccordionGroup,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonPage,
  IonRow,
  IonText,
} from '@ionic/react';
import { connect } from '../data/connect';

import { RouteComponentProps } from 'react-router';
import DefaultHeader from '../components/header/DefaultHeader';
import DefaultFooter from '../components/footer/DefaultFooter';
import './NoticePage.scss';
import { noticeSelect } from '../data/dataApi';
import NoticeCard from '../components/notice/NoticeCard';
import { Notice } from '../models/Notice';
import { informationCircle } from 'ionicons/icons';

interface OwnProps extends RouteComponentProps {}

interface StateProps {}

interface DispatchProps {}

interface NoticeProps extends OwnProps, StateProps, DispatchProps {}

const NoticePage: React.FC<NoticeProps> = ({}) => {
  // const [present] = useIonAlert(); // alert 폼
  const [notices, setNotices] = useState<Notice[]>([]); //공지사항 목록
  const [chkNotice, setChkNotice] = useState(false); //공지사항 갯수 여부
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false); //스크롤 여부

  const [pageNumber, setPageNumber] = useState(1);

  //TODO 추후 작업예정 const [open, setOpen] = useState(false); //공지사항 더보기

  const getData = async (resetList: boolean = false) => {
    const result = await noticeSelect({
      page: resetList ? 1 : pageNumber,
      size: 10,
    });

    if (!result.notice || result.notice.length === 0) {
      setInfiniteDisabled(true);
      setChkNotice(true);
      setNotices([]);
    } else {
      if (resetList) {
        setChkNotice(false);
        setNotices(result.notice);
        setPageNumber(pageNumber + 1);
      } else {
        setNotices([...notices, ...result.notice]);
        setPageNumber(pageNumber + 1);
      }

      if (result.notice.length < 10) {
        setInfiniteDisabled(true);
      } else {
        setInfiniteDisabled(false);
      }
    }
  };

  const loadData = async (ev: any) => {
    await getData();
    ev.target.complete();
    if (notices.length >= 1000) {
      setInfiniteDisabled(true);
    }
  };

  useEffect(() => {
    getData(true);
  }, []);

  return (
    <IonPage id="notice">
      <IonContent>
        <DefaultHeader title="공지사항" showBackButton={true} />
        <IonGrid>
          {chkNotice && notices.length == 0 && (
            <>
              <IonRow className="notice_not_exist">
                <IonCol className="ion-text-center">
                  <IonIcon icon={informationCircle} size={'large'} color="warning" />
                  <IonText>공지사항 내용이 없습니다.</IonText>
                </IonCol>
              </IonRow>
            </>
          )}

          {notices && notices.length > 0 && (
            <>
              <IonRow>
                <IonCol>
                  <IonAccordionGroup>
                    {notices && notices.map((notice) => <NoticeCard notice={notice} key={notice.noticeId} />)}
                  </IonAccordionGroup>
                </IonCol>
              </IonRow>
              <IonInfiniteScroll onIonInfinite={loadData} disabled={isInfiniteDisabled}>
                <IonInfiniteScrollContent loadingSpinner="bubbles" loadingText="불러오는 중..."></IonInfiniteScrollContent>
              </IonInfiniteScroll>
            </>
          )}

          {/*{open && (*/}
          {/*  <div className="open">*/}
          {/*    <button type="button">더보기</button>*/}
          {/*  </div>*/}
          {/*)}*/}
        </IonGrid>
      </IonContent>
      <DefaultFooter />
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  component: React.memo(NoticePage),
});
